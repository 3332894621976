// Material helpers
import {
  BottomNavigation,
  BottomNavigationAction,
  withStyles
} from "@material-ui/core";
import {
  AssignmentInd as BuscoTrabalhadorIcon,
  Work as BuscoTrabalhoIcon,
  Settings as SettingsIcon
} from "@material-ui/icons";

import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArticleIcon from '@mui/icons-material/Article';

// Externals
import PropTypes from "prop-types";
import React, { Component } from "react";
//Component styles
import styles from "./styles";

class FooterMobile extends Component {
  state = {
    value: 0,
    menuPerson: [
      {
        index: 0,
        label: "Meu Perfil",
        link: "/find-bot",
        icon: <BuscoTrabalhoIcon />
      },
      {
        index: 3,
        label: "Preferências",
        link: "/preferences",
        icon: <SettingsIcon />
      }
    ],
    menuCompany: [
      {
        index: 0,
        label: "Minhas Vagas",
        link: "/jobs",
        icon: <BuscoTrabalhadorIcon />
      },
      {
        index: 1,
        label: "Meus Créditos",
        icon: <CreditCardIcon />
      },
      {
        index: 2,
        label: "Solicitações Realizadas",
        icon: <ArticleIcon />,
        onClick: this.props.openRequestHistory
      }
    ]
  };

  componentDidMount() {
    const { location, userInitialized, userType } = this.props;
    const { menuPerson, menuCompany } = this.state;
    let newValue = 0;

    const mountMenuBar = userType === 'COMPANY' ? menuCompany : menuPerson;

    mountMenuBar.forEach(item => {
      if (item.link === location.pathname) {
        newValue = item.index;
      }
    });
    this.setState({ value: newValue });
    if (userInitialized && userInitialized === true) {
      this.setState({ value: newValue });
    }
  }

  render() {
    const { classes, history, userInitialized, userType } = this.props;
    const { value, menuCompany, menuPerson } = this.state;

    const mountMenuBar = userType === 'COMPANY' ? menuCompany : menuPerson;

    return (
      <BottomNavigation
        value={value}
        onChange={(event, newValue) => {
          this.setState({ value: newValue });
          if (newValue === 0 && userInitialized) {
            history.replace("/profile");
          } else {
            history.replace(mountMenuBar[newValue].link);
          }
        }}
        showLabels
        className={classes.bottomNav}
      >
        {mountMenuBar.map(item => (
          <BottomNavigationAction
            key={item.index}
            label={item.label}
            icon={item.icon}
            style={{ padding: "6px 8px" }}
            onClick={item.index === 1 ? this.props.openAvailableCredits : item.index === 2 ? this.props.openRequestHistory : () => { }}
          />
        ))}
      </BottomNavigation>
    );
  }
}

FooterMobile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FooterMobile);
