// @flow
import { CircularProgress, Grid, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Persona from "../../components/Persona";
import { doUpdateProfile, forceReloadProfile } from "../../modules/profile";
import { doGetProfile } from "../../modules/user";
import AccountDetails from "./components/AccountDetails";
import { Refresh as RefreshIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

class Profile extends Component {
  constructor(props) {
    super(props);
    let doLoading = true;

    this.getProfile();

    this.state = {
      loading: doLoading
    };
  }

  getProfile = () => {
    doGetProfile(this.props.token)
      .then(p => {

        this.props.doUpdateProfile(p);

        if (!p.initialized) {
          this.props.history.replace("/find-bot");
        }

        this.setState({ loading: false });

      })
      .catch(error => {
        console.error(error);
      });
  };

  handleRefreshClick = () => {
    this.setState({
      loading: true
    });

    doGetProfile(this.props.token)
    .then(p => {
      this.props.doUpdateProfile(p);
      this.setState({ loading: false });
    })
    .catch(error => {
      console.error(error);
    });
  };

  render() {
    const { classes, profile } = this.props;
    const { loading } = this.state;

    return (
      <div className={classes.root}>
              <Persona
              text={`${profile.name}, aqui você pode modificar o seu perfil, acrescentando itens ou removendo o que não quer mais. Mantenha seu perfil sempre em dia para ser encontrado(a) pelos contratantes.`}
            />
                {!loading && (
          <Button
            style={{ textTransform: "none", position: "relative", bottom: "20px" }}
            onClick={this.handleRefreshClick}
            >
            <RefreshIcon /> Atualizar
          </Button>
          )
        }
        <Grid container spacing={4}>
          {loading ? (
            <CircularProgress className={classes.progress} color="secondary" />
          ) : (
            <AccountDetails profile={profile} />
          )}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: state.formData.user && state.formData.user.initialized,
  editing: state.profile.editing,
  qualificationOpen: state.profile.qualificationOpen,
  ocupationOpen: state.profile.ocupationOpen,
  specialNeedsOpen: state.profile.specialNeedsOpen,
  placesInterestOpen: state.profile.placesInterestOpen,
  contractingModalitiesOpen: state.profile.contractingModalitiesOpen,
  shiftPreferencesOpen: state.profile.shiftPreferencesOpen,
  skillsOpen: state.profile.skillsOpen,
  confirmOpen: state.profile.confirmOpen,
  confirmTitle: state.profile.confirmTitle,
  confirmText: state.profile.confirmText,
  confirmKey: state.profile.confirmKey,
  confirmObjId: state.profile.confirmObjId,
  profile: state.profile.profile,
  profileQualification: state.profile.profileQualification,
  profileOcupation: state.profile.profileOcupation,
  profileSpecialNeeds: state.profile.profileSpecialNeeds,
  profilePlacesInterest: state.profile.profilePlacesInterest,
  profileContractingModalities: state.profile.profileContractingModalities,
  profileShiftPreferences: state.profile.profileShiftPreferences,
  profileSkills: state.profile.profileSkills,
  loading: state.profile.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Profile))
);
