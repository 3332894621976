// @flow
import { Fab, Grid, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add as AddIcon } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import Persona from "../../../components/Persona";
import TopBar from "../../../components/TopBar";
import { doLoadImportanceRange } from "../../../modules/form-data";
import {
  doPersistJob,
  doSelectSpecificProfissaoGrade
} from "../../../modules/job";
import JobCardLevel from "../components/JobCardLevel";
import SelectExperience from "../components/SelectExperience";
import SelectProfissao from "../components/SelectProfissao";
import SelectSegment from "../components/SelectSegment";
import styles from "./styles";

class JobProfissaoChoices extends Component {
  constructor(props) {
    super(props);

    this.header = React.createRef();

    this.state = {
      importanceRange: [],
      profissoes: [],
      openDialogSelectProfissao: false,
      openDialogSelectExperience: false,
      openDialogSelectSegment: false,
      profissoesGrades: {}
    };
  }

  componentDidMount() {
    this.doLoadData();
  }

  doLoadData = () => {
    let profissoes = this.props.profissoesConfirmed
      .map(p => p.profissao)
      .filter(
        (value, index, self) => self.map(p => p.id).indexOf(value.id) === index
      );
      
    this.setState({
      profissoes: profissoes
    });

    doLoadImportanceRange(this.props.token).then(data => {
      this.setState({
        importanceRange: data
      });
    });
  };

  handleManagerDialogSelectProfissao = () => {
    this.setState({
      openDialogSelectProfissao: !this.state.openDialogSelectProfissao
    });
  };

  handleSelectedProfissao = () => {
    this.setState({
      openDialogSelectProfissao: false,
      openDialogSelectExperience: true
    });
  };

  handleSelectedExperience = () => {
    this.setState({
      openDialogSelectProfissao: false,
      openDialogSelectExperience: false,
      openDialogSelectSegment: true
    });
  };

  handleSelectedSegment = () => {
    this.setState({
      openDialogSelectProfissao: false,
      openDialogSelectExperience: false,
      openDialogSelectSegment: false
    });
    setTimeout(() => {
      this.doLoadData();
    }, 200);
  };

  handleCloseSelectSituation = () => {
    this.setState({ openDialogSelectProfissao: false });
    setTimeout(() => {
      this.doLoadData();
    }, 200);
  };

  render() {
    const { classes } = this.props;
    const {
      profissoes,
      openDialogSelectProfissao,
      openDialogSelectExperience,
      openDialogSelectSegment,
      importanceRange
    } = this.state;
    const grid = {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 4
    };

    return (
      <div className={classes.root}>
        <TopBar
          pageTitle="Profissão"
          buttonTitle="Confirmar"
          hasButton={true}
          buttonColor="primary"
          variant="contained"
          actionButton={() => this.props.history.replace("/job")}
          actionBack={() => this.props.history.replace("/job")}
        />

        <Grid container spacing={3} style={{ paddingTop: 15 }}>
          {profissoes.length <= 0 ? (
            <Persona
              text={`Clique no botão de "+" para adicionar novos itens em Profissão`}
            />
          ) : (
            <Persona
              text={`Nosso algoritmo inteligente valoriza o que é mais importante para as suas escolhas. Você pode escolher diversas opções e selecionar um grau de importância para cada uma delas. A lista e a priorização dos candidatos se ajustarão automaticamente. Confirme suas escolhas sempre que solicitado no canto superior da tela.`}
            />
          )}

          {profissoes.length > 0 &&
            profissoes.map((vl, k) => (
              <Grid
                key={k}
                item
                xs={grid.xs}
                sm={grid.sm}
                md={grid.md}
                lg={grid.lg}
              >
                <JobCardLevel
                  master={false}
                  contentType="_PROFISSAO"
                  checkboxHidden={true}
                  grade={this.props.profissoesGrades[vl.id + "-" + vl.text]}
                  selectGradeFn={value => {
                    this.props.doSelectSpecificProfissaoGrade(vl, value);
                  }}
                  cardTitle={vl.text}
                  active={true}
                  choicesComponentUrl={
                    "/job-specific-profissao-choices/" + vl.id + "-" + vl.text
                  }
                  importanceRange={importanceRange}
                  jobId={this.props.jobId}
                  persistJobFn={this.props.doPersistJob}
                  history={this.props.history}
                />
              </Grid>
            ))}
        </Grid>

        <Fab
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={() => this.handleManagerDialogSelectProfissao()}
        >
          <AddIcon />
        </Fab>

        {openDialogSelectProfissao && (
          <SelectProfissao
            managerDialogSelectProfissao={() =>
              this.handleManagerDialogSelectProfissao()
            }
            selectedProfissao={() => this.handleSelectedProfissao()}
          />
        )}

        {openDialogSelectExperience && (
          <SelectExperience
            selectedExperience={() => this.handleSelectedExperience()}
          />
        )}

        {openDialogSelectSegment && (
          <SelectSegment selectedSegment={() => this.handleSelectedSegment()} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profissoesConfirmed: state.job.profissoesConfirmed,
  profissoesGrades: state.job.profissoesGrades,
  profissoes: state.profissoes,
  jobId: state.job.jobId,
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSelectSpecificProfissaoGrade,
      doPersistJob
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(JobProfissaoChoices))
);
