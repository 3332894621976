export default theme => ({
  topbar: {
    position: "fixed",
    width: "100%",
    top: 0,
    left: 0,
    right: "auto",
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  topbarShift: {
    // marginLeft: "271px",
    // width: "calc(-271px + 100vw)"
  },
  paper: {
    minWidth: "605px",
    maxWidth: "100%"
  },
  paperMobile: {
    minWidth: "360px",
    maxWidth: "100%"
  },
  paperMobileCredits: {
    minWidth: "360px",
    maxWidth: "100%",
    height: "200px"
  },
  drawerPaper: {
    zIndex: 1200,
    width: "241px"
  },
  sidebar: {
    width: "240px",
    position: "fixed",
    left: 0,
    top: 64
  },
  sidebarShift: {
    display: "none"
  },
  content: {
    paddingTop: "64px",
    paddingBottom: "80px",
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  contentShift: {
    marginLeft: "240px"
  },
  contentFull: {
    paddingTop: "64px",
    paddingBottom: "0px",
    backgroundColor: theme.palette.background.default,
    minHeight: "100%",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  planItem: {
    marginLeft: "10px",
    marginRight: "10px"
  },
  linearBar: {
    width: "290px",
    height: "10px",
    left: "25px"
  },
  textLeft: {
    display: "inline",
    float: "left",
  },
  textLeftHistory: {
    display: "inline",
    float: "left",
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }   
  },
  textRight: {
    display: "inline",
    float: "right"
  },
  textRightHistory: {
    display: "inline",
    float: "right",
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer'
    }   
  }
});
