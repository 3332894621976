import styled from "styled-components";
import Button from "@material-ui/core/Button";

export default theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  rootFull: {
    flexGrow: 1,
    padding: "0px",
    "& h1": {
      padding: "20px"
    }
  },
  fab: {
    position: "fixed",
    right: "20px",
    bottom: "20px"
  },
  fabMobile: {
    position: "fixed",
    right: "15px",
    bottom: "95px"
  },
  card: {
    marginTop: "13px",
    marginBottom: "13px"
  },
  cardHeader: {
    paddingBottom: "5px",
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#222"
    },
    "& small": {
      color: theme.palette.background.active,
      fontSize: "12px",
      fontWeight: "normal"
    }
  },
  cardHeaderInactive: {
    paddingBottom: "5px",
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#222"
    },
    "& small": {
      color: "red",
      fontSize: "12px",
      fontWeight: "normal"
    }
  },
  avatar: {
    fontSize: "14px"
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& p": {
      fontSize: "14px",
      marginBottom: "0px"
    }
  },
  jobName: {
    flexGrow: 1
  },
  badge: {
    borderRadius: "4px",
    backgroundColor: theme.palette.background.topbar,
    fontSize: "11px",
    color: "#fff",
    textAlign: "center",
    padding: "3px 5px",
    marginLeft: "auto"
  },
});

export const SubmitButton = styled(Button)`
  margin-top: 15px !important;
`;
