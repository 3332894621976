import i18n from "../util/i18n";
import { isEmailValid } from "../util/validator";

const initialState = {
  isProcessing: false,
  email: "",
  message: "",
  showMessage: false
};

export const FORGOT_PASSWORD_UPDATE_DATA =
  "forgot-password/FORGOT_PASSWORD_UPDATE_DATA";
export const FORGOT_PASSWORD_REQUESTED =
  "forgot-password/FORGOT_PASSWORD_REQUESTED";
export const FORGOT_PASSWORD_INVALID_EMAIL =
  "forgot-password/FORGOT_PASSWORD_INVALID_EMAIL";
export const FORGOT_PASSWORD_CLEAR_MESSAGE =
  "forgot-password/FORGOT_PASSWORD_CLEAR_MESSAGE";
export const FORGOT_PASSWORD_SUCCESSFUL =
  "forgot-password/FORGOT_PASSWORD_SUCCESSFUL";
export const FORGOT_PASSWORD_NOT_SUCCESSFUL =
  "forgot-password/FORGOT_PASSWORD_NOT_SUCCESSFUL";
export const FORGOT_PASSWORD_REQUEST_BY_PERSON =
  "forgot-password/FORGOT_PASSWORD_REQUEST_BY_PERSON";
export const FORGOT_PASSWORD_ERROR = "forgot-password/FORGOT_PASSWORD_ERROR";

export default (state = initialState, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_UPDATE_DATA:
      return {
        ...state,
        ...action.data
      };
    case FORGOT_PASSWORD_REQUESTED:
      return {
        ...state,
        isProcessing: true
      };
    case FORGOT_PASSWORD_INVALID_EMAIL:
      return {
        ...state,
        isProcessing: false,
        showMessage: true,
        message: i18n.t("invalidEmail")
      };
    case FORGOT_PASSWORD_CLEAR_MESSAGE:
      return {
        ...state,
        isProcessing: false,
        showMessage: false,
        message: ""
      };
    case FORGOT_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        isProcessing: false,
        showMessage: true,
        message: i18n.t("forgotPasswordEndSuccess")
      };
    case FORGOT_PASSWORD_NOT_SUCCESSFUL:
      return {
        ...state,
        isProcessing: false,
        showMessage: true,
        message: i18n.t("forgotPasswordEndFailure")
      };
    case FORGOT_PASSWORD_REQUEST_BY_PERSON:
      return {
        ...state,
        isProcessing: false,
        showMessage: true,
        message: i18n.t("forgotPasswordEndRequestPerson")
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        isProcessing: false,
        showMessage: true,
        message: i18n.t("genericError")
      };
    default:
      return state;
  }
};

export const doUpdateData = data => {
  return dispatch => {
    dispatch({
      type: FORGOT_PASSWORD_UPDATE_DATA,
      data: data
    });
  };
};

export const doForgotPassword = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: FORGOT_PASSWORD_REQUESTED
    });

    const { email } = getState().forgotPassword;
    if (!isEmailValid(email)) {
      dispatch({
        type: FORGOT_PASSWORD_INVALID_EMAIL
      });
    } else {
      const url = `${process.env.REACT_APP_SERVER_URL}/user/forgot-password`;
      try {
        const result = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            email
          })
        });
        if (result.ok) {
          dispatch({
            type: FORGOT_PASSWORD_SUCCESSFUL
          });
        } else {

          const errorObject = await result.json();

          if (errorObject.error_message === "person_forgot_password") {
            dispatch({
              type: FORGOT_PASSWORD_REQUEST_BY_PERSON
            });
          } else {
            dispatch({
              type: FORGOT_PASSWORD_NOT_SUCCESSFUL
            });
          }
        }
      } catch (e) {
        dispatch({
          type: FORGOT_PASSWORD_ERROR
        });
      }
    }
  };
};

export const doClearForgotPasswordMessage = () => {
  return dispatch => {
    dispatch({
      type: FORGOT_PASSWORD_CLEAR_MESSAGE
    });
  };
};
