// @flow
import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import { Card } from "@material-ui/core";

import ImportanceRange from "../../../../components/ImportanceRange";

import styles from "./styles";

class JobCard extends Component {
  render() {
    const {
      classes,
      cardTitle,
      cardIcon,
      actionCard,
      grade,
      active,
      optional
    } = this.props;

    return (
      <Card className={classes.card} onClick={() => actionCard()}>
        {cardIcon}
        <h3>{cardTitle}</h3>
        {active && <ImportanceRange range={Number(grade)} />}
        {!optional && <p style={{ color: "grey" }}>{""}</p> }
        {optional && <p style={{ color: "grey" }}>{""}</p> }
      </Card>
    );
  }
}

export default withStyles(styles)(JobCard);
