// @flow
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Slide,
  SnackbarContent,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
// Material icons
import { Close as CloseIcon, Warning as WarningIcon } from "@material-ui/icons";
import React, { Component, Fragment } from "react";
import Select from "react-select";
//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectLocation extends Component {
  constructor(props) {
    super(props);

    let states = props.states.map(p => ({
      label: p.name,
      name: p.name,
      value: p.id,
      id: p.id,
      cities: p.cities
    }));

    let cities = [];
    let selectedState = props.placesState;
    let selectedCity = props.placesCity;

    if (selectedState) {
      selectedState = states.find(s => s.value === selectedState);
      cities = selectedState.cities.map(p => ({
        label: p.name,
        name: p.name,
        value: p.id,
        latitude: p.latitude,
        longitude: p.longitude,
        id: p.id
      }));

      if (selectedCity) {
        selectedCity = cities.find(c => c.value === selectedCity);
      } else {
        selectedCity = "";
      }
    } else {
      selectedState = "";
      selectedCity = "";
    }

    this.state = {
      openDialog: true,
      selectedOption: "",
      states: states,
      cities: cities,
      selectedState: selectedState,
      selectedCity: selectedCity,
      currentCityGrade: props.currentCityGrade,
      otherCityGrade: props.otherCityGrade,
      validationError: false
    };
  }

  componentDidMount() {
    this.doLoadCard();
    if (this.props.defaultValuesFn && !this.props.jobId)
      this.props.defaultValuesFn(null);
  }

  doLoadCard = () => {
    const { jobId, active } = this.props;
    if (!jobId && !active) {
      this.handleActiveCard();
    }
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleClose = () => {

    if (
      this.props.active &&
      (this.state.selectedCity === "" || this.state.selectedState === "" || this.state.selectedCity === '' || this.state.selectedState === '' || this.state.selectedCity === null)
    ) {
      this.setState({ validationError: true });
    } else {
      this.setState({ openDialog: false, validationError: false });
      this.props.actionCard();
    }
  };

  handleActiveCard = () => {
    this.props.toggleCheckboxFn();

    if (this.props.jobId && this.props.persistJobFn) {
      this.props.persistJobFn();
    }
  };

  handleChangeOption = event => {
    const item = event.target.value;

    if (this.props.active && this.props.selectGradeFn) {
      this.props.selectGradeFn(item);
    }

    if (this.props.active && this.props.jobId && this.props.persistJobFn) {
      this.props.persistJobFn();
    }
    this.setState({ selectedOption: item });
  };

  handleActiveSubItems = (item, vcard) => {
    if (item === "0.50" && !vcard) {
      return true;
    } else {
      if (Number(item) === vcard || item === vcard) {
        return true;
      }
    }
    return false;
  };

  render() {
    const { classes, cardTitle, active, grade } = this.props;
    const { openDialog, selectedOption, validationError } = this.state;

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{ minWidth: "350px" }}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={() => this.handleClose()}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {cardTitle}
            </Typography>
            <Button color="inherit" onClick={() => this.handleClose()}>
              Confirmar
            </Button>
          </Toolbar>
        </AppBar>
        {active ? (
          <DialogContent className={classes.dialogContent}>
            <h3>{cardTitle}</h3>
            <small>
              Selecione o nível de importância do critério{" "}
              {cardTitle.toLowerCase()}
            </small>
            <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
            <RadioGroup
              aria-label="position"
              name="position"
              value={selectedOption}
              onChange={this.handleChangeOption}
              row
              className={classes.radioGroup}
            >
              {this.props.importanceRange.map((v, k) => (
                <FormControlLabel
                  key={k}
                  value={v.value}
                  control={<Radio color="primary" />}
                  label={v.text}
                  labelPlacement="top"
                  className={classes.label}
                  readOnly={!active}
                  checked={Number(v.value) === Number(grade)}
                />
              ))}
            </RadioGroup>

            {this.state.states && (
              <div style={{ marginTop: 24 }}>
                <h4>Selecione a cidade e estado da vaga</h4>
                <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
                {validationError && (
                  <p style={{ fontSize: 12, color: "red", paddingTop: 15 }}>
                    Você precisa selecionar um estado e cidade
                  </p>
                )}
                <Paper className={classes.statePaper} elevation={0}>
                  <Select
                    value={this.state.selectedState}
                    options={this.state.states}
                    placeholder={"Estado"}
                    onChange={selected => {
                      this.props.selectStateFn(selected);

                      this.setState({
                        selectedState: selected,
                        selectedCity: null,
                        cities: selected.cities.map(p => ({
                          label: p.name,
                          name: p.name,
                          value: p.id,
                          latitude: p.latitude,
                          longitude: p.longitude,
                          id: p.id
                        }))
                      });
                    }}
                  />
                </Paper>

                <Paper
                  className={classes.statePaper}
                  elevation={0}
                  style={{ marginTop: 10 }}
                >
                  <Select
                    value={this.state.selectedCity}
                    options={this.state.cities}
                    placeholder={"Cidade"}
                    onChange={selected => {
                      this.props.selectCityFn(selected);

                      this.setState({
                        selectedCity: selected,
                        validationError: false
                      });
                    }}
                  />
                </Paper>
              </div>
            )}

            <div style={{ marginTop: 28 }}>
              <h4>Opções</h4>
              <small>Aqui você seleciona a importância das subopções</small>
              <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
            </div>

            {[
              {
                text: "Cidade da vaga",
                value: "currentCityGrade",
                subOptionsSelectGradeFn: this.props.currentCitySelectGradeFn
              },
              {
                text: "Outras cidades",
                value: "otherCityGrade",
                subOptionsSelectGradeFn: this.props.otherCitySelectGradeFn
              }
            ].map((v0, k0) => (
              <Fragment key={k0}>
                <span style={{ fontSize: 20 }}>
                  {this.capitalizeFirstLetter(v0.text)}
                </span>
                <div className={classes.levelCard}>
                  {this.props.importanceRange.map((v, k) => (
                    <div key={k} className={classes.levelCardOptions}>
                      <label>{v.text}</label>
                      <Radio
                        color="primary"
                        name={v0.value}
                        value={v.value}
                        readOnly={!active}
                        checked={this.handleActiveSubItems(
                          v.value,
                          this.props[v0.value]
                        )}
                        onChange={() => {
                          if (this.props.active) {
                            v0.subOptionsSelectGradeFn(v.value);
                          }

                          if (
                            this.props.active &&
                            this.props.jobId &&
                            this.props.persistJobFn
                          )
                            this.props.persistJobFn();
                        }}
                      />
                    </div>
                  ))}
                </div>
                <Divider
                  style={{ height: 10, marginBottom: 15, marginTop: 15 }}
                />
              </Fragment>
            ))}
          </DialogContent>
        ) : (
          <SnackbarContent
            aria-describedby="client-snackbar"
            style={{
              backgroundColor: "#55738c",
              margin: "20px auto",
              display: "flex"
            }}
            message={
              <span
                id="client-snackbar"
                style={{ display: "flex", alignItems: "center" }}
              >
                <WarningIcon style={{ marginRight: "10px" }} />
                Ative este critério para editar as opções!
              </span>
            }
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(SelectLocation);
