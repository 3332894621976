// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";

import Loading from "../loading";

import { doConfirmCourseSituation } from "../../modules/job";
import { doLoadEducationSituations } from "../../modules/form-data";

class JobQualificacaoAddChoiceSelectCourseSituation extends React.Component {
  constructor(props) {
    super(props);

    this.educationSituations = [];
    this.selectedCheckboxes = new Set();
    this.selectedValues = new Set();
  }
  componentDidMount() {
    doLoadEducationSituations(this.props.token).then(data => {
      this.educationSituations = data;
      this.forceUpdate();
    });
  }
  toggleCheckbox = opt => {
    if (this.selectedCheckboxes.has(opt.text)) {
      this.selectedCheckboxes.delete(opt.text);
      this.selectedValues.delete(opt);
    } else {
      this.selectedCheckboxes.add(opt.text);
      this.selectedValues.add(opt);
    }
  };
  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };
  render() {
    const { classes, course, courseLevel, history } = this.props;

    let cardTitle = null;
    if (
      courseLevel.value === "ENSINO_FUNDAMENTAL" ||
      courseLevel.value === "ENSINO_MEDIO"
    ) {
      cardTitle = this.capitalizeFirstLetter(courseLevel.text);
    } else {
      cardTitle = this.capitalizeFirstLetter(course.text);
    }

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => {
                const newUrl =
                  courseLevel.value === "ENSINO_FUNDAMENTAL" ||
                  courseLevel.value === "ENSINO_MEDIO"
                    ? "/job-qualificacao-add-choice"
                    : "/job-qualificacao-add-choice-select-course";
                history.replace(newUrl);
              }}
            >
              <Icon className={classes.icon}>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Situação do curso
            </Typography>
            <Button
              color="inherit"
              onClick={() => {
                let values = [...this.selectedValues];
                this.props.doConfirmCourseSituation(values);
              }}
            >
              Confirmar
            </Button>
          </Toolbar>
        </AppBar>
        <Paper className={classes.titleRootContainer} elevation={1}>
          <Typography variant="headline" component="h3">
            {cardTitle}
          </Typography>
        </Paper>
        <div className={classes.bodyRootContainer}>
          <Grid container spacing={0}>
            <Grid item xs>
              <Paper elevation={1} className={classes.paperLabel}>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={require("../../images/84b12ae895a9177449241d9d65b92f3c_757575.svg")}
                    alt="Graduation hat icon"
                    width={50}
                    height={50}
                  />
                  <div className={classes.smallCardText}>
                    Situação<br />do curso
                  </div>
                </div>
              </Paper>
            </Grid>
            {this.educationSituations.length > 0 && (
              <Grid item xs={9}>
                <Paper elevation={0} className={classes.paperValues}>
                  <Table className={classes.table}>
                    <TableBody>
                      {this.educationSituations.map((el, k) => (
                        <TableRow key={k} className={classes.tableRow}>
                          <TableCell className={classes.tableCellLabel}>
                            {el.text}
                          </TableCell>
                          <TableCell className={classes.tableCellCheckbox}>
                            <Checkbox
                              color="primary"
                              className={classes.checkbox}
                              value={el.value}
                              onChange={() => {
                                this.toggleCheckbox(el);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Paper>
              </Grid>
            )}
            {this.educationSituations.length === 0 && <Loading />}
          </Grid>
        </div>
        <div className={classes.footer} />
      </div>
    );
  }
}

const styles = theme => ({
  titleRootContainer: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  bodyRootContainer: {
    paddingTop: theme.spacing.unit * 4,
    paddingBottom: theme.spacing.unit * 4,
    paddingLeft: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2
  },
  paperLabel: {
    backgroundColor: "#C8E6C9",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  smallCardText: {
    fontSize: 13
  },
  paperValues: {
    borderTop: "1px solid rgba(224, 224, 224, 1)"
  },
  tableRow: {
    height: 38
  },
  tableCellLabel: {
    paddingRight: 0
  },
  tableCellCheckbox: {
    paddingRight: "0 !important",
    paddingLeft: 0,
    textAlign: "right"
  },
  footer: {
    position: "fixed",
    bottom: 20,
    left: "50%",
    transform: "translateX(-50%)"
  },
  checkbox: {
    height: 30
  },
  flex: {
    flex: 1
  }
});

const mapStateToProps = state => ({
  token: state.login.token,
  courseLevel: state.job.courseLevel,
  course: state.job.course
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourseSituation
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(JobQualificacaoAddChoiceSelectCourseSituation))
);
