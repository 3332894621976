import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import {
  ContainerJobSpot
} from "./styles";

import Persona from "../../components/Persona";

const Jasypt = require('jasypt');
const jasypt = new Jasypt();

class Redirect extends Component {
  constructor(props) {
    super(props);

    const search = window.location.search;
    const params = new URLSearchParams(search);

    this.url = params.get("url");

    this.handleRedirect(decodeURIComponent(this.url));
  }

  componentDidMount () {
    if (process.env.REACT_APP_ENV === "PROD") {
      console.log('Adding pixel...');
      const ReactPixel = require('react-facebook-pixel');
      ReactPixel.default.init('1049451412933339');
      ReactPixel.default.track('PageView');
    }

    if (this.url !== null && this.url !== undefined) {
      this.handleRedirect(encodeURI(this.url));
    }

  }

  sleep = ms => new Promise(r => setTimeout(r, ms));

  async handleRedirect (url) {
    jasypt.setPassword('!g3n-on3-t1m3!');

    try {
    const decoded = this.url.replaceAll(" ", "+");
    const decryptMsg = jasypt.decrypt(decoded);

    await this.sleep(5000);

    window.location.href = decryptMsg;

    } catch(error) {
      console.log("Something happened! ", error);
    }

  };

  render() {
    return (
      <ContainerJobSpot component="main" maxWidth="xs">
        <Persona
          text={`Obrigado por confiar na plataforma JobSpot! Em breve você será redirecionado para nosso Whatsapp para efetuarmos seu cadastro!`}
        />
        <Typography align="center" component="p">Redirecionando...</Typography>
      </ContainerJobSpot>
    );
  }
}

export default (Redirect);
