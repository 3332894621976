import { Container, Typography } from "@material-ui/core";
import React from "react";
import { Row } from "reactstrap";
import { Logo } from "./styles";

export default class NotFound extends React.Component {
  render() {
    return (
      <Container
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center"
        }}
      >
        <Row>
          <Logo>
            <img src={require("../../images/logo.png")} alt="Logo JobSpot" />
          </Logo>
        </Row>
        <Row>
          <Typography variant="headline">
            Ops, essa página não existe...
            <span role="img" aria-label="Sad emoji">
              😢
            </span>
            <br />
            Clique <a href="/jobs">aqui</a> e volte para a página principal! 
          </Typography>
        </Row>
      </Container>
    );
  }
}
