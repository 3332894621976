export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  card: {
    width: "100%",
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",

    // "& svg": {
    //   width: "48px",
    //   height: "48px",
    //   color: "#989ca0",
    //   marginBottom: "10px"
    // },
    "& h3": {
      width: "100%",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "left",
      color: "#333",
      margin: "10px 0px 15px 0",
      textTransform: "uppercase"
    }
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    "& h3": {
      flexGrow: 1
    }
  },
  masterTitle: {
    color: "#0062e4 !important",
    "& svg": {
      color: "#0062e4"
    }
  },
  radioGroup: {
    flexWrap: "nowrap"
  },
  label: {
    margin: "5px",
    "& span": {
      fontSize: "10px",
      lineHeight: ".8rem"
    }
  }
});
