const coolMessages = {
  "msg-start": ({ name }) =>
    `Olá, ${name}! Legal ter você por aqui! Meu nome é Jobin e estou aqui para ajudar você!`,
  "msg-start-2":
    "Para eu poder te ajudar, vamos nos conhecer melhor.",
  "msg-start-3": "É algo bem rápido, não se preocupe!",
  "msg-start-4": "São apenas algumas perguntas sobre suas experiências profissionais.",
  "msg-agreement":
    "Então, vamos começar?",
  "msg-continue-1": "Vamos lá!",
  "msg-location": ({ name, city, state }) =>
    `${name}, você quer trabalhar somente em ${city} - ${state} ou em outras cidades também?`,
  "msg-continue-2":
    "Uma dica: você poderá editar seu perfil a qualquer hora, mesmo depois de nossa conversa aqui.",
  "msg-continue-3":
    "Primeiro vamos conversar sobre sua formação e capacitação.",
  "msg-start-education": "Sobre o que você quer me contar agora?",
  "msg-elementary-school": "Qual a situação no Ensino Fundamental?",
  "msg-high-school": "Qual a situação no Ensino Médio?",
  "msg-other-course":
    "Voce tem algum outro curso que gostaria de informar agora?",
  "msg-course-other": "Qual o nome do curso?",
  "msg-course-other-situation": "Qual a situação?",
  "msg-other-course-more": "Tem algum outro que gostaria de informar agora?",
  "msg-technical-professional": "Qual o curso?",
  "msg-technical-professional-situation": "Qual a situação?",
  "msg-technical-professional-other-course":
    "Tem algum outro curso profissionalizante?",
  "msg-graduation": "Qual o curso de graduação?",
  "msg-graduation-degrees": "Qual o nível acadêmico?",
  "msg-graduation-situation": "Qual a situação?",
  "msg-master": "Digite o curso de mestrado:",
  "msg-master-situation": "Qual a situação?",
  "msg-master-professional": "Informe o curso de mestrado profissional:",
  "msg-master-professional-situation": "Qual a situação?",
  "msg-other-specialization": "Qual o curso?",
  "msg-other-specialization-situation": "Qual a situação?",
  "msg-doctorate": "Qual o curso de doutorado?",
  "msg-doctorate-situation": "Qual a situação?",
  "msg-continue-4": "Legal! Estamos quase no fim.",
  "msg-continue-5":
    "Vamos agora para suas experiências profissionais. Você pode escolher uma profissão ou trabalho que deseja neste momento. Você pode indicar mais do que uma; fique à vontade.",
  "msg-job": "Qual a sua profissão?",
  "msg-experience-period": ({ profession }) =>
    `Quanto tempo de experiência como ${profession}?`,
  "msg-experience-segment": ({ profession }) =>
    `Em quais das áreas abaixo você já atuou como ${profession}? Escolha pelo menos 1 opção.`,
  "msg-other-job":
    "Entendi. Quer informar outra experiência profissional agora?",
  "msg-contracting-modality": "Ok. Que tipo de trabalho você está buscando?",
  "msg-shift-preferences": "Qual a sua disponibilidade?",
  "msg-skills":
    "Agora me diz: com qual destas características você mais se identifica? (Marque até 3 opções)",
  "msg-special-needs": "Você é portador de alguma necessidade especial?",
  "msg-special-needs-1": "Qual é a sua necessidade especial?",
  "msg-special-needs-2": "Escreva mais detalhes abaixo:",
  "msg-finish-1": ({ name }) =>
    `Pronto, ${name}! Terminamos por agora. O seu perfil já está na nossa vitrine e você já pode ser contatado(a) para novas oportunidades.`,
  "msg-finish-2":
    "Fique ligado! A qualquer momento você poderá ser convidado para um bate-papo por um empregador.",
  "msg-authorization-1": "Você autoriza esses convites?",
  "msg-authorization-2":
    "Tem certeza? Você vai ficar de fora das oportunidades? Vamos autorizar?",
  "msg-policies":
    "Para finalizar, você concorda com nossas políticas?",
  "msg-end":
    "Tudo pronto! Você já está na nossa vitrine! Agora é só esperar pelos convites de bate-papo. Boa sorte! #tamojunto",
  "msg-already-choose":
    "Ei, você já escolheu essa escolaridade! Escolha outra, por favor."
};

const regularMessages = {
  "msg-start": ({ name }) =>
    `Olá, ${name}! Seja bem-vindo(a)! Meu nome é Jobin e meu trabalho é ajudar você por aqui.`,
  "msg-start-2": "Para eu poder te ajudar, vamos nos conhecer melhor.",
  "msg-start-3": "É algo bem rápido, não se preocupe!",
  "msg-start-4": "São apenas algumas perguntas sobre suas experiências profissionais.",
  "msg-agreement":
    "Então, vamos começar?",
  "msg-continue-1": "Muito bem, vamos lá :)",
  "msg-location": ({ name, city, state }) =>
    `${name}, você tem interesse em trabalhar somente na cidade de ${city} - ${state} ou tem interesse em outras localidades também?`,
  "msg-continue-2":
    "Uma dica: se precisar, você poderá modificar ou complementar o seu perfil a qualquer momento posteriormente.",
  "msg-continue-3":
    "Vamos conversar agora sobre sua formação, cursos, treinamentos e capacitação?",
  "msg-start-education": "Escolha sobre qual você quer me contar agora:",
  "msg-elementary-school": "Qual a situação no Ensino Fundamental?",
  "msg-high-school": "Qual a situação no Ensino Médio?",
  "msg-other-course":
    "Há algum outro curso que você tenha realizado e gostaria de informar agora?",
  "msg-course-other": "Por favor, digite o nome do curso ou do treinamento:",
  "msg-course-other-situation": "Qual a situação?",
  "msg-other-course-more": "Gostaria de informar algum outro agora?",
  "msg-technical-professional": "Qual o nome do curso?",
  "msg-technical-professional-situation": "Qual a situação?",
  "msg-technical-professional-other-course":
    "Gostaria de informar algum outro curso profissionalizante agora?",
  "msg-graduation": "Qual o curso de graduação?",
  "msg-graduation-degrees": "Qual o nível acadêmico?",
  "msg-graduation-situation": "Qual a situação?",
  "msg-master": "Digite o curso de mestrado:",
  "msg-master-situation": "Qual a situação?",
  "msg-master-professional": "Informe o curso de mestrado profissional:",
  "msg-master-professional-situation": "Qual a situação?",
  "msg-other-specialization": "Qual o curso?",
  "msg-other-specialization-situation": "Qual a situação?",
  "msg-doctorate": "Qual o curso de doutorado?",
  "msg-doctorate-situation": "Qual a situação?",
  "msg-continue-4": "Muito bem, estamos quase finalizando...",
  "msg-continue-5":
    "Agora conte-me um pouco sobre as suas experiências profissionais.",
  "msg-job": "Qual a sua profissão?",
  "msg-experience-period": ({ profession }) =>
    `Quanto tempo de experiência como ${profession}?`,
  "msg-experience-segment": ({ profession }) =>
    `Em quais das áreas abaixo você já atuou como ${profession}? Escolha pelo menos 1 opção.`,
  "msg-other-job":
    "Entendi. Você gostaria de informar alguma outra experiência agora?",
  "msg-contracting-modality":
    "Ok. Agora eu gostaria de saber a sua disponibilidade para trabalho. Escolha pelo menos 1 opção.",
  "msg-shift-preferences": "E em quais turnos você teria disponibilidade? Escolha pelo menos 1 opção.",
  "msg-skills":
    "Ótimo! Dentre as habilidades a seguir, quais delas você acha que tem mais a ver com você? (Marque no máximo 3)",
  "msg-special-needs":
    "Entendido. Agora me diga, você é portador(a) de alguma necessidade especial?",
  "msg-special-needs-1": "Qual a sua necessidade especial?",
  "msg-special-needs-2": "Pode detalhar no campo abaixo:",
  "msg-finish-1": ({ name }) =>
    `Pronto, ${name}! Terminamos o seu perfil profissional. Já vou disponibilizá-lo na nossa vitrine para que você possa ser encontrado(a) para vagas de seu interesse.`,
  "msg-finish-2":
    "Agora fique atento: a qualquer momento você pode ser convidado a participar de um bate-papo com um empregador interessado. É importante que você entenda que quem disponibiliza uma vaga não sabe o seu nome ou quem você é; apenas sabe como é o seu perfil.",
  "msg-authorization-1":
    "Sendo assim, você autoriza solicitações de conversa enviadas pelos empregadores que se interessarem pelo seu perfil?",
  "msg-authorization-2":
    "Você tem certeza? Neste caso os empregadores não vão poder conhecer você... Não prefere então autorizar?",
  "msg-policies":
    "Ótimo! Por fim, você aceita nossas políticas?\n",
  "msg-end":
    "Parabéns, terminamos! Agora você está na nossa vitrine. Fique atento às solicitações de bate-papo que virão. Você poderá examiná-las e decidir quais deseja aceitar ou não. As solicitações que você aceitar abrirão a possibilidade de bate-papos com potenciais empregadores. A partir daí, vocês conversam diretamente. Boa sorte!",
  "msg-already-choose":
    "Ei, você já escolheu essa escolaridade! Escolha outra, por favor."
};

const formalMessages = {
  "msg-start": ({ name }) =>
    `Olá, ${name}! Seja bem-vindo(a)! Muito prazer! Meu nome é Jobin e meu trabalho é ajudar você por aqui.`,
  "msg-start-2":
    "Para lhe ajudar na busca por uma vaga, gostaria de conhecer-lhe melhor.",
  "msg-start-3":
    'A próxima interação será bem rápida, não se preocupe!',
  "msg-start-4":
    "Serão apenas algumas perguntas sobre suas experiências profissionais.",
  "msg-agreement":
    "Então, acho que podemos iniciar!",
  "msg-continue-1": "Bem, vamos lá!",
  "msg-location": ({ name, city, state }) =>
    `${name}, você tem interesse em trabalhar somente na cidade de ${city} - ${state} ou tem interesse em outras localidades também?`,
  "msg-continue-2":
    "Uma dica: você poderá modificar ou complementar o seu perfil a qualquer momento posteriormente, sempre que precisar.",
  "msg-continue-3":
    "Conte-me agora sobre sua formação, cursos realizados, treinamentos e outras modalidades de capacitação.",
  "msg-start-education": "Sobre qual você gostaria de informar agora?",
  "msg-elementary-school": "Qual a situação no Ensino Fundamental?",
  "msg-high-school": "Qual a situação no Ensino Médio?",
  "msg-other-course":
    "Há algum outro curso que você tenha realizado e gostaria de informar agora?",
  "msg-course-other": "Por favor, digite o nome do curso ou do treinamento:",
  "msg-course-other-situation": "Qual a situação?",
  "msg-other-course-more": "Gostaria de informar algum outro nesse momento?",
  "msg-technical-professional": "Qual o nome do curso?",
  "msg-technical-professional-situation": "Qual a situação?",
  "msg-technical-professional-other-course":
    "Gostaria de informar algum outro curso profissionalizante nesse momento?",
  "msg-graduation": "Qual o curso de graduação? (digite parte do título)",
  "msg-graduation-degrees": "Qual o nível acadêmico?",
  "msg-graduation-situation": "Qual a situação?",
  "msg-master": "Digite o curso de mestrado:",
  "msg-master-situation": "Qual a situação?",
  "msg-master-professional": "Informe o curso de mestrado profissional:",
  "msg-master-professional-situation": "Qual a situação?",
  "msg-other-specialization": "Qual o curso? (digite parte do título)",
  "msg-other-specialization-situation": "Qual a situação?",
  "msg-doctorate": "Qual o curso de doutorado?",
  "msg-doctorate-situation": "Qual a situação?",
  "msg-continue-4": "Entendido, estamos quase finalizando...",
  "msg-continue-5":
    "Agora conte-me um pouco sobre as suas experiências profissionais.",
  "msg-job": "Qual a sua profissão?",
  "msg-experience-period": ({ profession }) =>
    `Quanto tempo de experiência você acumulou como ${profession}?`,
  "msg-experience-segment": ({ profession }) =>
    `Em quais das áreas abaixo você já atuou como ${profession}? Escolha pelo menos 1 opção.`,
  "msg-other-job":
    "Entendi. Você gostaria de informar alguma outra experiência agora?",
  "msg-contracting-modality":
    "Perfeito. Agora eu gostaria de conhecer sua disponibilidade para trabalho.",
  "msg-shift-preferences":
    "Em quais turnos você teria disponibilidade para trabalhar?",
  "msg-skills":
    "Anotado. Dentre as habilidades a seguir, com quais você mais se identifica? (Escolha no máximo 3 opções)",
  "msg-special-needs": "Você é portador de alguma necessidade especial?",
  "msg-special-needs-1": "Qual a sua necessidade especial?",
  "msg-special-needs-2": "Você poderia adicionar detalhes no campo a seguir?",
  "msg-finish-1": ({ name }) =>
    `Pronto, ${name}! Terminamos o seu perfil profissional. Já vou disponibilizá-lo na nossa vitrine para que você possa ser encontrado(a) para vagas de seu interesse.`,
  "msg-finish-2":
    "É importante observar que, a qualquer momento, você pode ser convidado a participar de um bate-papo com um empregador interessado. Note que quem disponibiliza uma vaga não saberá o seu nome ou quem você é; apenas visualizará o seu perfil.",
  "msg-authorization-1":
    "Você autoriza solicitações de conversa enviadas pelos empregadores que se interessarem pelo seu perfil?",
  "msg-authorization-2":
    "Você tem certeza? Neste caso os empregadores não vão poder conhecer você... Não prefere então autorizar?",
  "msg-policies":
    "Ótimo! Por fim, você aceita nossas políticas?\n",
  "msg-end":
    "Parabéns, terminamos! Agora você está na nossa vitrine. Fique atento às solicitações de bate-papo que virão. Você poderá examiná-las e decidir quais deseja aceitar ou não. As solicitações que você aceitar abrirão a possibilidade de bate-papos com potenciais empregadores. As conversas com empregadores interessados fluirão livremente. Desejamos muito boa sorte a você! Conte conosco sempre que precisar.",
  "msg-already-choose":
    "Ei, você já escolheu essa escolaridade! Escolha outra, por favor."
};

/**
 * Retorna a mensagem correspondente aos critérios do usuário
 * Leva em consideração o ID da mensagem, idade do usuário e seu tipo
 * @param {String} messageId ID da mensagem, representado em String
 * @param {Object} data Objeto de dados para a mensagem. Ex: { name: "Fulano" }, { profession: "Engenheiro" }
 * @param {Number} age Idade do usuário. Número inteiro ou undefined
 * @returns {String} Mensagem correspondente
 */
export const getBotMessage = (messageId, data = undefined, age = undefined) => {
  let ref;
  if (age <= 25) ref = coolMessages[messageId];
  else if (age >= 26 && age <= 37) ref = regularMessages[messageId];
  else ref = formalMessages[messageId];
  return typeof ref === "function" ? ref(data) : ref;
};
