// @flow
import { Grid, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import Persona from "../../../components/Persona";
import TopBar from "../../../components/TopBar";
import { doLoadImportanceRange } from "../../../modules/form-data";
import {
  doPersistJob,
  doSelectSpecificProfissaoItemGrade
} from "../../../modules/job";
import JobCardLevel from "../components/JobCardLevel";
import styles from "./styles";

class JobSpecificProfissaoChoices extends Component {
  constructor(props) {
    super(props);

    this.header = React.createRef();

    this.state = {
      importanceRange: [],
      levelParams: props.match.params.option.split("-"),
      specificProfissao: null
    };
  }

  componentDidMount() {
    this.doLoadData();
  }

  doLoadData = () => {
    let profissao = this.props.profissoesConfirmed.find(
      p =>
        p.profissao.id === Number(this.state.levelParams[0]) &&
        p.profissao.text === this.state.levelParams[1]
    );

    this.setState({
      specificProfissao: profissao
    });

    doLoadImportanceRange(this.props.token).then(data => {
      this.setState({
        importanceRange: data
      });
    });
  };

  render() {
    const { classes, history } = this.props;
    const { importanceRange } = this.state;

    const grid = {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 4
    };

    return (
      <div className={classes.root}>
        <TopBar
          pageTitle="Profissão"
          buttonTitle="Confirmar"
          hasButton={true}
          buttonColor="primary"
          variant="contained"
          actionButton={() => history.replace("/job-profissao-choices")}
          actionBack={() => history.replace("/job-profissao-choices")}
        />

        <Grid container spacing={3}>
          <Persona
            style={{ paddingTop: 15 }}
            text={`Nosso algoritmo inteligente valoriza o que é mais importante para as suas escolhas. Você pode escolher diversas opções e selecionar um grau de importância para cada uma delas. A lista e a priorização dos candidatos se ajustarão automaticamente. Confirme suas escolhas sempre que solicitado no canto superior da tela.`}
          />

          {this.props.profissoesConfirmed.length > 0 &&
            this.state.specificProfissao !== null &&
            this.state.specificProfissao.profissao !== null &&
            this.props.profissoesConfirmed
              .filter(
                p =>
                  p.profissao.text ===
                    this.state.specificProfissao.profissao.text &&
                  p.profissao.id === this.state.specificProfissao.profissao.id
              )
              .map((vl, k) => {
                let thisCardTitle = vl.profissao.text + " - ";
                if (vl.experiencePeriod) {
                  thisCardTitle += vl.experiencePeriod.text;
                } else if (vl.segment) {
                  thisCardTitle += vl.segment.text;
                }

                return (
                  <Grid
                    key={k}
                    item
                    xs={grid.xs}
                    sm={grid.sm}
                    md={grid.md}
                    lg={grid.lg}
                  >
                    <JobCardLevel
                      master={false}
                      contentType="_SUBPROFISSAO"
                      checkboxHidden={true}
                      grade={vl.grade}
                      selectGradeFn={value => {
                        vl.grade = value;
                        this.props.doSelectSpecificProfissaoItemGrade(vl);
                      }}
                      cardTitle={thisCardTitle}
                      active={true}
                      choicesComponentUrl={null}
                      importanceRange={importanceRange}
                      jobId={this.props.jobId}
                      persistJobFn={this.props.doPersistJob}
                      history={this.props.history}
                    />
                  </Grid>
                );
              })}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  profissoesConfirmed: state.job.profissoesConfirmed,
  profissoesGrades: state.job.profissoesGrades,
  jobId: state.job.jobId,
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSelectSpecificProfissaoItemGrade,
      doPersistJob
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(JobSpecificProfissaoChoices))
);
