// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  withStyles,
  withMobileDialog,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Snackbar,
  Slide,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CircularProgress,
  Checkbox
} from "@material-ui/core";

//Component styles
import styles from "./styles";

import {
  doGetJsonProfile,
  doSaveProfile,
  doUpdateJsonProfile
} from "../../../../modules/user";

import { doLoadSegments } from "../../../../modules/form-data";

import {
  doUpdateProfile,
  forceReloadProfile,
  doConfirmExperienceSegments
} from "../../../../modules/profile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddProfissaoSelectSegment extends Component {
  constructor(props) {
    super(props);

    this.academicDegrees = [];

    this.state = {
      loading: false,
      openDialog: true,
      segments: [],
      gridSegments: [],
      selectedSegments: new Set(),
      selectedSegmentsIds: new Set(),
      showMsg: false
    };
  }

  componentDidMount() {
    this.loadSegments();
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  loadSegments = () => {
    const { token } = this.props;

    doLoadSegments(token).then(data => {
      this.setState({
        segments: data
      });
    });
  };

  toggleSegment = opt => {
    if (this.state.selectedSegmentsIds.has(opt.id)) {
      this.state.selectedSegments.delete(opt);
      this.state.selectedSegmentsIds.delete(opt.id);
    } else {
      this.state.selectedSegments.add(opt);
      this.state.selectedSegmentsIds.add(opt.id);
    }

    this.setState({
      selectedSegments: this.state.selectedSegments,
      selectedSegmentsIds: this.state.selectedSegmentsIds
    });
  };

  handleDialogClose = () => {
    //this.setState({ openDialog: false });
    const { CloseDialog } = this.props;
    CloseDialog();
  };

  handleSubmit = () => {
    const { doConfirmExperienceSegments } = this.props;
    const { selectedSegments } = this.state;

    const values = [...selectedSegments];
    doConfirmExperienceSegments(values);
    this.handleSaveEdit();
  };

  handleSaveEdit = () => {
    const {
      profile,
      token,
      doUpdateJsonProfile,
      forceReloadProfile,
      refreshData,
      CloseDialogSegment,
      history
    } = this.props;
    const userJsonData = doGetJsonProfile(profile);

    doSaveProfile(userJsonData, token).then(result => {
      doUpdateJsonProfile(result);
      forceReloadProfile(token);
      refreshData();
      CloseDialogSegment();
      this.setState({ showMsg: true });
      history.push({
        pathname: "/profile",
        search: "",
        state: { edited: "_profissao" }
      });
    });
  };

  render() {
    const { classes, fullScreen } = this.props;
    const {
      openDialog,
      loading,
      segments,
      selectedSegmentsIds,
      showMsg
    } = this.state;

    return (
      <Fragment>
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                Áreas de interesse
              </Typography>
              <Button color="inherit" onClick={() => this.handleSubmit()}>
                Confirmar
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.dialogContent}>
            <List>
              {loading && <CircularProgress color="secondary" />}

              {segments && segments.length > 0 ? (
                segments.map((item, key) => {
                  return (
                    <Fragment key={key}>
                      <ListItem component="li" button>
                        <ListItemText
                          id={`item-${item.id}`}
                          primary={this.capitalizeFirstLetter(item.text)}
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => this.toggleSegment(item)}
                            checked={
                              [...selectedSegmentsIds].indexOf(item.id) >= 0
                            }
                            inputProps={{
                              "aria-labelledby": `item-${item.id}`
                            }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider component="li" />
                    </Fragment>
                  );
                })
              ) : (
                <ListItem component="li">
                  <ListItemText
                    primary={`Nada foi encontrado!`}
                    className={classes.notFoundText}
                  />
                </ListItem>
              )}
            </List>
          </DialogContent>
        </Dialog>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={showMsg}
          onClose={() => {
            this.setState({ showMsg: false });
          }}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Salvo com sucesso!</span>}
        />
      </Fragment>
    );
  }
}

AddProfissaoSelectSegment.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  CloseDialog: PropTypes.func
};

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: state.formData.user && state.formData.user.initialized,
  profile: state.profile.profile
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmExperienceSegments,
      doUpdateJsonProfile,
      doUpdateProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(AddProfissaoSelectSegment)))
);
