// @flow
import React from "react";
import { withRouter } from "react-router-dom";
import Menu from "../../components/menu";
import options from "../../util/menu-options";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.options = options;
  }

  render() {
    return (
      <div>
        <div
          className="main-screen"
          style={{ paddingLeft: 0, paddingRight: 0 }}
        />

        <Menu options={this.options} />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
