// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  withMobileDialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Input,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress
} from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";

import { doLoadOcupations } from "../../../../modules/form-data";
import { doConfirmProfissao, doAddNewProfissao } from "../../../../modules/job";

import styles from "./styles";
import Persona from "../../../../components/Persona";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectProfissao extends Component {
  constructor(props) {
    super(props);

    this.academicDegrees = [];

    this.state = {
      openDialog: true,
      ocupations: [],
      ocupationName: null,
      searching: false
    };
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  handleDoSearch = evt => {
    if (evt.length <= 0) {
      this.setState({
        searching: false,
        ocupations: []
      });
    }

    if (evt.length < 3) return;

    this.setState({
      searching: true,
      courseName: evt
    });

    doLoadOcupations(this.props.token, evt).then(data => {
      this.setState({
        searching: false,
        ocupations: data
      });
    });
  };

  handleSelectProfissao = item => {
    const { doConfirmProfissao, selectedProfissao } = this.props;

    doConfirmProfissao(item);
    this.setState({ openDialog: false });

    setTimeout(() => {
      selectedProfissao();
    }, 100);
  };

  handleCloseDialog = () => {
    const { managerDialogSelectProfissao } = this.props;
    this.setState({ openDialog: false });
    setTimeout(() => {
      managerDialogSelectProfissao();
    }, 100);
  };

  render() {
    const { classes, fullScreen } = this.props;
    const { openDialog, ocupations, searching } = this.state;

    return (
      <Fragment>
        {!openDialog && <CircularProgress color="secondary" />}
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.handleCloseDialog()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                Profissão
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogTitle id="responsive-dialog-title" className={classes.header}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              autoFocus={true}
              type="text"
              ref={inputElement => {
                this.inputField = inputElement;
              }}
              placeholder="Busque pelo cargo"
              className={classes.inputSearch}
              onChange={evt => {
                this.handleDoSearch(evt.target.value);
              }}
            />
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            <List>
              {searching && <CircularProgress color="secondary" />}
              {ocupations && ocupations.length > 0 ? (
                ocupations.map((e, key) => {
                  if (e.id) {
                    return (
                      <Fragment key={key}>
                        <ListItem
                          component="li"
                          button
                          onClick={() => this.handleSelectProfissao(e)}
                        >
                          <ListItemText
                            primary={this.capitalizeFirstLetter(e.text)}
                          />
                        </ListItem>
                        <Divider component="li" />
                      </Fragment>
                    );
                  } else {
                    return (
                      <ListItem component="li">
                      <Persona
                        text={`Não encontrei nenhum resultado com esses termos... Tente buscar novamente.`}
                      />
                    </ListItem>
                    );
                  }
                })
              ) : (
                <ListItem component="li">
                  <Persona
                    text={`Aqui você pode escolher a profissão que deseja. Poderá também escolher várias profissões que se assemelham ao que você deseja. `}
                  />
                </ListItem>
              )}
            </List>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

SelectProfissao.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmProfissao,
      doAddNewProfissao
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(SelectProfissao)))
);
