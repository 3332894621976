// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Loading from "../loading";

import { doLoadEducationLevels } from "../../modules/form-data";
import { doConfirmCourseLevel } from "../../modules/job";

class JobQualificacaoAddChoice extends React.Component {
  constructor(props) {
    super(props);

    this.educationLevels = [];
    this.gridEducationLevels = [];
  }
  componentDidMount() {
    doLoadEducationLevels(this.props.token).then(data => {
      this.educationLevels = data;
      let aux = [];

      data.forEach((el, k) => {
        aux.push(el);

        if ((k + 1) % 3 === 0) {
          this.gridEducationLevels.push(aux);
          aux = [];
        }
      });

      this.forceUpdate();
    });
  }
  render() {
    const { classes } = this.props;

    if (this.gridEducationLevels.length === 0) {
      return <Loading />;
    } else {
      return (
        <div>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={() => {
                  this.props.history.replace("/job-qualificacao-choices");
                }}
              >
                <Icon className={classes.icon}>keyboard_arrow_left</Icon>
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                Grau de instrução
              </Typography>
              <Button
                color="inherit"
                onClick={() => {
                  this.props.history.replace("/job-qualificacao-choices");
                }}
              >
                Confirmar
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container className={classes.root} spacing={8}>
            {this.gridEducationLevels.map((row, k1) => {
              return (
                <Grid key={k1} item xs={12}>
                  <Grid container justifyContent="center" spacing={8}>
                    {row.map((col, k2) => {
                      return (
                        <Grid key={k2} item>
                          <Paper
                            className={classes.smallCard}
                            onClick={() => {
                              this.props.doConfirmCourseLevel(col);
                            }}
                          >
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={require("../../images/84b12ae895a9177449241d9d65b92f3c_757575.svg")}
                                alt="Graduation Cap Icon"
                                width={50}
                                height={50}
                              />
                              <div className={classes.smallCardText}>
                                {col.text}
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <div className={classes.footer} />
        </div>
      );
    }
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20
  },
  flex: {
    flex: 1
  },
  smallCard: {
    height: 110,
    width: 110,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  smallCardText: {
    fontSize: 13
  },
  footer: {
    position: "fixed",
    bottom: 20,
    left: "50%",
    transform: "translateX(-50%)"
  }
});

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourseLevel
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(JobQualificacaoAddChoice))
);
