export default theme => ({
  root: {},
  header: {
    backgroundColor: "#e7eaec",
    padding: 0,
    position: "relative"
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold"
  },
  searchIcon: {
    position: "absolute",
    left: 5,
    top: 15,
    color: "#777"
  },
  inputSearch: {
    margin: 0,
    backgroundColor: "transparent",
    border: 0,
    width: "100%",
    height: "50px",
    padding: "5px 15px",
    paddingLeft: "30px"
  },
  dialogContent: {
    margin: 0,
    padding: 0
  },
  notFoundText: {
    color: "#888",
    fontSize: "12px"
  }
});
