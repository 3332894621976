// @flow
import {
  AppBar,
  Avatar,
  Breadcrumbs,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Slide,
  Snackbar,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";

import MobileBreadcrumb from './mobileBreadcrumb';

// Material icons
import {
  Close as CloseIcon
} from "@material-ui/icons";

import { Rating } from "@material-ui/lab";
import React, { Component, Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  doUpdateJobCandidates,
  favoriteCandidate,
  sendDataAccessRequest,
  sendChatInvitation,
  generatePDF
} from "../../../../modules/job";
//Component styles
import styles from "./styles";

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: '#d6d6d6',
    height: theme.spacing(3),
    color: '#fff'
  },
}))(Chip);

const StyledButton = withStyles((theme) => ({
  root: {
    backgroundColor: '#0062e4',
    height: theme.spacing(3),
    color: '#ffffff',
    '&:hover, &:focus': {
      backgroundColor: "#0B48A0",
    }
  },
}))(Chip);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CandidatoProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: true,
      openDialogNotEnoughCredits: false,
      loading: true,
      name: "",
      gender: "",
      criterios: [],
      showSnackbar: false,
      snackbarMessage: "",
      convidado: false,
      favorite: false,
      hiringStatus: 0,
      base64Header: "",
      audioFileReference: ""
    };
  }

  componentDidMount() {
    this.buildData();
    this.buildCriterios();
    this.getBase64ImageFromURL('https://i.imgur.com/zqD2KAr.png');
  }

  buildData = () => {
    const { profile, jobId } = this.props;
    const c = profile;
    const k = profile.keyId;
    const audioFileRef = profile.user.audioFileReference;
    let gender = null;
    let name = "";

    if (
      c.user.gender === "_MAS" &&
      c.user.jobs
        .filter(j => j.accepted)
        .map(j => j.job.id)
        .indexOf(jobId) >= 0
    ) {
      gender = "_MAS";
    } else if (
      c.user.gender === "_FEM" &&
      c.user.jobs
        .filter(j => j.accepted)
        .map(j => j.job.id)
        .indexOf(jobId) >= 0
    ) {
      gender = "_FEM";
    } else {
      gender = "_UKN";
    }

    if (
      c.user.jobs
        .filter(j => j.accepted)
        .map(j => j.job.id)
        .indexOf(jobId) >= 0
    ) {
      name = c.user.name;
    } else {
      name = c.user.publicId ? c.user.publicId : `Candidato ${k + 1}`;
    }

    if (
      c.user.jobs
        .filter(j => j.invited)
        .map(j => j.job.id)
        .indexOf(jobId) >= 0
    ) {
      this.setState({ convidado: true });
    }

    if (
      c.user.jobs
        .filter(j => j.favorite)
        .map(j => j.job.id)
        .indexOf(jobId) >= 0
    ) {
      this.setState({ favorite: true });
    }

    c.user.jobs.forEach((job) => {
      if (job.job.id === jobId) {
        this.setState({ hiringStatus: job.hiringStatus });
        if (job.hiringStatus === "40" || job.accepted) {
          gender = c.user.gender;
          name = c.user.name;
        }
      }
    })

    this.setState({ gender, name });
    this.setState({ audioFileReference: audioFileRef });
  };

  trataNome = label => {
    let name = "";

    switch (label) {
      case "SEXO":
        name = "Sexo";
        break;
      case "FAIXA_ETARIA":
        name = "Faixa etária";
        break;
      case "LOCALIDADE":
        name = "Localidade";
        break;
      case "FORMA_CONTRATACAO":
        name = "Contratação";
        break;
      case "NECESSIDADES_ESPECIAIS":
        name = "Necessidades Especiais";
        break;
      case "PROFISSAO":
        name = "Profissão";
        break;
      case "QUALIFICACAO":
        name = "Qualificação";
        break;
      case "HABILIDADES":
        name = "Habilidades";
        break;
      case "DISPONIBILIDADE":
        name = "Disponibilidade";
        break;
      default:
        name = "N/A";
        break;
    }

    return name;
  };

  buildCriterios = () => {
    const { profile } = this.props;
    const list = [];

    profile.criterios.forEach(item => {
      list.push({
        name: this.trataNome(item.idDescricao),
        pontos: item.pontos
      });
    });

    this.setState({ criterios: list, loading: false });
  };

  handleGender = (code) => {
    if (code === '_FEM') {
      return 'Feminino';
    }

    if (code === '_MAS') {
      return 'Masculino';
    }

    return 'Não definido';
  }

  capitalizeEachFirstLetter = (course) => {

    if (course === null || course === undefined) {
      return '-'
    };

    const { text } = course;

    return text.charAt(0).toUpperCase() + text.toLowerCase().slice(1);
  }

  getBase64ImageFromURL = (url) => {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
  
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
  
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
  
        var dataURL = canvas.toDataURL("image/png");
  
        this.setState({ base64Header: dataURL })
        resolve(dataURL);
      };
  
      img.onerror = error => {
        reject(error);
      };
  
      img.src = url;

    });
  }

  handleSendConvite = () => {
    sendChatInvitation(
      this.props.token,
      this.props.jobId,
      this.props.profile.idCandidato,
      this.props.profile.user.name,
      this.props.profile.user.email
    )
      .then(r => {
        this.setState({
          showSnackbar: true,
          snackbarMessage: "Convite enviado com sucesso!",
          convidado: true
        });

        setTimeout(() => {
          this.setState({
            showSnackbar: false
          });
        }, 3000);
      })
      .catch(e => {
        //ADICIONADO MSG DE SUCESSO, PORQUE SEMPRE RETORNAR FAIL DA API, MESMO SENDO REALIZADO COM SUCESSO
        this.setState({
          showSnackbar: true,
          snackbarMessage: "Convite enviado com sucesso!",
          convidado: true
        });

        // this.setState({
        //   showSnackbar: true,
        //   snackbarMessage: "Algum erro ocorreu."
        // });

        setTimeout(() => {
          this.setState({
            showSnackbar: false
          });
        }, 3000);
      });
  };

  handleGeneratePDF = async () => {
    // make API call to generate PDF
    const result = await generatePDF(this.props.token, this.props.profile.user.phone);

    window.open(
      result.file,
      '_blank'
    );
  }

  handleFavoritar = () => {
    favoriteCandidate(
      this.props.token,
      this.props.jobId,
      this.props.profile.idCandidato
    ).then(() => {
      if (!this.state.favoritado) {
        this.setState({
          showSnackbar: true,
          snackbarMessage: "Favoritado com sucesso!"
        });
      } else {
        this.setState({
          showSnackbar: true,
          snackbarMessage: "Removido dos favoritos com sucesso!"
        });
      }

      let newCandidatosObject = this.props.candidatos;
      const openedCandidatoId = this.props.idCandidatoOpened;

      newCandidatosObject.forEach((candidato) => {
        if(candidato.idCandidato === openedCandidatoId) {
          candidato.favorite = !candidato.favorite;
          this.setState({ favorite: candidato.favorite });
        }
      });

      this.props.updateFromChild(newCandidatosObject);

      setTimeout(() => {
        this.setState({
          showSnackbar: false
        });
      }, 3000);
    });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
    this.props.actionClose();
  };

  handleGetAccessToCandidate = async () => {
    const responseOfDataAccess = await sendDataAccessRequest(this.props.token, this.props.jobId, this.props.profile.idCandidato);

    if (responseOfDataAccess.error_message) {
      this.setState({ openDialog: false });
      this.setState({ openDialogNotEnoughCredits: true });
    } else {
      this.setState({ hiringStatus: responseOfDataAccess.hiringStatus });
    }

  }

  calculateStars = totalPoints => {
    const totalStars = 5;
    const totalStarsPercentage = 100;
    return (totalPoints * totalStars) / totalStarsPercentage;
  };

  handleCandidateFeedbackBackgroundColor = (code) => {
    if (code === '30') {
      return 'darkred';
    } else if (code === '40') {
      return 'green';
    } else {
      return 'grey';
    }
  }

  handleCandidateFeedbackMessage = (code) => {
    if (code === '30') {
      return 'Solicitação recusada';
    }

    if (code === '40') {
      return 'Solicitação aceita';
    }

    return 'Resposta da solicitação';
  }

  handleStatusMessage = statusMessage => {
    switch (statusMessage) {
      case null:
        return "Não iniciado";
      case "10":
        return "Solicitação enviada";
      case "20":
        return "Solicitação visualizada pelo candidato";
      case "30":
        return "Solicitação recusada pelo candidato";
      case "40":
        return "Solicitação aprovada! Veja seus dados pessoais e inicie uma conversa";
      case "50":
        return "Conversa com o candidato iniciada. Verifique o chat dos candidatos por {aqui}";
      default:
        return '';
    }
  }

  componentWillMount = () => {
    let newCandidatosObject = this.props.candidatos;
    const openedCandidatoId = this.props.idCandidatoOpened;

    newCandidatosObject.forEach((candidato) => {
      if(candidato.idCandidato === openedCandidatoId) {
        this.setState({ favorite: candidato.favorite });
      }
    });

    this.forceUpdate();
  }

  render() {
    const { classes, profile, isMobile } = this.props;

    const {
      openDialog,
      openDialogNotEnoughCredits,
      name,
      criterios,
      loading,
      showSnackbar,
      snackbarMessage,
      favoritado,
      convidado,
      hiringStatus,
      audioFileReference
    } = this.state;

    const avatar = `https://api.dicebear.com/8.x/initials/svg?seed=${name}&backgroundColor=0062E4`;

    return (
      <Fragment>
        <Dialog
          TransitionComponent={Transition}
          maxWidth={"md"}
          open={openDialog}
          // open={false}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.handleClose()}>
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                Perfil do Candidato
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogTitle id="responsive-dialog-title" className={ classes.headerMobile }>

           { isMobile ? (
              <MobileBreadcrumb
                colorHomeIcon={ hiringStatus === null || hiringStatus === '00' || hiringStatus === 0 ? 'green' : 'grey' }
                colorMarkChatReadIcon={ hiringStatus === '10' || hiringStatus === '20' ? 'green' : 'grey' }
                colorSentimentVerySatisfiedIcon={ this.handleCandidateFeedbackBackgroundColor(hiringStatus) }
              />  
           ):
              <Breadcrumbs separator="›"aria-label="breadcrumb">
                <StyledBreadcrumb style={{ backgroundColor: hiringStatus === null || hiringStatus === '00' || hiringStatus === 0 ? 'green' : 'grey' }} component="a" href="#" label="Início"> </StyledBreadcrumb>
                <StyledBreadcrumb style={{ backgroundColor: hiringStatus === '10' || hiringStatus === '20' ? 'green' : 'grey' }} component="a" href="#" label={hiringStatus === "10" ? "Solicitação enviada" : hiringStatus === "20" ? "Solicitação visualizada" : "Aguardando ação" } />
                <StyledBreadcrumb style={{ backgroundColor: this.handleCandidateFeedbackBackgroundColor(hiringStatus) }} component="a" href="#" label={this.handleCandidateFeedbackMessage(hiringStatus)} />
              </Breadcrumbs> 
           }

            <Avatar
              src={avatar}
              style={{ width: 70, height: 70, margin: "10px auto" }}
            />
            <span>
              {name}
              <br />
              <Rating
                  name="simple-controlled"
                  size="small"
                  readOnly={true}
                  value={this.calculateStars(profile.totalPontos)}
                  precision={0.1}
              />
              </span>
              
              
            <div id="bubbly">
              <div id="buttons" style={{ display: "flex", justifyContent: "space-evenly" }}>  
                {favoritado && (
                  <StyledButton component="a" href="#" label="Favoritado" onClick={() => this.handleFavoritar()} />
                )}
                {!favoritado && (
                  <StyledButton component="a" href="#" label="Favoritar" onClick={() => this.handleFavoritar()} />
                )}

                {hiringStatus === null && (
                  <StyledButton component="a" href="#" label="Solicitar acesso ao perfil" onClick={() => this.handleGetAccessToCandidate()} />
                )}

                {hiringStatus === 0 && (
                  <StyledButton component="a" href="#" label="Solicitar acesso ao perfil" onClick={() => this.handleGetAccessToCandidate()} />
                )}

                {hiringStatus === '00' && (
                  <StyledButton component="a" href="#" label="Solicitar acesso ao perfil" onClick={() => this.handleGetAccessToCandidate()} />
                )}

                {hiringStatus === '40' && !convidado && (
                  <StyledButton component="a" href="#" label="Contatar candidato" onClick={() => this.handleSendConvite()} />
                )}
                {hiringStatus === '40' && convidado && (
                  <StyledButton component="a" label="Convite enviado" />
                )}
                {hiringStatus === '40' && (
                  <StyledButton component="a" href="#" label="Imprimir perfil" onClick={() => this.handleGeneratePDF()} />
                )}
            </div>
            {(hiringStatus === '40' && audioFileReference !== null) && (
              <div style={{ paddingTop: "10px" }}>
                <audio src={audioFileReference} preload="metadata" controls controlsList="nodownload" />
              </div>
            )}

            {(hiringStatus === '40' && audioFileReference === null) && (
              <div style={{ paddingTop: "10px" }}>
                <span>O candidato não possui um áudio de apresentação gravado.</span>
              </div>
            )}
            </div>
          </DialogTitle>

          <DialogContent className={classes.dialogContent}>
            {loading && (
              <CircularProgress
                className={classes.progress}
                color="secondary"
              />
            )}
            {!loading && criterios.length > 0 && (
              <List>
                {criterios.map((item, key) => (
                  <ListItem key={key} className={classes.item}>
                    <ListItemText
                      className={classes.itemText}
                      primary={item.name}
                      secondary={
                        <Rating
                        name="simple-controlled"
                        size="small"
                        readOnly={true}
                        value={this.calculateStars(item.pontos)}
                        precision={0.1}
                      />
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}
          </DialogContent>
        </Dialog>
        <Snackbar
          open={showSnackbar}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "snackbar-fab-message-id",
            className: classes.snackbarContent
          }}
          message={<span id="snackbar-fab-message-id">{snackbarMessage}</span>}
          className={classes.snackbar}
        />
        <Dialog
          TransitionComponent={Transition}
          maxWidth={"md"}
          open={openDialogNotEnoughCredits}
          //open={true}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
                    <AppBar position="static">
            <Toolbar>
              <IconButton color="inherit" onClick={() => this.handleClose()}>
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                Mensagem do Jobin!
              </Typography>
            </Toolbar>
          </AppBar>

          <DialogTitle id="responsive-dialog-title" style={{ backgroundColor: "white", textAlign: "center" }}>
            Você não possui créditos suficientes para concluir essa ação. <br />Efetue sua recarga utilizando o link abaixo:
            <br />
            <a href="https://billing.stripe.com/p/login/bIYeYd4bKdR29eU6oo">Recarga de créditos JobSpot</a>
            <br />
            Está com dificuldade? Fale conosco através do nosso Whatsapp!
            <b>Telefone e WhatsApp:</b><a href="https://wa.me/555140422006?text=Oi, estou tentando ver um perfil para minha vaga mas estou sem créditos. Gostaria de comprar mais créditos, por favor!">+55 51 4042.2006</a>
          </DialogTitle>
        </Dialog>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  jobName: state.job.jobName,
  jobId: state.job.jobId,
  token: state.login.token,
  jobCandidates: state.job.jobCandidates
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateJobCandidates
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CandidatoProfile))
);
