export default theme => ({
  root: {},
  progress: {
    margin: "15px auto"
  },
  header: {
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    "& span": {
      fontSize: "14px",
      fontWeight: "bold"
    }
  },
  headerMobile: {
    backgroundColor: "#ffffff",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    textAlign: "center",
    "& span": {
      fontSize: "10px",
      fontWeight: "bold"
    }
  },
  item: {
    padding: "5px 0",
    borderTop: "1px solid #e2e2e2"
  },
  itemText: {
    padding: "0",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& span": {
      flexGrow: 1
    },
    "& p": {
      padding: "2px 5px",
      color: "white",
      fontSize: "11px",
      borderRadius: "4px",
      marginBottom: "0px"
    }
  },
  icon: {
    textTransform: "none",
    display: "flex",
    color: "white",
    alignContent: "space-between",
    alignItems: "flex-end",
    flexDirection: "column-reverse",
    flexWrap: "wrap",
    justifyContent: "space-evenly"
  },
  button: {
    backgroundColor: "rgb(0, 98, 228) !important"
  }
});
