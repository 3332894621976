// @flow
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import TextField from "@material-ui/core/TextField/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import moment from "moment";
import "moment/locale/pt-br";

import EmailIcon from '@mui/icons-material/Email';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { Slide, Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Loading from "../loading";
import i18n from "../../util/i18n";
import { Snackbar, withStyles } from "@material-ui/core";

import {
  doCreateAccountStep2Company,
  doUpdateData,
  doClearCreateAccountMessage
} from "../../modules/create-account";
import { doLoadStates } from "../../modules/form-data";
import { ContainerBox, ContainerJobSpot, Logo, LoadingContent, SubmitButton } from "./styles";

moment.locale("pt-br");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CreateAccountStep2Company extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      vertical: "top",
      horizontal: "center",
      openConfirmationMethod: false,
      isHoveringWhatsApp: false,
      isHoveringEmail: false,
      appUsagePolicy: false,
      appPrivacyPolicy: false,
      appCookiesPolicy: false
    };
  }

  handleMouseEnterWhatsApp = () => {
    this.setState({ isHoveringWhatsApp: true });
  };

  handleMouseLeaveWhatsApp = () => {
    this.setState({ isHoveringWhatsApp: false });
  };

  handleMouseEnterEmail = () => {
    this.setState({ isHoveringEmail: true });
  };

  handleMouseLeaveEmail = () => {
    this.setState({ isHoveringEmail: false });
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  capitalizeEachFirstLetter(string) {
    let arr = string.split(" ");
    arr = arr.map(s => {
      return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
    });
    return arr.join(" ");
  }

  componentDidMount() {
    if (this.props.states.length === 0) {
      this.props.doLoadStates();
    }
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleSubmit = () => {
    if (this.formRef.current.reportValidity()) {
      this.handleChooseValidationForm();
    }
    //  this.props.doCreateAccountStep2Company();
  };

  handleChooseValidationForm = () => {
    this.setState({ openConfirmationMethod: true });
  }

  handleToggleAppUsagePolicy = () => {
    this.setState({ appUsagePolicy: !this.state.appUsagePolicy });
  }

  handleToggleAppPrivacyPolicy = () => {
    this.setState({ appPrivacyPolicy: !this.state.appPrivacyPolicy });
  }

  handleToggleAppCookiesPolicy = () => {
    this.setState({ appCookiesPolicy: !this.state.appCookiesPolicy });
  }

  handleSendUserWhatsApp = () => {
    this.setState({ openConfirmationMethod: false });
    this.props.doCreateAccountStep2Company('whatsapp');
  }

  handleSendUserEmail = () => {
    this.setState({ openConfirmationMethod: false });
    this.props.doCreateAccountStep2Company('email');
  }

  render() {
    const {
      isCreating,
      history,
      states,
      showMessage,
      message,
      email,
      doClearCreateAccountMessage
    } = this.props;
    const { vertical, horizontal, openConfirmationMethod, appCookiesPolicy, appPrivacyPolicy, appUsagePolicy } = this.state;

    return(
    <Fragment>
          <Dialog
            TransitionComponent={Transition}
            maxWidth={"xl"}
            onClose={() => { this.setState({ openConfirmationMethod: false }) }}
            open={openConfirmationMethod}
            aria-labelledby="responsive-dialog-title"
            style={{ display: "flex", flexDirection: "column"}}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Nossa plataforma precisa verificar se você é real! O e-mail informado está correto?
              </DialogContentText>

              <Card sx={{ minWidth: 250 }} 
                  style={{
                    cursor: 'pointer',
                    backgroundColor: this.state.isHoveringEmail ? 'rgb(0,126,252)' : '',
                    color: this.state.isHoveringEmail ? 'white' : '',
                  }}
                  onMouseEnter={this.handleMouseEnterEmail}
                  onMouseLeave={this.handleMouseLeaveEmail}
                  onClick={this.handleSendUserEmail}
                  >
                <CardContent>
                  <EmailIcon style={{ color: this.state.isHoveringEmail ? 'white' : 'rgb(0,126,252)', position: "relative", bottom: "-5" }} /> Sim, o e-mail: <b>{email}</b> está correto!
                </CardContent>
              </Card>

              <br />
              
            </DialogContent>
          </Dialog>
      {states.length < 1 ? (
          <Loading />
        ) : (
          <Fragment>
            <ContainerJobSpot component="main" maxWidth="xs">
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={showMessage}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{message}</span>}
            autoHideDuration={6000}
            onClose={() => doClearCreateAccountMessage()}
          />

          <ContainerBox
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              onClick={() => history.replace("/create-account")}
              color="inherit"
              aria-label="Menu"
            >
              <ArrowBackIcon />
            </IconButton>
            <Logo>
              <img src={require("../../images/logo.png")} alt="Logo" />
            </Logo>
            <form noValidate ref={this.formRef}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="companyName"
                  name="companyName"
                  label={i18n.t("companyName")}
                  value={this.props.companyName}
                  onChange={e => {
                    this.props.doUpdateData({
                      companyName: e.target.value
                    });
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="socialNumber"
                  name="socialNumber"
                  label={i18n.t("companySocialNumber")}
                  value={this.props.socialNumber}
                  onChange={e => {
                    let txt = e.target.value
                      .replace(/[^0-9]/g, "")
                      .substr(0, 14);

                    let maskedTxt = txt.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                      "$1.$2.$3/$4-$5"
                    );

                    this.props.doUpdateData({
                      socialNumber: maskedTxt
                    });
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="firstNameLastName"
                  name="firstNameLastName"
                  label={i18n.t("representativeName")}
                  value={this.props.firstNameLastName}
                  onChange={e => {
                    this.props.doUpdateData({
                      firstNameLastName: e.target.value
                    });
                  }}
                />
                </Grid>

                <Grid item xs={12}>
                <TextField
                variant="outlined"
                fullWidth
                id="phone"
                name="phone"
                label={i18n.t("phone")}
                value={this.props.phone}
                onFocus={e => {
                  let txt = e.target.value
                    .trim()
                    .replace(/[^0-9]/g, "")
                    .substr(0, 11);

                  this.props.doUpdateData({
                    phone: txt.trim()
                  });
                }}
                onChange={e => {
                  let txt = e.target.value
                    .trim()
                    .replace(/[^0-9]/g, "")
                    .substr(0, 11);

                  this.props.doUpdateData({
                    phone: txt.trim()
                  });
                }}
                onBlur={e => {
                  let txt = e.target.value
                    .trim()
                    .replace(/[^0-9]/g, "")
                    .substr(0, 11);

                  let ddd = txt.substr(0, 2);
                  let phone = txt.substr(2).replace(/(.{3})/g, "$1 ");

                  let maskedTxt = ddd;
                  if (ddd.length === 2 && phone) maskedTxt = "(" + ddd + ") ";
                  if (phone) maskedTxt = maskedTxt + phone;

                  this.props.doUpdateData({
                    phone: maskedTxt.trim()
                  });
                }}
              />
                </Grid>

                <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel
                          htmlFor="state"
                          style={{
                            backgroundColor: "white",
                            paddingHorizontal: 5
                          }}
                        >
                          {i18n.t("state")}
                        </InputLabel>
                        <Select
                          value={this.props.state ? this.props.state.id : ""}
                          inputProps={{
                            name: "state",
                            id: "state"
                          }}
                          onChange={e => {
                            let st = this.props.states.find(m => {
                              return m.id === e.target.value;
                            });
        
                            this.props.doUpdateData({
                              state: st
                            });
                          }}
                          input={<OutlinedInput name="state" id="state" />}
                        >
                          <MenuItem value="">
                            <em>{i18n.t("state")}</em>
                          </MenuItem>
                          {this.props.states.map((e, key) => (
                            <MenuItem key={key} value={e.id}>
                              {e.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel
                          htmlFor="city"
                          style={{
                            backgroundColor: "white",
                            paddingHorizontal: 5
                          }}
                        >
                          {i18n.t("city")}
                        </InputLabel>
                        <Select
                          value={this.props.city ? this.props.city.id : ""}
                          inputProps={{
                            name: "city",
                            id: "city"
                          }}
                          onChange={e => {
                            let c = this.props.state.cities.find(m => {
                              return m.id === e.target.value;
                            });

                            this.props.doUpdateData({
                              city: c
                            });
                          }}
                          input={<OutlinedInput name="city" id="city" />}
                        >
                          <MenuItem value="">
                            <em>{i18n.t("city")}</em>
                          </MenuItem>
                          {this.props.state &&
                            this.props.state.cities.map((e, key) => (
                              <MenuItem key={key} value={e.id}>
                                {e.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                </Grid>

                <Grid item xs={14} sm={14}>
                  <FormGroup>
                    <FormControlLabel onClick={ this.handleToggleAppUsagePolicy } checked={ this.state.appUsagePolicy } control={<Checkbox />} label={<Typography style={{ fontSize: "12px" }}>Aceito os <a target="_blank" rel="noreferrer" href="https://jobspot.com.br/termos-de-uso/">termos de uso da aplicação</a> *</Typography>} />
                    <FormControlLabel onClick={ this.handleToggleAppPrivacyPolicy } checked={ this.state.appPrivacyPolicy } control={<Checkbox />} label={<Typography style={{ fontSize: "12px" }}>Aceito as <a target="_blank" rel="noreferrer" href="https://jobspot.com.br/politica-de-privacidade/">políticas de privacidade</a> da aplicação *</Typography>} />
                    <FormControlLabel onClick={ this.handleToggleAppCookiesPolicy } checked={ this.state.appCookiesPolicy } control={<Checkbox />} label={<Typography style={{ fontSize: "12px" }}>Aceito as <a target="_blank" rel="noreferrer" href="https://jobspot.com.br/politica-de-cookies/">políticas de uso de cookies</a> da aplicação *</Typography>} />
                  </FormGroup>
                </Grid>

              </Grid>
            </form>

            <Grid item xs={12}>
                      {isCreating ? (
                        <LoadingContent>
                          <CircularProgress />
                        </LoadingContent>
                      ) : (
                        <SubmitButton
                          type="button"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isCreating || !(appCookiesPolicy && appPrivacyPolicy && appUsagePolicy)}
                          onClick={this.handleSubmit}
                        >
                          {i18n.t("nextStep")}
                        </SubmitButton>
                      )}
                    </Grid>
          </ContainerBox>
            </ContainerJobSpot>
          </Fragment>
          )}
    </Fragment>)
  }
}

const mapStateToProps = state => ({
  phone: state.createAccount.phone,
  password: state.createAccount.password,
  passwordConfirmation: state.createAccount.passwordConfirmation,
  firstNameLastName: state.createAccount.firstNameLastName,
  socialNumber: state.createAccount.socialNumber,
  state: state.createAccount.state,
  city: state.createAccount.city,
  email: state.createAccount.email,
  emailConfirmation: state.createAccount.emailConfirmation,
  isCreating: state.createAccount.isCreating,
  message: state.createAccount.message,
  showMessage: state.createAccount.showMessage,
  states: state.formData.states,
  companyName: state.createAccount.companyName
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateAccountStep2Company,
      doUpdateData,
      doLoadStates,
      doClearCreateAccountMessage
    },
    dispatch
  );

const styles = theme => ({
  progress: {
    margin: theme.spacing(2)
  },
  root: {
    flexGrow: 1,
    height: "100vh"
  },
  flex: {
    flex: 1
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none"
  },
  menuButton: {
    color: "#787878"
  },
  mainContainer: {
    margin: 20,
    maxHeight: "calc(100vh - 125px)",
    overflowY: "auto",
    color: "#787878"
  },
  centerAlign: {
    textAlign: "center",
    height: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20
  },
  textField: {
    marginTop: 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CreateAccountStep2Company))
);
