export default theme => ({
  root: {
    padding: theme.spacing(2)
  },
  flex: {
    flex: 1
  },
  optionCard: {
    height: 135,
    maxWidth: 135,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    margin: "0 auto",
    "& p": {
      fontSize: "13px",
      fontWeight: "bold",
      textAlign: "center",
      padding: "3px",
      margin: "0px"
    },
    "& svg": {
      width: "38px",
      height: "38px",
      color: "#adbad2"
      //color: theme.palette.background.topbar
    }
  }
});
