export default theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    backgroundColor: "#f4f6f8",
    minHeight: "100vh",
    padding: 15
  }
});
