import styled from "styled-components";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

export const ContainerJobSpot = styled(Container)`
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
  max-width: 500px !important;
  padding: 0 20px;
`;

export const Logo = styled.div`
  width: 100%;
  max-width: 230px;
  margin: 15px auto;
  padding: 20px 0;
  img {
    width: 100%;
  }

  @media (max-width: 600px) {
    padding: 45px 0 10px 0;
  }
`;

export const ContainerBox = styled(Grid)`
  border: 1px solid #dadce0;
  border-radius: 6px;
  padding: 20px 30px;
  display: flex-container;

  @media (max-width: 600px) {
    border: 0;
    padding: 0;
  }
`;

export const ContainerPolicyBox = styled(Grid)`
  border: 1px solid #dadce0;
  border-radius: 6px;
  padding: 20px 30px;
  display: flex-container;

  @media (max-width: 600px) {
    border: 0;
    padding: 0;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 15px !important;
`;

export const LoadingContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export const Buttons = styled(Grid)`
  display: flex;
  justify-content: center;
  button {
    margin: 15px;
  }
`;

export default () => ({
  cardSelectVerificationMethod: {
    "&:hover": {
      boxShadow: "0 16px 70px -12.125px rgba(0,0,0,0.3)"
    },
  }
});
