// @flow
import { createStore, applyMiddleware } from "redux";
import { routerMiddleware } from "react-router-redux";
import { persistStore, persistReducer } from "redux-persist";
//import storage from 'redux-persist/lib/storage';
import localForage from "localforage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import thunk from "redux-thunk";
import { createBrowserHistory } from 'history';
import rootReducer from "../modules";
import { composeWithDevTools } from "redux-devtools-extension";

export const history = createBrowserHistory();

const initialState = {};
const middleware = [thunk, routerMiddleware(history)];

const composedEnhancers = composeWithDevTools(applyMiddleware(...middleware));

const persistConfig = {
  key: "root",
  storage: localForage,
  blacklist: ["navigation", "findBot"],
  stateReconciler: autoMergeLevel2
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(
  persistedReducer,
  initialState,
  composedEnhancers
);
export const persistor = persistStore(store);
//persistor.purge();
