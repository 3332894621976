export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  card: {
    width: "100%",
    minHeight: "161px",
    padding: "30px 15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    cursor: "pointer",

    "& svg": {
      width: "48px",
      height: "48px",
      color: "#989ca0",
      marginBottom: "10px"
    },
    "& h3": {
      minHeight: "32px",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#333",
      marginBottom: "0px"
    }
  }
});
