export default theme => ({
    root: {},
    audioPlayer: {
        display: "flex",
        width: "232px",
        alignItems: "center",
        borderRadius: "15px",
        backgroundColor: "#ebf0f7",
        borderWidth: "2px",
        padding: "5px",
        justifyContent: "space-between"
    },
    playPause: {
        background: theme.palette.primary.main,
        border: "none",
        borderRadius: "50%",
        width: "30px",
        height: "30px",
        fontSize: "24px",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    rangeAudio: {
        display: "flex",
        alignItems: "center"
    },
    timeText: {
        fontFamily: "monospace"
    },
    progressBar: {
        barBg: "#ffe3d4",
        seekBeforeWidth: "100px",
        seekBeforeColor: "#ffc2a1",
        knobby: "#3452a5",
        selectedKnobby: "#26c9c3",
        appearence: "none",
        background: "#ffe3d4",
        borderRadius: "10px",
        position: "relative",
        width: "100%",
        height: "11px",
        outline: "none"
    }
  });
  