// @flow
import { INITIALIZE_USER_JSON } from "./form-data";

export const UPDATE_PROFILE = "user/UPDATE_PROFILE";
export const UPDATE_PROFILE_FROM_SERVER = "user/UPDATE_PROFILE_FROM_SERVER";
export const UPDATE_PROFILE_FINAL_STEP = "user/UPDATE_PROFILE_FINAL_STEP";

const initialState = {
  initialized: false,
  subscribedToOneSignal: false,
  profile: {
    idPessoa: null,
    nome: null,
    dataNascimento: null,
    Perfil: {
      ANONASC: null,
      SEXO: null,
      FORMA_CONTRATACAO: [],
      NECESSIDADES_ESPECIAIS: [],
      DISPONIBILIDADE: [],
      HABILIDADE: [],
      LOCALIDADE: {
        IDCIDADE: null,
        IDUF: null,
        COORDENADAS: [],
        caracteristicas: []
      },
      QUALIFICACAO: {
        _INSTRUCAO: []
      },
      PROFISSAO: {
        _PROFISSOES: []
      },
      TAG: []
    }
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        ...state.profile.data,
        initialized: true
      };

    case UPDATE_PROFILE_FROM_SERVER:
      return {
        ...state,
        profile: action.data,
        initialized: true
      };
    
    case UPDATE_PROFILE_FINAL_STEP:
      return {
        ...state,
        initialized: true
      }

    default:
      return state;
  }
};

export const doUpdateJsonProfile = json => {
  return dispatch => {
    dispatch({
      type: UPDATE_PROFILE_FROM_SERVER,
      data: json
    });

    dispatch({
      type: INITIALIZE_USER_JSON
    });
  };
};

export const doUpdateJsonProfileInitialized = json => {
  return dispatch => {
    dispatch({
      type: UPDATE_PROFILE_FINAL_STEP,
      data: json
    });

    dispatch({
      type: INITIALIZE_USER_JSON
    });
  };
};

export const doGetJsonProfile = user => {
  let jsonProfile = Object.assign({}, initialState.profile);

  jsonProfile.idPessoa = user.id;
  jsonProfile.nome = user.name;
  jsonProfile.dataNascimento = user.dateOfBirth;
  jsonProfile.Perfil.ANONASC = user.dateOfBirth.substr(6);
  jsonProfile.Perfil.SEXO = user.gender;
  jsonProfile.Perfil.LOCALIDADE.IDCIDADE = user.city.id;
  jsonProfile.Perfil.LOCALIDADE.IDUF = user.state.id;
  jsonProfile.Perfil.LOCALIDADE.COORDENADAS = [
    user.city.latitude,
    user.city.longitude
  ];

  jsonProfile.Perfil.FORMA_CONTRATACAO = [];
  user.contractingModalities.forEach(p => {
    jsonProfile.Perfil.FORMA_CONTRATACAO.push(p.value);
  });

  jsonProfile.Perfil.NECESSIDADES_ESPECIAIS = [];
  user.specialNeeds.forEach(p => {
    jsonProfile.Perfil.NECESSIDADES_ESPECIAIS.push(p.value);
  });

  jsonProfile.Perfil.DISPONIBILIDADE = [];
  user.shiftPreferences.forEach(p => {
    jsonProfile.Perfil.DISPONIBILIDADE.push(p.value);
  });

  jsonProfile.Perfil.HABILIDADE = [];
  user.skills.forEach(p => {
    jsonProfile.Perfil.HABILIDADE.push(p.value);
  });

  jsonProfile.Perfil.LOCALIDADE.caracteristicas = [];
  user.placesInterest.forEach(p => {
    jsonProfile.Perfil.LOCALIDADE.caracteristicas.push(p.value);
  });

  jsonProfile.Perfil.QUALIFICACAO._INSTRUCAO = [];
  user.educations.forEach(p => {
    jsonProfile.Perfil.QUALIFICACAO._INSTRUCAO.push({
      __TIPO: p.educationLevel ? p.educationLevel.value : null,
      __CURSO_ID: p.course ? p.course.id : null,
      __NIVEIS: [
        {
          __NIVEL: p.academicDegree ? p.academicDegree.value : null,
          __SITUACAO: p.situation ? p.situation.value : null
        }
      ]
    });
  });

  jsonProfile.Perfil.PROFISSAO._PROFISSOES = [];
  user.ocupations.forEach(p => {
    if (p.ocupation.id) {
      jsonProfile.Perfil.PROFISSAO._PROFISSOES.push({
        __CODIGO_CBO: p.ocupation ? p.ocupation.id : null,
        __TEMPO_EXPERIENCIA: p.experiencePeriod
          ? p.experiencePeriod.value
          : null,
        __SEGMENTOS: p.segments ? p.segments.map(s => s.value) : []
      });
    }
  });

  return jsonProfile;
};

export const doGetProfile = token => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/user";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => resolve(e));
        } else {
          result.text().then(e => reject(e));
        }
      })
      .catch(e => reject(e));
  });
};

export const doGetAllProfileRequests = token => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/user/profile-requests";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => resolve(e));
        } else {
          result.text().then(e => reject(e));
        }
      })
      .catch(e => reject(e));
  });
}

export const doGetAllPlans = token => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/access-plans/";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => resolve(e));
        } else {
          result.text().then(e => reject(e));
        }
      })
      .catch(e => reject(e));
  });
};

export const doSaveProfile = (data, token) => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/user/update-json";
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(data)
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {});
  });
};

export const getUser = (token, id) => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/user/find/" + id;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};
