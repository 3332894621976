export default theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4)
  },
  rootFull: {
    flexGrow: 1,
    padding: "0px",
    "& h1": {
      padding: "20px"
    }
  }
});
