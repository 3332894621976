import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  Accessible as AccessibleIcon,
  Assignment as AssignmentIcon,
  Place as PlaceIcon,
  Schedule as ScheduleIcon,
  School as SchoolIcon,
  Star as StarIcon,
  Work as WorkIcon,
  GraphicEq as AudioIcon
} from "@material-ui/icons";

 import AudioPlayer from "./components/AudioPlayer";

import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  doLoadAgeRanges,
  doLoadContractingModalities,
  doLoadGenders,
  doLoadImportanceRange,
  doLoadPlacesInterests,
  doLoadShiftPreferences,
  doLoadSkills,
  doLoadSpecialNeeds
} from "../../../../modules/form-data";
import { doUpdateProfile } from "../../../../modules/profile";
import { doGetProfile } from "../../../../modules/user";
import ProfileItem from "./components/ProfileItem";
import ProfileQualificacao from "./components/ProfileQualificacao";

//Component styles
import styles from "./styles";

class AccountDetails extends Component {
  state = {
    qualificacao: [],
    profissao: [],
    necessidade_especial: [],
    localidade: [],
    contratacao: [],
    disponibilidade: [],
    habilidades: [],

    importanceRange: [],
    genders: [],
    ageRanges: [],
    shiftPreferences: [],
    contractingModalities: [],
    skills: [],
    specialNeeds: [],
    placesInterest: [],
    audioFileReference: "",

    loadingQualificacao: false,
    loadingProfissao: false,
    loadingEspecial: false,
    loadingLocalidade: false,
    loadingContratacao: false,
    loadingDisponibilidade: false,
    loadingHabilidades: false,
    loadingAudioReference: false
  };

  componentDidMount() {
    this.loadAllOptions();
  }

  loadAllOptions = () => {
    Promise.all([
      doLoadImportanceRange(this.props.token),
      doLoadGenders(this.props.token),
      doLoadAgeRanges(this.props.token),
      doLoadShiftPreferences(this.props.token),
      doLoadContractingModalities(this.props.token),
      doLoadSkills(this.props.token),
      doLoadSpecialNeeds(this.props.token),
      doLoadPlacesInterests(this.props.token)
    ]).then(data => {

      this.setState({
        importanceRange: data[0],
        genders: data[1],
        ageRanges: data[2],
        shiftPreferences: data[3],
        contractingModalities: data[4],
        skills: data[5],
        specialNeeds: data[6],
        placesInterest: data[7],
        audioFileReference: this.props.profile.audioFileReference
      });

      this.getQualificacoes();
      this.getProfissoes();
      this.getLimitacoes();
      this.getLocalidade();
      this.getModalidadesContratacao();
      this.getDisponibilidade();
      this.getHabilidades();
      this.getAudioReference();
    });
  };

  getAudioReference = () => {
    this.setState({
      audioFileReference:
        this.props.profile.audioFileReference === "nao" ? undefined : this.props.profile.audioFileReference
      });
  }

  getQualificacoes = () => {
    this.setState({ loadingQualificacao: true });

    const { profile } = this.props;
    const data = [];

    profile.educations.forEach(item => {
      if (item.course) {
        data.push({
          id: item.id,
          uuid: item.uuid,
          title: item.course.text,
          subitens: [
            { text: item.educationLevel.text },
            { text: item.situation.text }
          ],
          selectedItens: []
        });
      } else {
        data.push({
          id: item.id,
          uuid: item.uuid,
          title: item.educationLevel.text,
          subitens: [{ text: item.situation.text }],
          selectedItens: []
        });
      }
    });

    this.setState({ qualificacao: data });
    setTimeout(() => {
      this.setState({ loadingQualificacao: false });
    }, 300);
  };

  getProfissoes = () => {
    this.setState({ loadingProfissao: true });
    const { profile } = this.props;
    const data = [];

    profile.ocupations.forEach(item => {
      data.push({
        id: item.id,
        uuid: item.uuid,
        title: item.ocupation.text,
        subitens: [{ text: item.experiencePeriod.text }, ...item.segments],
        selectedItens: []
      });
    });

    this.setState({ profissao: data });
    setTimeout(() => {
      this.setState({ loadingProfissao: false });
    }, 300);
  };

  getLimitacoes = () => {
    this.setState({ loadingEspecial: true });

    const { profile } = this.props;
    const data = [];

    profile.specialNeeds.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ necessidade_especial: data });
    setTimeout(() => {
      this.setState({ loadingEspecial: false });
    }, 300);
  };

  getLocalidade = () => {
    this.setState({ loadingLocalidade: true });

    const { profile } = this.props;
    const data = [];

    profile.placesInterest.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ localidade: data });
    setTimeout(() => {
      this.setState({ loadingLocalidade: false });
    }, 300);
  };

  getModalidadesContratacao = () => {
    this.setState({ loadingContratacao: true });
    const { profile } = this.props;
    const data = [];

    profile.contractingModalities.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ contratacao: data });
    setTimeout(() => {
      this.setState({ loadingContratacao: false });
    }, 300);
  };

  getDisponibilidade = () => {
    this.setState({ loadingDisponibilidade: true });
    const { profile } = this.props;
    const data = [];

    profile.shiftPreferences.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ disponibilidade: data });
    setTimeout(() => {
      this.setState({ loadingDisponibilidade: false });
    }, 300);
  };

  getHabilidades = () => {
    this.setState({ loadingHabilidades: true });
    const { profile } = this.props;
    const data = [];

    profile.skills.forEach(item => {
      data.push({
        id: item.id,
        title: item.text,
        subitens: []
      });
    });

    this.setState({ habilidades: data });
    setTimeout(() => {
      this.setState({ loadingHabilidades: false });
    }, 300);
  };

  updateProfile = targetContent => {
    if (targetContent === "QUALIFICACAO") {
      this.setState({ loadingQualificacao: true });
    } else {
      this.setState({ loadingProfissao: true });
    }

    doGetProfile(this.props.token)
      .then(p => {
        this.props.doUpdateProfile(p);

        this.loadAllOptions();
        if (targetContent === "QUALIFICACAO") {
          this.setState({ loadingQualificacao: false });
        } else {
          this.setState({ loadingProfissao: false });
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    const { classes, doUpdateProfile, location, profile } = this.props;
    const {
      qualificacao,
      profissao,
      necessidade_especial,
      localidade,
      contratacao,
      disponibilidade,
      habilidades,

      shiftPreferences,
      contractingModalities,
      skills,
      specialNeeds,
      placesInterest,

      loadingQualificacao,
      loadingProfissao,
      loadingEspecial,
      loadingLocalidade,
      loadingContratacao,
      loadingDisponibilidade,
      loadingHabilidades,
      loadingAudioReference,

      audioFileReference
    } = this.state;
    const grids = {
      xl: 3,
      lg: 4,
      md: 6,
      xs: 12
    };

    const cityName = profile.city.name;
    const stateInitials = profile.state.initials;

    return (
      <Fragment>
        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
          <ProfileQualificacao
            title="Qualificação"
            field="QUALIFICACAO"
            data={qualificacao}
            Icon={<SchoolIcon />}
            refreshData={() => this.getQualificacoes()}
            loading={loadingQualificacao}
            fieldEdited={
              location.state && location.state.edited === "_qualificacao"
            }
            refreshProfile={() => {
              this.updateProfile("QUALIFICACAO");
            }}
          />
        </Grid>

        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
          <ProfileQualificacao
            title="Profissão"
            field="PROFISSAO"
            data={profissao}
            Icon={<WorkIcon />}
            refreshData={() => this.getProfissoes()}
            loading={loadingProfissao}
            fieldEdited={
              location.state && location.state.edited === "_profissao"
            }
            refreshProfile={() => {
              this.updateProfile("PROFISSAO");
            }}
          />
        </Grid>

        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
          {necessidade_especial && (
            <ProfileItem
              title="Necessidades Especiais"
              targetName={"specialNeeds"}
              data={necessidade_especial}
              Icon={<AccessibleIcon />}
              fullList={specialNeeds}
              doUpdateProfile={doUpdateProfile}
              refreshData={() => this.getLimitacoes()}
              loading={loadingEspecial}
            />
          )}
        </Grid>

        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
          {localidade && (
            <ProfileItem
              title={`Localidade (${cityName} - ${stateInitials})`}
              targetName={"placesInterest"}
              data={localidade}
              Icon={<PlaceIcon />}
              fullList={placesInterest}
              doUpdateProfile={doUpdateProfile}
              refreshData={() => this.getLocalidade()}
              loading={loadingLocalidade}
            />
          )}
        </Grid>

        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
          {contratacao && (
            <ProfileItem
              title="Forma de Contratação"
              targetName={"contractingModalities"}
              data={contratacao}
              Icon={<AssignmentIcon />}
              fullList={contractingModalities}
              doUpdateProfile={doUpdateProfile}
              refreshData={() => this.getModalidadesContratacao()}
              loading={loadingContratacao}
            />
          )}
        </Grid>

        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
          {disponibilidade && (
            <ProfileItem
              title="Disponibilidade"
              targetName={"shiftPreferences"}
              data={disponibilidade}
              Icon={<ScheduleIcon />}
              fullList={shiftPreferences}
              doUpdateProfile={doUpdateProfile}
              refreshData={() => this.getDisponibilidade()}
              loading={loadingDisponibilidade}
            />
          )}
        </Grid>

        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
          {habilidades && (
            <ProfileItem
              title="Habilidades"
              targetName={"skills"}
              data={habilidades}
              Icon={<StarIcon />}
              fullList={skills}
              doUpdateProfile={doUpdateProfile}
              refreshData={() => this.getHabilidades()}
              loading={loadingHabilidades}
            />
          )}
        </Grid>
        <Grid
          item
          xl={grids.xl}
          lg={grids.lg}
          md={grids.md}
          xs={grids.xs}
          className={classes.grid}
        >
            <ProfileItem
              Icon={<AudioIcon />}
              title="Áudio de apresentação"
              targetName={"audioFileReference"}
              data={[{ id: audioFileReference ? "audioRef" : "noAudio", title: audioFileReference ? <AudioPlayer title={"audioRef1"} data={[]} reference={ audioFileReference } /> : "Você ainda não possui um áudio de apresentação.", text: audioFileReference ? "AudioReference" : "NoAudio" }]}
              fullList={"audioFileReference"}
              doUpdateProfile={ false }
              refreshData={() => ({ })}
              loading={loadingAudioReference}
            />
        </Grid>
      </Fragment>
    );
  }
}

AccountDetails.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: state.formData.user && state.formData.user.initialized,
  editing: state.profile.editing,
  qualificationOpen: state.profile.qualificationOpen,
  ocupationOpen: state.profile.ocupationOpen,
  specialNeedsOpen: state.profile.specialNeedsOpen,
  placesInterestOpen: state.profile.placesInterestOpen,
  contractingModalitiesOpen: state.profile.contractingModalitiesOpen,
  shiftPreferencesOpen: state.profile.shiftPreferencesOpen,
  skillsOpen: state.profile.skillsOpen,
  confirmOpen: state.profile.confirmOpen,
  confirmTitle: state.profile.confirmTitle,
  confirmText: state.profile.confirmText,
  confirmKey: state.profile.confirmKey,
  confirmObjId: state.profile.confirmObjId,
  profile: state.profile.profile,
  profileQualification: state.profile.profileQualification,
  profileOcupation: state.profile.profileOcupation,
  profileSpecialNeeds: state.profile.profileSpecialNeeds,
  profilePlacesInterest: state.profile.profilePlacesInterest,
  profileContractingModalities: state.profile.profileContractingModalities,
  profileShiftPreferences: state.profile.profileShiftPreferences,
  profileSkills: state.profile.profileSkills,
  loading: state.profile.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateProfile
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AccountDetails))
);
