// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import Persona from "../../../../components/Persona";

import {
  withStyles,
  withMobileDialog,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Input,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  IconButton
} from "@material-ui/core";

// Material icons
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

import { doLoadOcupations } from "../../../../modules/form-data";
import {
  doConfirmProfissao,
  doAddNewProfissao
} from "../../../../modules/profile";

import AddProfissaoSelectExperience from "../AddProfissaoSelectExperience";
import AddProfissaoSelectSegment from "../AddProfissaoSelectSegment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddProfissao extends Component {
  constructor(props) {
    super(props);

    this.academicDegrees = [];

    this.state = {
      ocupations: [],
      ocupationName: null,
      searching: false,

      search: "",
      data: [],
      openDialog: true,
      loadingSearch: false,

      openDialogExperience: false,
      openDialogSegment: false
    };
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  handleDoSearch = evt => {
    const { token } = this.props;

    if (evt.length < 3) return;

    this.setState({
      loadingSearch: true,
      searching: true,
      courseName: evt
    });

    doLoadOcupations(token, evt).then(data => {
      this.setState({
        loadingSearch: false,
        searching: false,
        ocupations: data
      });
    });
  };

  handleDialogClose = () => {
    //this.setState({ openDialog: false });
    const { CloseDialog } = this.props;
    CloseDialog();
  };

  handleSelectItem = e => {
    const { doConfirmProfissao } = this.props;

    doConfirmProfissao(e);
    this.setState({ openDialog: false, openDialogExperience: true });
  };

  handleDialogSegmetOpen = () => {
    this.setState({ openDialogExperience: false, openDialogSegment: true });
  };

  handleDialogSegmentClose = () => {
    this.setState({ openDialogExperience: false, openDialogSegment: false });
  };

  render() {
    const { classes, fullScreen, refreshData } = this.props;
    const {
      openDialog,
      ocupations,
      loadingSearch,
      openDialogExperience,
      openDialogSegment
    } = this.state;

    return (
      <Fragment>
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.handleDialogClose()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                Profissão
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogTitle id="responsive-dialog-title" className={classes.header}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              autoFocus={true}
              ref={inputElement => {
                this.inputField = inputElement;
              }}
              placeholder="Busque pelo cargo"
              className={classes.inputSearch}
              onChange={evt => {
                this.handleDoSearch(evt.target.value);
              }}
            />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <List>
              {loadingSearch && <CircularProgress color="secondary" />}
              {ocupations && ocupations.length > 0 ? (
                ocupations.map((e, key) => {
                  if (e.id) {
                    return (
                      <Fragment key={key}>
                        <ListItem
                          component="li"
                          button
                          onClick={() => this.handleSelectItem(e)}
                        >
                          <ListItemText
                            primary={this.capitalizeFirstLetter(e.text)}
                          />
                        </ListItem>
                        <Divider component="li" />
                      </Fragment>
                    );
                  } else {
                    return (
                      <ListItem component="li">
                      <Persona
                        text={`Não encontrei nenhum resultado com esses termos... Tente buscar novamente.`}
                      />
                    </ListItem>
                    );
                  }
                })
              ) : (
                <ListItem component="li">
                  <ListItemText
                    primary={`Nada foi encontrado!`}
                    className={classes.notFoundText}
                  />
                </ListItem>
              )}
            </List>
          </DialogContent>
        </Dialog>

        {openDialogExperience && (
          <AddProfissaoSelectExperience
            NextStep={() => this.handleDialogSegmetOpen()}
          />
        )}
        {openDialogSegment && (
          <AddProfissaoSelectSegment
            CloseDialogSegment={() => this.handleDialogSegmentClose()}
            refreshData={() => refreshData()}
          />
        )}
      </Fragment>
    );
  }
}

AddProfissao.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  CloseDialog: PropTypes.func
};

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmProfissao,
      doAddNewProfissao
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(AddProfissao)))
);
