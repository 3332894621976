// @flow
import React from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ChatIcon from "@material-ui/icons/Chat";
import SettingshIcon from "@material-ui/icons/Settings";
import PagesIcon from "@material-ui/icons/Pages";

export default class Footer extends React.Component {
  constructor(props) {
    super(props);

    let value = this.props.value;
    if (value === 0 && !this.props.userInitialized) value = 2;

    this.state = {
      value: value
    };
  }
  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    return (
      <BottomNavigation
        value={this.state.value}
        onChange={this.handleChange}
        showLabels
        style={styles.bottomNav}
      >
        <BottomNavigationAction
          onClick={() => {
            if (this.props.userInitialized)
              this.props.history.replace("/profile");
            else this.props.history.replace("/find-bot");
          }}
          label="Busco Trabalho"
          icon={<PagesIcon />}
        />
        <BottomNavigationAction
          onClick={() => {
            this.props.history.replace("/jobs");
          }}
          label="Busco Trabalhadores"
          icon={<PagesIcon />}
        />
        <BottomNavigationAction
          onClick={() => {
            this.props.history.replace("/chats");
          }}
          label="Conversas"
          icon={<ChatIcon />}
        />
        <BottomNavigationAction
          onClick={() => {
            this.props.history.push("/settings");
          }}
          label="Ajustes"
          icon={<SettingshIcon />}
        />
      </BottomNavigation>
    );
  }
}

const styles = {
  bottomNav: {
    width: "100vw",
    height: "82px",
    position: "absolute",
    bottom: 0,
    backgroundColor: "#FAFAFA"
  }
};
