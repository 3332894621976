// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Slide,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton
} from "@material-ui/core";

import { doConfirmCourseSituation } from "../../../../modules/job";
import { doLoadEducationSituations } from "../../../../modules/form-data";

// Material icons
import { Save as SaveIcon, Close as CloseIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectEducationSituation extends Component {
  constructor(props) {
    super(props);

    this.educationSituations = [];
    this.selectedCheckboxes = new Set();
    this.selectedValues = new Set();

    this.state = {
      selectedsQty: 0,
      openDialog: true,
      showMsg: false
    };
  }

  componentDidMount() {
    doLoadEducationSituations(this.props.token).then(data => {
      this.educationSituations = data;
      this.forceUpdate();
    });
  }

  toggleCheckbox = opt => {
    if (this.selectedCheckboxes.has(opt.text)) {
      this.selectedCheckboxes.delete(opt.text);
      this.selectedValues.delete(opt);
    } else {
      this.selectedCheckboxes.add(opt.text);
      this.selectedValues.add(opt);
    }

    this.setState({ selectedsQty: this.selectedCheckboxes.size });
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleSaveSituation = () => {
    const { doConfirmCourseSituation, history, courseSelected } = this.props;

    let values = [...this.selectedValues];
    doConfirmCourseSituation(values);
    if (courseSelected === "updateList") {
      this.props.handleCloseSelectSituation();
    } else {
      setTimeout(() => {
        history.replace(`/job-qualificacao-level-choices/${courseSelected}`);
      }, 100);
    }
  };

  render() {
    const { classes, courseLevel } = this.props;
    const { openDialog, selectedsQty } = this.state;

    let cardTitle = this.capitalizeFirstLetter(courseLevel.text);

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton
              color="inherit"
              onClick={() => {
                this.setState({ openDialog: false });
                this.props.handleCloseSelectSituation();
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {cardTitle}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogTitle id="responsive-dialog-title" className={classes.header}>
          Selecione a situação do curso
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <FormGroup
            aria-label="Situacao"
            name="situacao1"
            className={classes.group}
          >
            {this.educationSituations.map((el, k) => (
              <FormControlLabel
                key={k}
                value={el.value}
                control={<Checkbox />}
                label={el.text}
                onChange={() => this.toggleCheckbox(el)}
              />
            ))}
          </FormGroup>

          {selectedsQty > 0 && (
            <Fab
              color="secondary"
              variant="extended"
              aria-label="Save"
              style={{ margin: "15px auto", display: "flex" }}
              onClick={() => this.handleSaveSituation()}
            >
              <SaveIcon style={{ marginRight: "10px" }} />
              Confirmar
            </Fab>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  courseLevel: state.job.courseLevel,
  course: state.job.course
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourseSituation
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SelectEducationSituation))
);
