// @flow
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Persona from "../../components/Persona";
import { doUpdateProfile, forceReloadProfile } from "../../modules/profile";
import { doGetProfile } from "../../modules/user";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import HelpIcon from '@material-ui/icons/Help';

import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Fab from "@material-ui/core/Fab";
import SaveIcon from '@material-ui/icons/Save';

import {
  sendUpdatePreferences
} from "../../modules/job";

//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Preferences extends Component {
  constructor(props) {
    super(props);
    let doLoading = true;

    this.getProfile();

    this.state = {
      loading: doLoading,
      alertInfo: false,
      profileVisibility: this.props.profile.profileVisibility ? this.props.profile.profileVisibility : "automatic",
      isSnackbarOpen: false
    };
  }

  getProfile = () => {
    doGetProfile(this.props.token)
      .then(p => {

        this.props.doUpdateProfile(p);

        if (!p.initialized) {
          this.props.history.replace("/find-bot");
        }

        this.setState({ loading: false });

      })
      .catch(error => {
        console.error(error);
      });
  };

  onClose = () => {
    this.setState({
      alertInfo: false
    });
  };

  handleOpenInfoAlert = () => {
    this.setState({
      alertInfo: true
    });
  };

  sendPreferences = () => {
    this.setState({ isSnackbarOpen: true });

    sendUpdatePreferences(this.props.token, this.props.profile.phone, this.state.profileVisibility);
  }

  onChange = (event) => {
    this.setState({ profileVisibility: event.target.value });
  }

  render() {
    const { classes, profile } = this.props;
    const { alertInfo, isSnackbarOpen } = this.state;

    return (
      <div className={classes.root}>
        <Snackbar
          open={isSnackbarOpen}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          message="Perfil atualizado com sucesso!"
          onClose={() => this.setState({ isSnackbarOpen: false })}
          onClick={() => this.setState({ isSnackbarOpen: false })}
          autoHideDuration={2000}
        />
        <Dialog
          TransitionComponent={Transition}
          maxWidth={"md"}
          open={alertInfo}
          onClose={this.onClose}
          aria-labelledby="responsive-dialog-title"
        >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-title">
                Nessa opção, você poderá escolher a visibilidade do seu perfil.
              </DialogContentText>

              <DialogContentText id="alert-dialog-slide-description">
                Na JobSpot, você é encontrado por quem busca um perfil parecido com o seu. Os seus dados pessoais ficam protegidos
                e somente suas informações profissionais são reveladas por padrão.
              </DialogContentText>

              <DialogContentText id="alert-dialog-slide-description-2">
                Se o seu perfil for compatível com a vaga oferecida, você será convidado(a) para uma conversa, simplificando o processo.
                É você quem decide se quer responder a cada oportunidade oferecida. A sua identidade só será revelada se você aceitar participar
                da conversa. Este é o comportamento adotado pela JobSpot.
              </DialogContentText>

              <DialogContentText id="alert-dialog-slide-description-3">
                Alternativamente, você já poderá deixar seu perfil visível para todas as empresas. Isso poderá agilizar o processo tanto
                para a empresa quanto para você. Você não será perguntado se deseja liberar o seu perfil a cada convite. O Jobin liberará
                seu perfil automaticamente para a empresa interessada.
              </DialogContentText>
              
            </DialogContent>
        </Dialog>

        <Persona
          text={`${profile.name}, aqui você poderá alterar as preferências do seu perfil pessoal e profissional dentro da plataforma JobSpot.`}
        />
        <Grid container spacing={4}>
        <div id="profile_visibility_preference" style={{ marginLeft: "20px" }}>
          <h3>Convites de Empresas <HelpIcon fontSize="small" style={{ color: "#0062E4" }} onClick={ this.handleOpenInfoAlert } /></h3> 
          <br />
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Ao receber uma nova oportunidade de trabalho:</FormLabel>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="automatic"
                name="radio-buttons-group"
                onChange={ this.onChange }
              >
                <FormControlLabel value="automatic" control={<Radio />} onChange={ this.onChange2 } label="Desejo liberar automaticamente o acesso ao meu perfil" />
                <br />
                <FormControlLabel value="requiresConfirmation" control={<Radio />} onChange={ this.onChange2 } label="Desejo receber uma pergunta se libero o acesso ao meu perfil" />
              </RadioGroup>
            </FormControl>
        </div>
        </Grid>
        <br />
        <Fab
          variant="extended"
          elevation={0}
          size="medium"
          color="primary"
          aria-label="Edit"
          onClick={ this.sendPreferences }
        >
        <SaveIcon style={{ marginRight: 8 }} />
          Salvar
        </Fab>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  userInitialized: state.formData.user && state.formData.user.initialized,
  editing: state.profile.editing,
  qualificationOpen: state.profile.qualificationOpen,
  ocupationOpen: state.profile.ocupationOpen,
  specialNeedsOpen: state.profile.specialNeedsOpen,
  placesInterestOpen: state.profile.placesInterestOpen,
  contractingModalitiesOpen: state.profile.contractingModalitiesOpen,
  shiftPreferencesOpen: state.profile.shiftPreferencesOpen,
  skillsOpen: state.profile.skillsOpen,
  confirmOpen: state.profile.confirmOpen,
  confirmTitle: state.profile.confirmTitle,
  confirmText: state.profile.confirmText,
  confirmKey: state.profile.confirmKey,
  confirmObjId: state.profile.confirmObjId,
  profile: state.profile.profile,
  profileQualification: state.profile.profileQualification,
  profileOcupation: state.profile.profileOcupation,
  profileSpecialNeeds: state.profile.profileSpecialNeeds,
  profilePlacesInterest: state.profile.profilePlacesInterest,
  profileContractingModalities: state.profile.profileContractingModalities,
  profileShiftPreferences: state.profile.profileShiftPreferences,
  profileSkills: state.profile.profileSkills,
  loading: state.profile.loading
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Preferences))
);
