import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import loginRedirects from "../util/login-redirects";
import AuthLayout from "../_layouts/auth";
import DefaultLayout from "../_layouts/default";

function RouteWrapper({
  component: Component,
  initialRouter,
  isPrivate,
  displayFooter,
  token,
  userInitialized,
  path,
  authType,
  ...rest
}) {
  const { userType } = rest;

  if (!token && !initialRouter && isPrivate) {
    return (
      <Redirect
        to={`/${
          path && loginRedirects.includes(path) ? `?redirect=${path}` : ""
        }`}
      />
    );
  }

  if (token && initialRouter && isPrivate && !userInitialized) {
    return <Redirect to="/find-bot" />;
  }

  if (initialRouter && token) {
    if (userType === "COMPANY") {
      return <Redirect to="/jobs" />;
    }
    
    if (userType === "PERSON" && !userInitialized) {
      return <Redirect to="/find-bot" />;
    }

    if (userType === "PERSON" && userInitialized) {
      return <Redirect to="/profile" />;
    }
  }

  if (authType && userType && authType !== userType) return <Redirect to="/" />;

  const Layout = isPrivate ? DefaultLayout : AuthLayout;

  return (
    <Route
      {...rest}
      render={props => (
        <Layout displayFooter={displayFooter}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  initialRouter: PropTypes.bool,
  isPrivate: PropTypes.bool,
  displayFooter: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]).isRequired
};

RouteWrapper.defaultProps = {
  initialRouter: false,
  isPrivate: false,
  displayFooter: true
};

const mapStateToProps = state => ({
  token: state.login.token,
  cloudMessagingToken: state.login.cloudMessagingToken,
  userInitialized: state.formData.user && state.formData.user.initialized,
  subscribedToOneSignal: state.user.subscribedToOneSignal,
  userType: state.profile.profile ? state.profile.profile.userType : null,
  current_plan: state.profile.profile ? state.profile.profile.current_plan : null
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RouteWrapper)
);
