// @flow
import React from "react";
import { BeatLoader } from "react-spinners";

export default class UserOptionsWaiting extends React.Component {
  render() {
    return (
      <div
        className="d-flex flex-row-reverse"
        style={{
          backgroundColor: "#fff",
          width: "100vw"
        }}
      >
        <BeatLoader loading={true} size={4} />
      </div>
    );
  }
}
