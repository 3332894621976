export default theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    paddingTop: "70px"
  },
  rootFull: {
    flexGrow: 1,
    padding: "0px",
    "& h1": {
      padding: "20px"
    }
  },
  card: {
    marginTop: "13px",
    marginBottom: "13px"
  },
  cardHeader: {
    paddingBottom: "5px",
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#222",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    "& small": {
      color: theme.palette.background.active,
      fontSize: "12px",
      fontWeight: "normal"
    }
  },
  cardHeaderInactive: {
    paddingBottom: "5px",
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#222",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center"
    },
    "& small": {
      color: "red",
      fontSize: "12px",
      fontWeight: "normal"
    }
  },
  cardContent: {
    paddingTop: "5px"
  },
  cardContentInactive: {
    paddingTop: "5px",
    opacity: "0.35"
  },
  progressBar: {
    maxWidth: "180px",
    width: "40%"
  },
  itemList: {
    listStyle: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "8px 0",
    width: "100%"
  },
  itemListMobile: {
    listStyle: "none",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%"
  },
  itemListName: {
    flexGrow: 0.3,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0px 15px",
    "& p": {
      flexGrow: 1,
      paddingRight: "15px",
      marginBottom: "0px"
    }
  },
  buttonPagination: {
    backgroundColor: "#1976D2",
    color: "white",
    width: "25px", 
    height: "25px"
  },
  buttonPaginationDisabled: {
    backgroundColor: "#797979",
    color: "black",
    width: "25px", 
    height: "25px"
  },
  itemListNameMobile: {
    flexGrow: "1",
    display: "flex",
    marginBottom: "0px",
    paddingRight: "15px",
    position: "relative",
    left: "7px",
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    alignContent: "flex-end",
    justifyContent: "space-evenly",
    alignItems: "center"
  },
  match: {
    width: "100%",
    maxWidth: "280px",
    "& small": {
      fontSize: "11px",
      color: "#555"
    }
  },
  matchMobile: {
    width: "100%",
    maxWidth: "280px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "space-around",
    justifyContent: "space-around"
  },
  itemListIcon: {
    maxWidth: "35px"
  },
  badgePoints: {
    padding: "2px 5px",
    backgroundColor: theme.palette.background.topbar,
    color: "white",
    fontSize: "11px",
    borderRadius: "4px"
  }
});
