import styled from "styled-components";
import Button from "@material-ui/core/Button";

export default theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: "80px"
  },
  cardActive: {
    "& svg": {
      color: theme.palette.background.active
    }
  }
});

export const SubmitButton = styled(Button)`
  margin-top: 15px !important;
`;
