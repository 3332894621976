// @flow
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import storage from "redux-persist/lib/storage";
import localForage from "localforage";

import login from "./login";
import loading from "./welcome";
import createAccount from "./create-account";
import findBot from "./find-bot";
import formData from "./form-data";
import user from "./user";
import job from "./job";
import chat from "./chat";
import profile from "./profile";
import forgotPassword from "./forgot-password";
import resetPassword from "./reset-password";
import pendingInvites from "./pending-invites";

const appReducer = combineReducers({
  routing: routerReducer,
  login,
  loading,
  createAccount,
  findBot,
  formData,
  user,
  job,
  chat,
  profile,
  forgotPassword,
  resetPassword,
  pendingInvites
});

export default (state, action) => {
  if (action.type === "FACTORY_RESET") {
    Object.keys(state).forEach(key => {
      storage.removeItem(`persist:${key}`);
      localForage.removeItem(`persist:${key}`);
    });
    state = undefined;
  }

  return appReducer(state, action);
};
