export default theme => ({
  root: {},
  header: {
    backgroundColor: "#e7eaec",
    padding: "15px",
    position: "relative",
    minWidth: "280px"
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold"
  },
  searchIcon: {
    position: "absolute",
    left: 5,
    top: 15,
    color: "#777"
  },
  inputSearch: {
    margin: 0,
    backgroundColor: "transparent",
    border: 0,
    width: "100%",
    height: "50px",
    padding: "5px 15px",
    paddingLeft: "30px"
  },
  dialogContent: {
    margin: 0,
    padding: "20px"
  },
  formControl: {
    margin: "5px 15px",
    padding: "15px",
    border: "1px solid #dedede",
    borderRadius: "4px",
    "& legend": {
      paddingLeft: "5px"
    }
  },
  notFoundText: {
    color: "#888",
    fontSize: "12px"
  }
});
