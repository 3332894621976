// @flow
import CircularProgress from "@material-ui/core/CircularProgress";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Slide, Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import moment from "moment";
import "moment/locale/pt-br";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  doClearCreateAccountMessage,
  doCreateAccountStep2,
  doUpdateData
} from "../../modules/create-account";
import { doLoadStates } from "../../modules/form-data";
import i18n from "../../util/i18n";
import Loading from "../loading";
import {
  ContainerBox,
  ContainerJobSpot,
  LoadingContent,
  Logo,
  SubmitButton
} from "./styles";

moment.locale("pt-br");

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class CreateAccountStep2 extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      selectedDate: new Date(),
      vertical: "top",
      horizontal: "center",
      openConfirmationMethod: false,
      isHoveringWhatsApp: false,
      isHoveringEmail: false
    };
  }

  handleMouseEnterWhatsApp = () => {
    this.setState({ isHoveringWhatsApp: true });
  };

  handleMouseLeaveWhatsApp = () => {
    this.setState({ isHoveringWhatsApp: false });
  };

  handleMouseEnterEmail = () => {
    this.setState({ isHoveringEmail: true });
  };

  handleMouseLeaveEmail = () => {
    this.setState({ isHoveringEmail: false });
  };

  capitalizeEachFirstLetter(string) {
    let arr = string.split(" ");
    arr = arr.map(s => {
      return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
    });
    return arr.join(" ");
  }

  componentDidMount() {
    if (this.props.states.length === 0) {
      this.props.doLoadStates();
    }
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleSubmit = () => {
    if (this.formRef.current.reportValidity()) {
      this.handleChooseValidationForm();
    }
    //  this.props.doCreateAccountStep2();
  };

  // clica -> abre modal com pergunta: email ou telefone? -> clica numa opcao -> envia formulario

  handleChooseValidationForm = () => {
    this.setState({ openConfirmationMethod: true });
  }

  handleSendUserWhatsApp = () => {
    this.setState({ openConfirmationMethod: false });
    this.props.doCreateAccountStep2('whatsapp');
  }

  handleSendUserEmail = () => {
    this.setState({ openConfirmationMethod: false });
    this.props.doCreateAccountStep2('email');
  }

  render() {
    const {
      states,
      history,
      showMessage,
      message,
      firstNameLastName,
      fullAddress,
      neighborhood,
      socialNumber,
      email,
      phone,
      dateOfBirth,
      gender,
      state,
      city,
      isCreating,
      doUpdateData,
      doClearCreateAccountMessage
    } = this.props;
    const { vertical, horizontal, openConfirmationMethod } = this.state;

    return (
      <Fragment>
          <Dialog
            TransitionComponent={Transition}
            maxWidth={"xl"}
            onClose={() => { this.setState({ openConfirmationMethod: false }) }}
            open={openConfirmationMethod}
            aria-labelledby="responsive-dialog-title"
            style={{ display: "flex", flexDirection: "column"}}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Como você gostaria de validar sua conta?
              </DialogContentText>

              <Card sx={{ minWidth: 250 }}           
                style={{
                  cursor: 'pointer',
                  backgroundColor: this.state.isHoveringWhatsApp ? 'rgb(0,126,252)' : '',

                  color: this.state.isHoveringWhatsApp ? 'white' : '',
                }}
                onMouseEnter={this.handleMouseEnterWhatsApp}
                onMouseLeave={this.handleMouseLeaveWhatsApp}
                onClick={this.handleSendUserWhatsApp}
              >
                  <CardContent>
                    <WhatsAppIcon style={{ color: this.state.isHoveringWhatsApp ? 'white' : 'rgb(0,126,252)', position: "relative", bottom: "-5" }} /> Usando meu WhatsApp: <b>{phone}</b>
                  </CardContent>
              </Card>

              <br />

              <Card sx={{ minWidth: 250 }} 
                  style={{
                    cursor: 'pointer',
                    backgroundColor: this.state.isHoveringEmail ? 'rgb(0,126,252)' : '',
                    color: this.state.isHoveringEmail ? 'white' : '',
                  }}
                  onMouseEnter={this.handleMouseEnterEmail}
                  onMouseLeave={this.handleMouseLeaveEmail}
                  onClick={this.handleSendUserEmail}
                  >
                <CardContent>
                  <EmailIcon style={{ color: this.state.isHoveringEmail ? 'white' : 'rgb(0,126,252)', position: "relative", bottom: "-5" }} /> Usando meu email: <b>{email}</b>
                </CardContent>
              </Card>

              <br />
              
            </DialogContent>
          </Dialog>
        {states.length < 1 ? (
          <Loading />
        ) : (
          <Fragment>
            <ContainerJobSpot component="main" maxWidth="xs">
              <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                key={`${vertical},${horizontal}`}
                open={showMessage}
                ContentProps={{
                  "aria-describedby": "message-id"
                }}
                message={<span id="message-id">{message}</span>}
                autoHideDuration={6000}
                onClose={() => doClearCreateAccountMessage()}
              />

              <ContainerBox
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <IconButton
                  onClick={() => history.replace("/create-account")}
                  color="inherit"
                  aria-label="Menu"
                >
                  <ArrowBackIcon />
                </IconButton>
                <Logo>
                  <img src={require("../../images/logo.png")} alt="Logo" />
                </Logo>

                <form ref={this.formRef}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="firstNameLastName"
                        name="firstNameLastName"
                        label={i18n.t("firstNameLastName")}
                        value={firstNameLastName}
                        onChange={e => {
                          doUpdateData({
                            firstNameLastName: e.target.value
                          });
                        }}
                        autoFocus
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="email"
                        id="email"
                        label={i18n.t("email")}
                        value={email}
                        onChange={e => {
                          doUpdateData({
                            email: e.target.value
                          });
                        }}
                      />
                    </Grid>
                    
                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="socialNumber"
                        id="socialNumber"
                        label={i18n.t("socialNumber")}
                        value={socialNumber}
                        onChange={e => {
                          let txt = e.target.value
                            .replace(/[^0-9]/g, "")
                            .substr(0, 11);

                          let maskedTxt = txt.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                          );

                          doUpdateData({
                            socialNumber: maskedTxt
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="dateOfBirth"
                        id="dateOfBirth"
                        label={i18n.t("dateOfBirth")}
                        value={dateOfBirth}
                        onChange={e => {
                          let txt = e.target.value
                            .replace(/[^0-9]/g, "")
                            .substr(0, 8);

                          let day = txt.substr(0, 2);
                          let month = txt.substr(2, 2);
                          let year = txt.substr(4, 4);

                          let maskedTxt = day;
                          if (month) maskedTxt += "/" + month;
                          if (year) maskedTxt += "/" + year;

                          doUpdateData({
                            dateOfBirth: maskedTxt
                          });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel
                          htmlFor="gender"
                          style={{
                            backgroundColor: "white",
                            paddingHorizontal: 5
                          }}
                        >
                          {i18n.t("gender")}
                        </InputLabel>
                        <Select
                          value={gender}
                          inputProps={{
                            name: "gender",
                            id: "gender"
                          }}
                          onChange={e => {
                            doUpdateData({
                              gender: e.target.value
                            });
                          }}
                          input={<OutlinedInput name="gender" id="gender" />}
                        >
                          <MenuItem value="">
                            <em>{i18n.t("gender")}</em>
                          </MenuItem>
                          <MenuItem value={"_MAS"}>
                            {i18n.t("gender_masc")}
                          </MenuItem>
                          <MenuItem value={"_FEM"}>
                            {i18n.t("gender_fem")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel
                          htmlFor="state"
                          style={{
                            backgroundColor: "white",
                            paddingHorizontal: 5
                          }}
                        >
                          {i18n.t("state")}
                        </InputLabel>
                        <Select
                          value={state ? state.id : ""}
                          inputProps={{
                            name: "state",
                            id: "state"
                          }}
                          onChange={e => {
                            let st = states.find(m => {
                              return m.id === e.target.value;
                            });

                            doUpdateData({
                              state: st
                            });
                          }}
                          input={<OutlinedInput name="state" id="state" />}
                        >
                          <MenuItem value="">
                            <em>{i18n.t("state")}</em>
                          </MenuItem>
                          {states.map((e, key) => (
                            <MenuItem key={key} value={e.id}>
                              {e.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl variant="outlined" fullWidth required>
                        <InputLabel
                          htmlFor="city"
                          style={{
                            backgroundColor: "white",
                            paddingHorizontal: 5
                          }}
                        >
                          {i18n.t("city")}
                        </InputLabel>
                        <Select
                          value={city ? city.id : ""}
                          inputProps={{
                            name: "city",
                            id: "city"
                          }}
                          onChange={e => {
                            let c = state.cities.find(m => {
                              return m.id === e.target.value;
                            });

                            doUpdateData({
                              city: c
                            });
                          }}
                          input={<OutlinedInput name="city" id="city" />}
                        >
                          <MenuItem value="">
                            <em>{i18n.t("city")}</em>
                          </MenuItem>
                          {state &&
                            state.cities.map((e, key) => (
                              <MenuItem key={key} value={e.id}>
                                {e.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="fullAddress"
                            name="fullAddress"
                            label={i18n.t("fullAddress")}
                            value={fullAddress}
                            onChange={e => {
                              doUpdateData({
                                fullAddress: e.target.value
                              });
                            }}
                          />
                    </Grid>

                    <Grid item xs={12}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            id="neighborhood"
                            name="neighborhood"
                            label={i18n.t("neighborhood")}
                            value={neighborhood}
                            onChange={e => {
                              doUpdateData({
                                neighborhood: e.target.value
                              });
                            }}
                          />
                    </Grid>

                    <Grid item xs={12}>
                      {isCreating ? (
                        <LoadingContent>
                          <CircularProgress />
                        </LoadingContent>
                      ) : (
                        <SubmitButton
                          type="button"
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={isCreating}
                          onClick={this.handleSubmit}
                        >
                          {i18n.t("createAccount")}
                        </SubmitButton>
                      )}
                    </Grid>
                  </Grid>
                </form>
              </ContainerBox>
            </ContainerJobSpot>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  phone: state.createAccount.phone,
  phoneConfirmation: state.createAccount.phoneConfirmation,
  password: state.createAccount.password,
  passwordConfirmation: state.createAccount.passwordConfirmation,
  firstNameLastName: state.createAccount.firstNameLastName,
  socialNumber: state.createAccount.socialNumber,
  dateOfBirth: state.createAccount.dateOfBirth,
  gender: state.createAccount.gender,
  state: state.createAccount.state,
  city: state.createAccount.city,
  email: state.createAccount.email,
  isCreating: state.createAccount.isCreating,
  message: state.createAccount.message,
  showMessage: state.createAccount.showMessage,
  states: state.formData.states
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateAccountStep2,
      doUpdateData,
      doLoadStates,
      doClearCreateAccountMessage
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAccountStep2)
);
