// @flow
import React, { Component } from "react";

import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Slide,
  Switch,
  FormGroup,
  FormControlLabel,
  SnackbarContent,
  IconButton
} from "@material-ui/core";

// Material icons
import { Close as CloseIcon, Warning as WarningIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectSpecialNeed extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: true
    };
  }

  componentDidMount() {
    this.doLoadCard();
  }

  doLoadCard = () => {
    const { jobId, active } = this.props;
    if (!jobId && !active) {
      this.handleActiveCard();
    }
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleClose = () => {
    this.setState({ openDialog: false });
    this.props.actionCard();
  };

  handleActiveCard = () => {
    const { toggleCheckboxFn } = this.props;

    toggleCheckboxFn();

    // if (jobId && persistJobFn) persistJobFn();
  };

  handleActiveSubItems = item => {
    const {
      active,
      subOptionsSavedValues,
      subOptionsSelectGradeFn
    } = this.props;

    if (active && subOptionsSelectGradeFn) {
      const vl = subOptionsSavedValues[item.value] === 1 ? 0 : 1;
      subOptionsSelectGradeFn(item.value, vl);
    }

    //if (active && jobId && persistJobFn) {
    //  persistJobFn();
    //}
  };

  render() {
    const {
      classes,
      cardTitle,
      active,
      subOptions,
      subOptionsSavedValues
    } = this.props;
    const { openDialog } = this.state;

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar position="static">
          <Toolbar style={{ padding: "0px" }}>
            <IconButton color="inherit" onClick={() => this.handleClose()}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {cardTitle}
            </Typography>
            <Button color="inherit" onClick={() => this.handleClose()}>
              Confirmar
            </Button>
          </Toolbar>
        </AppBar>

        <DialogTitle id="responsive-dialog-title" className={classes.header}>
          <p>Ativar critério</p>
          <Switch
            color="secondary"
            checked={active}
            onChange={() => this.handleActiveCard()}
          />
        </DialogTitle>

        <p
          style={{
            fontSize: "12px",
            color: "red",
            padding: "5px 15px",
            margin: "0px"
          }}
        >
          Quando ativada, esta opção restringe a busca trazendo <u>SOMENTE</u>{" "}
          pessoas com necessidades especiais
        </p>
        {active ? (
          <DialogContent className={classes.dialogContent}>
            <FormGroup
              aria-label="Situacao"
              name="situacao1"
              className={classes.group}
            >
              {subOptions &&
                subOptions.map((item, k) => (
                  <FormControlLabel
                    key={k}
                    label={item.text}
                    control={
                      <Switch
                        color="secondary"
                        name={item.value}
                        value={"1"}
                        readOnly={!active}
                        checked={1 === subOptionsSavedValues[item.value]}
                        onChange={() => this.handleActiveSubItems(item)}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </DialogContent>
        ) : (
          <SnackbarContent
            aria-describedby="client-snackbar"
            style={{
              backgroundColor: "#55738c",
              margin: "20px auto",
              display: "flex"
            }}
            message={
              <span
                id="client-snackbar"
                style={{ display: "flex", alignItems: "center" }}
              >
                <WarningIcon style={{ marginRight: "10px" }} />
                Ative este critério para editar as opções!
              </span>
            }
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(SelectSpecialNeed);
