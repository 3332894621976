export default theme => ({
  root: {},
  content: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    listStyle: "none",
    "& span": {
      width: "9px",
      height: "9px",
      borderRadius: "3px",
      margin: "1px",
      fontSize: "1px",
      color: "transparent"
    }
  },
  inactive: {
    backgroundColor: "#f1f1f1"
  },
  first: {
    //backgroundColor: theme.palette.background.active
    backgroundColor: "#b2ebf2"
  },
  second: {
    backgroundColor: "#80cbc4"
  },
  third: {
    backgroundColor: "#ffd54f"
  },
  fourth: {
    backgroundColor: "#ffa726"
  },
  fifth: {
    backgroundColor: "#f44336"
  }
});
