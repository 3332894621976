// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";

import { doPersistJob } from "../../modules/job";

class JobName extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      jobName: ""
    };
  }
  componentDidMount() {}
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              onClick={this.props.history.goBack}
              color="inherit"
              aria-label="Menu"
            >
              <Icon className={classes.icon}>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Nome da vaga
            </Typography>
          </Toolbar>
        </AppBar>
        <Paper className={classes.paper} elevation={0}>
          <Typography component="p" className={classes.paperText}>
            Qual o nome desta vaga?
          </Typography>
        </Paper>
        <TextField
          fullWidth
          id="nome-da-vaga"
          label="Nome da vaga"
          className={classes.textField}
          margin="normal"
          onChange={evt => {
            this.setState({
              jobName: evt.target.value
            });
          }}
        />
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          onClick={() => this.props.doCreateJob(this.state.jobName)}
        >
          Confirmar Escolha
        </Button>
      </div>
    );
  }
}

const styles = theme => ({
  margin: {
    marginRight: theme.spacing.unit * 2
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  },
  root: {
    flexGrow: 1,
    height: "100vh",
    width: "100vw"
  },
  flex: {
    flex: 1
  },
  paper: {
    height: "100px",
    width: "100vw"
  },
  paperText: {
    textAlign: "center",
    paddingTop: "25px"
  },
  textField: {
    marginLeft: theme.spacing.unit * 4,
    marginRight: theme.spacing.unit * 4,
    width: "92%"
  },
  button: {
    margin: theme.spacing.unit,
    position: "absolute",
    bottom: 25,
    left: "50%",
    transform: "translateX(-50%)"
  }
});

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateJob: doPersistJob
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(JobName))
);
