// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Slide,
  Radio,
  RadioGroup,
  FormControlLabel
} from "@material-ui/core";

import { doConfirmExperiencePeriod } from "../../../../modules/profile";
import { doLoadExperiencePeriods } from "../../../../modules/form-data";

// Material icons
import { Save as SaveIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AddProfissaoSelectExperience extends Component {
  constructor(props) {
    super(props);

    this.experiencePeriods = [];
    this.state = {
      experiencePeriod: "",
      openDialog: true
    };
  }

  componentDidMount() {
    doLoadExperiencePeriods(this.props.token).then(data => {
      this.experiencePeriods = data;
      this.forceUpdate();
    });
  }

  handleChange = opt => {
    this.setState({
      experiencePeriod: opt.target.value
    });
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleSaveSituation = () => {
    const { doConfirmExperiencePeriod, NextStep } = this.props;
    const { experiencePeriod } = this.state;
    const fullSituation = this.experiencePeriods.filter(
      item => item.value === experiencePeriod
    );

    doConfirmExperiencePeriod(fullSituation[0]);
    //this.setState({ openDialog: false });
    NextStep();
  };

  render() {
    const { classes, profissao } = this.props;
    const { openDialog, experiencePeriod } = this.state;

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {profissao.text}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogTitle id="responsive-dialog-title" className={classes.header}>
          Selecione a experiência
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <RadioGroup
            aria-label="Situacao"
            name="situacao1"
            className={classes.group}
            value={experiencePeriod}
            onChange={this.handleChange}
          >
            {this.experiencePeriods.map((el, k) => (
              <FormControlLabel
                key={k}
                value={el.value}
                control={<Radio />}
                label={el.text}
              />
            ))}
          </RadioGroup>

          {experiencePeriod.length > 0 && (
            <Fab
              color="secondary"
              variant="extended"
              aria-label="Save"
              style={{ margin: "15px auto", display: "flex" }}
              onClick={() => this.handleSaveSituation()}
            >
              <SaveIcon style={{ marginRight: "10px" }} />
              Confirmar
            </Fab>
          )}
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  profissao: state.profile.profissao
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmExperiencePeriod
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(AddProfissaoSelectExperience))
);
