// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import Loading from "../loading";

import {
  doLoadCoursesByType,
  doLoadAcademicDegrees
} from "../../modules/form-data";
import { doConfirmCourse } from "../../modules/profile";

class ProfileAddQualificacaoSelectCourse extends React.Component {
  constructor(props) {
    super(props);

    this.academicDegrees = [];

    this.state = {
      courses: [],
      courseName: null,
      searching: false
    };
  }
  componentDidMount() {
    doLoadAcademicDegrees(this.props.token).then(data => {
      this.academicDegrees = data;
    });
  }
  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={() => {
                this.props.history.replace("/profile-add-qualificacao");
              }}
            >
              <Icon className={classes.icon}>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {this.props.courseLevel.text}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className={classes.listContainer}>
          <TextField
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon className={classes.icon}>search</Icon>
                </InputAdornment>
              )
            }}
            onChange={evt => {
              if (evt.target.value.length < 3) return;

              this.setState({
                searching: true,
                courseName: evt.target.value
              });

              doLoadCoursesByType(
                this.props.token,
                this.props.courseLevel.value,
                evt.target.value
              ).then(data => {
                this.setState({
                  searching: false,
                  courses: data
                });
              });
            }}
            margin="normal"
            fullWidth
          />
          {this.state.searching && <Loading />}
          {!this.state.searching && (
            <div className={classes.table}>
              <Table>
                <TableBody>
                  {this.state.courses.map((el, k) => (
                    <TableRow key={k} className={classes.tableRow}>
                      <TableCell
                        className={classes.tableCellLabel}
                        onClick={() => {
                          this.props.doConfirmCourse(el);
                        }}
                      >
                        {this.capitalizeFirstLetter(el.text)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  listContainer: {
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit
  },
  table: {
    maxHeight: "calc(100vh - 112px)",
    height: "calc(100vh - 112px)",
    overflowY: "auto"
  }
});

const mapStateToProps = state => ({
  token: state.login.token,
  courseLevel: state.profile.courseLevel
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourse
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(ProfileAddQualificacaoSelectCourse))
);
