// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import JobCard from "./job-card";

import { doLoadImportanceRange } from "../../modules/form-data";
import {
  doSelectQualificationLevelGrade,
  doPersistJob
} from "../../modules/job";

class JobSpecialNeedsChoices extends React.Component {
  constructor(props) {
    super(props);

    this.importanceRange = [];
    this.coursesByLevel = [];
  }
  componentDidMount() {
    this.coursesByLevel = this.props.coursesConfirmed
      .map(l => l.courseLevel)
      .filter(
        (value, index, self) => self.map(l => l.id).indexOf(value.id) === index
      );

    doLoadImportanceRange(this.props.token).then(data => {
      this.importanceRange = data;
      this.forceUpdate();
    });
  }
  render() {
    const { classes } = this.props;

    return (
      <div style={{ backgroundColor: "#EEEEEE", height: "100vh" }}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
              onClick={this.props.history.goBack}
            >
              <Icon className={classes.icon}>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              Qualificação
            </Typography>
            <Button
              color="inherit"
              onClick={() => {
                this.props.history.replace("/job-qualificacao-add-choice");
              }}
            >
              Adicionar
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.mainContainer}>
          {this.coursesByLevel.length === 0 && (
            <Paper className={classes.titleRootContainer} elevation={1}>
              <Typography align="center" component="p">
                Nenhuma opção selecionada
              </Typography>
            </Paper>
          )}
          {this.coursesByLevel.map((el, k) => (
            <JobCard
              cardTitle={el.text}
              grade={this.props.qualificationsLevelsGrades[el.value]}
              selectGradeFn={value => {
                this.props.doSelectQualificationLevelGrade(el.value, value);
              }}
              checkboxHidden={true}
              key={k}
              active={true}
              choicesComponentUrl={
                "/job-qualificacao-level-choices/" + el.value
              }
              importanceRange={this.importanceRange}
              jobId={this.props.jobId}
              persistJobFn={this.props.doPersistJob}
              history={this.props.history}
            />
          ))}
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1
  },
  flex: {
    flex: 1
  },
  mainContainer: {
    height: "calc(100vh - 60px)",
    overflowY: "scroll"
  },
  titleRootContainer: {
    ...theme.mixins.gutters(),
    margin: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  button: {
    margin: theme.spacing.unit + 15,
    position: "fixed",
    bottom: 0,
    right: 0,
    zIndex: 10
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  }
});

const mapStateToProps = state => ({
  coursesConfirmed: state.job.coursesConfirmed,
  qualificationsLevelsGrades: state.job.qualificationsLevelsGrades,
  jobId: state.job.jobId,
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSelectQualificationLevelGrade,
      doPersistJob
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(JobSpecialNeedsChoices))
);
