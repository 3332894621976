// @flow
import React from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem
} from "reactstrap";
import i18n from "../util/i18n";

export default class Menu extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.close = this.close.bind(this);
    this.state = {
      isOpen: false
    };
    this.options = props.options;
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  close() {
    this.setState({
      isOpen: false
    });
  }
  render() {
    return (
      <Navbar color="light" light expand="md" fixed="bottom">
        <NavbarBrand href="/">Find</NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {this.options.map((option, k) => (
              <NavItem key={k}>
                <Link
                  className="nav-link"
                  to={option.path}
                  onClick={this.close}
                >
                  {i18n.t(option.key)}
                </Link>
              </NavItem>
            ))}
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}
