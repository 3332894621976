import { Typography } from "@material-ui/core";
import { CheckCircleOutlineRounded as CheckCircleOutlineRoundedIcon } from "@material-ui/icons";
import React, { Component, Fragment } from "react";
import { ContainerBox, ContainerJobSpot, Logo, SubmitButton } from "./styles";

class VerifyAccount extends Component {
  state = {
    loading: false,
    success: false
  };

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    this.handleVerifyAccount(token);
  }

  handleVerifyAccount = async token => {
    this.setState({ loading: true });
    try {
      const result = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/user/verify?token=${token}`,
        {
          method: "PATCH",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );
      if (result.ok) this.setState({ success: true, loading: false });
      else this.setState({ success: false, loading: false });
    } catch (e) {
      this.setState({ success: false, loading: false });
    }
  };

  render() {
    const { loading, success } = this.state;

    return (
      <ContainerJobSpot component="main" maxWidth="xs">
        <ContainerBox style={{ textAlign: "center" }}>
          <Logo>
            <img src={require("../../images/logo.png")} alt="JobSpot Logo" />
          </Logo>
          {loading && <Typography>Ativando sua conta...</Typography>}
          {!loading && success && (
            <Fragment>
              <CheckCircleOutlineRoundedIcon
                style={{
                  color: "#5ec749",
                  width: "52px",
                  height: "52px",
                  margin: "5px auto",
                  display: "block"
                }}
              />
              <Typography>Conta ativada com sucesso!</Typography>
              <SubmitButton onClick={() => this.props.history.replace("/")}>
                Acessar agora
              </SubmitButton>
            </Fragment>
          )}
          {!loading && !success && (
            <Typography>Ops... essa conta já foi ativada!</Typography>
          )}
        </ContainerBox>
      </ContainerJobSpot>
    );
  }
}

export default VerifyAccount;
