// @flow
import {
  LOAD_DATA_STATE,
  LOAD_DATA_PLACE_INTEREST,
  LOAD_DATA_CONTRACT_MODALITY,
  LOAD_DATA_SKILL,
  LOAD_DATA_SPECIAL_NEED,
  LOAD_DATA_SHIFT_PREFERENCE,
  LOAD_DATA_EDUCATION_LEVEL,
  LOAD_DATA_EDUCATION_SITUATION,
  LOAD_DATA_ACADEMIC_DEGREE,
  LOAD_DATA_COURSE,
  LOAD_DATA_USER,
  LOAD_DATA_OCUPATION,
  LOAD_DATA_SEGMENT,
  LOAD_DATA_EXPERIENCE_PERIOD
} from "./form-data";

export const LOADING_STARTED = "loading/LOADING_STARTED";
export const LOADING_FINISHED = "loading/LOADING_FINISHED";
export const LOADING_ERROR = "loading/LOADING_ERROR";

const initialState = {
  isLoading: false,
  loaded: false,
  user: null,
  token: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_STARTED:
      return {
        ...state,
        isLoading: true,
        loaded: false
      };

    case LOADING_FINISHED:
      return {
        ...state,
        isLoading: false,
        loaded: true
      };

    case LOADING_ERROR:
      return {
        ...state,
        isLoading: false,
        loaded: false
      };

    default:
      return state;
  }
};

export const doLoadFormData = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const token = getState().login.token;

      dispatch({
        type: LOADING_STARTED
      });

      let url = process.env.REACT_APP_SERVER_URL + "/form-data/";
      let processesFinished = 0;
      let processes = [
        {
          url: "states",
          actionType: LOAD_DATA_STATE
        },
        {
          url: "places-interests",
          actionType: LOAD_DATA_PLACE_INTEREST
        },
        {
          url: "contracting-modalities",
          actionType: LOAD_DATA_CONTRACT_MODALITY
        },
        {
          url: "skills",
          actionType: LOAD_DATA_SKILL
        },
        {
          url: "special-needs",
          actionType: LOAD_DATA_SPECIAL_NEED
        },
        {
          url: "shift-preferences",
          actionType: LOAD_DATA_SHIFT_PREFERENCE
        },
        {
          url: "education-levels",
          actionType: LOAD_DATA_EDUCATION_LEVEL
        },
        {
          url: "academic-degrees",
          actionType: LOAD_DATA_ACADEMIC_DEGREE
        },
        {
          url: "ocupations",
          actionType: LOAD_DATA_OCUPATION
        },
        {
          url: "segments",
          actionType: LOAD_DATA_SEGMENT
        },
        {
          url: "experience-periods",
          actionType: LOAD_DATA_EXPERIENCE_PERIOD
        },
        {
          url: "education-situations",
          actionType: LOAD_DATA_EDUCATION_SITUATION
        },
        {
          url: "user",
          actionType: LOAD_DATA_USER
        },
        {
          url: "courses",
          actionType: LOAD_DATA_COURSE,
          callbackFn: function(result) {
            dispatch({
              type: LOAD_DATA_COURSE,
              coursesGraduation: result.coursesGraduation,
              coursesMaster: result.coursesMaster,
              coursesProfessionalMaster: result.coursesProfessionalMaster,
              coursesDoctorate: result.coursesDoctorate,
              coursesOther: result.coursesOther,
              coursesOtherSpecialization: result.coursesOtherSpecialization,
              coursesTechnicalProfessional: result.coursesTechnicalProfessional
            });
          }
        }
      ];

      processes.forEach(element => {
        fetch(url + element.url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: token
          }
        })
          .then(function(result) {
            if (result.ok) {
              result.json().then(e => {
                if (element.callbackFn) {
                  element.callbackFn(e);
                } else {
                  dispatch({
                    type: element.actionType,
                    result: e
                  });
                }

                processesFinished++;
                if (processesFinished >= processes.length) {
                  dispatch({
                    type: LOADING_FINISHED
                  });

                  resolve();
                }
              });
            } else {
              result.text().then(e => {
                reject(e);
              });
            }
          })
          .catch(function(error) {
            reject(error);
          });
      });
    });
  };
};
