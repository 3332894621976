// @flow
import React from "react";
import { Container, Row, Col } from "reactstrap";
import CircularProgress from '@mui/material/CircularProgress';

import i18n from "../../util/i18n";

export default class Welcome extends React.Component {
  render() {
    return (
      <Container style={{ marginTop: 100 }}>
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{ padding: 25 }}
          >
            {i18n.t("loading")}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <CircularProgress />
          </Col>
        </Row>
      </Container>
    );
  }
}
