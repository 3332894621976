// @flow
export const ACCEPT_INVITE_CHAT_REQUEST =
  "invitechat/ACCEPT_INVITE_CHAT_REQUEST";
export const ACCEPT_INVITE_CHAT_SUCCESS =
  "invitechat/ACCEPT_INVITE_CHAT_SUCCESS";
export const ACCEPT_INVITE_CHAT_FAILED = "invitechat/ACCEPT_INVITE_CHAT_FAILED";

const initialState = {
  loading_accept: false,
  loading_accept_fail: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACCEPT_INVITE_CHAT_REQUEST:
      return {
        ...state,
        loading_accept: true
      };
    case ACCEPT_INVITE_CHAT_SUCCESS:
      return {
        ...state,
        loading_accept: false
      };
    case ACCEPT_INVITE_CHAT_FAILED:
      return {
        ...state,
        loading_accept: false,
        loading_accept_fail: true
      };

    default:
      return state;
  }
};

export const doAcceptInvitation = (token, jobChatId) => {
  return (dispatch, getState) => {
    dispatch({
      type: ACCEPT_INVITE_CHAT_REQUEST
    });

    const url = `${process.env.REACT_APP_SERVER_URL}/job/accept-chat-invitation/${jobChatId}`;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          dispatch({
            type: ACCEPT_INVITE_CHAT_SUCCESS
          });
        } else {
          dispatch({
            type: ACCEPT_INVITE_CHAT_FAILED
          });
        }
      })
      .catch(function() {
        dispatch({
          type: ACCEPT_INVITE_CHAT_FAILED
        });
      });
  };
};

export const acceptChatInvitation = async (token, jobChatId) => {
  try {
    let url = `${process.env.REACT_APP_SERVER_URL}/job/accept-chat-invitation/${jobChatId}`;
    const result = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    });
    if (result.ok) {
      const json = await result.json();
      return json;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
};

export const getChatMessages = (token, jobUserId) => {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/chat/${jobUserId}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};
