// @flow
import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

class Reset extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: "FACTORY_RESET"
    });
  }
  render() {
    return <Redirect to="/forgot-password" />;
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatch
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Reset)
);
