import Snackbar from "@material-ui/core/Snackbar";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  doClearForgotPasswordMessage,
  doForgotPassword,
  doUpdateData
} from "../../modules/forgot-password";
import {
  ContainerBox,
  ContainerJobSpot,
  Logo
} from "./styles";

class DataAccessaAnswer extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    vertical: "top",
    horizontal: "center"
  };

  componentDidMount() {
    this.handleAnswerDataAccess();
  }

  handleAnswerDataAccess = async () => {
    const [ ,answerToken] = this.props.location.search.split("=");
    const url = `${process.env.REACT_APP_SERVER_URL}/job/answer/${answerToken}`;

    await fetch(url, { method: 'GET', headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }})
  }

  render() {
    const {
      message,
      showMessage,
    } = this.props;
    const { vertical, horizontal } = this.state;

    return (
      <ContainerJobSpot component="main" maxWidth="xs">
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={showMessage}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{message}</span>}
          autoHideDuration={6000}
          onClose={() => this.props.doClearForgotPasswordMessage()}
        />

        <ContainerBox>
          <Logo>
            <img src={require("../../images/logo.png")} alt="Logo JobSpot" />
          </Logo>
          <Typography style={{ textAlign: "center" }}>
            Enviamos sua resposta ao contratante! Obrigado!
          </Typography>
        </ContainerBox>
      </ContainerJobSpot>
    );
  }
}

const mapStateToProps = state => ({
  email: state.forgotPassword.email,
  message: state.forgotPassword.message,
  showMessage: state.forgotPassword.showMessage,
  isProcessing: state.forgotPassword.isProcessing
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateData,
      doClearForgotPasswordMessage,
      doForgotPassword
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DataAccessaAnswer)
);
