import styled from "styled-components";
import {
  jobspotPrimary,
  jobspotSecondary
} from "../../../../theme/common/colors";

export const OptionRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 10px -5px;
  margin-bottom: 0;
`;

export const OptionButton = styled.button`
  background-color: ${jobspotPrimary};
  border-radius: 4px;
  border: 0;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 4px 0px;
  margin: 0px 5px 10px;
  padding: 10px 15px;
  color: white;
  text-align: center !important;
  flex-grow: 1;
  svg {
    margin-left: 5px;
    margin-top: -2px;
    width: 24px;
    height: 24px;
  }
  &:hover {
    background-color: ${jobspotSecondary};
  }
`;
