export default theme => ({
  root: {},
  header: {
    backgroundColor: "#e7eaec",
    padding: "15px",
    position: "relative",
    minWidth: "280px",
    "& h6": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%"
    }
  },
  title: {
    flexGrow: 1,
    fontWeight: "bold"
  },
  searchIcon: {
    position: "absolute",
    left: 5,
    top: 15,
    color: "#777"
  },
  inputSearch: {
    margin: 0,
    backgroundColor: "transparent",
    border: 0,
    width: "100%",
    height: "50px",
    padding: "5px 15px",
    paddingLeft: "30px"
  },
  dialogContent: {
    margin: 0,
    padding: "20px",
    position: "relative"
  },
  formControl: {
    margin: "5px 15px",
    padding: "15px",
    border: "1px solid #dedede",
    borderRadius: "4px",
    "& legend": {
      paddingLeft: "5px"
    }
  },
  notFoundText: {
    color: "#888",
    fontSize: "12px"
  },
  levelCard: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "space-around"
  },
  levelCardOptions: {
    padding: "5px 0px",
    display: "flex",
    flexDirection: "column",
    "& label": {
      fontSize: "10px",
      textAlign: "center",
      lineHeight: "11px",
      marginNottom: 0
    }
  },
  radioGroup: {
    flexWrap: "nowrap"
  },
  label: {
    margin: "5px",
    "& span": {
      fontSize: "10px",
      lineHeight: ".8rem"
    }
  }
});
