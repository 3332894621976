// @flow
import chatFullConversation from "../util/chat-full-conversation";

import { getCacheData, putCacheData } from "../util/cache";

export const LOAD_DATA = "form-data/LOAD_DATA";
export const LOAD_DATA_STATE = "form-data/LOAD_DATA_STATE";
export const LOAD_DATA_PLACE_INTEREST = "form-data/LOAD_DATA_PLACE_INTEREST";
export const LOAD_DATA_IMPORTANCE_RANGE =
  "form-data/LOAD_DATA_IMPORTANCE_RANGE";
export const LOAD_DATA_CONTRACT_MODALITY =
  "form-data/LOAD_DATA_CONTRACT_MODALITY";
export const LOAD_DATA_SKILL = "form-data/LOAD_DATA_SKILL";
export const LOAD_DATA_SPECIAL_NEED = "form-data/LOAD_DATA_SPECIAL_NEED";
export const LOAD_DATA_SHIFT_PREFERENCE =
  "form-data/LOAD_DATA_SHIFT_PREFERENCE";
export const LOAD_DATA_EDUCATION_LEVEL = "form-data/LOAD_DATA_EDUCATION_LEVEL";
export const LOAD_DATA_ACADEMIC_DEGREE = "form-data/LOAD_DATA_ACADEMIC_DEGREE";
export const LOAD_DATA_EDUCATION_SITUATION =
  "form-data/LOAD_DATA_EDUCATION_SITUATION";
export const LOAD_DATA_COURSE = "form-data/LOAD_DATA_COURSE";
export const LOAD_DATA_OCUPATION = "form-data/LOAD_DATA_OCUPATION";
export const LOAD_DATA_EXPERIENCE_PERIOD =
  "form-data/LOAD_DATA_EXPERIENCE_PERIOD";
export const LOAD_DATA_SEGMENT = "form-data/LOAD_DATA_SEGMENT";
export const LOAD_DATA_USER = "form-data/LOAD_DATA_USER";
export const LOAD_DATA_CONVERSATION = "form-data/LOAD_DATA_CONVERSATION";
export const INITIALIZE_USER_JSON = "form-data/INITIALIZE_USER_JSON";

const initialState = {
  states: [],
  placesInterests: [],
  importanceRanges: [],
  contractingModalities: [],
  shiftPreferences: [],
  skills: [],
  specialNeeds: [],
  educationLevels: [],
  academicDegrees: [],
  educationSituations: [],
  ocupations: [],
  experiencePeriods: [],
  segments: [],
  coursesGraduation: [],
  coursesMaster: [],
  coursesProfessionalMaster: [],
  coursesDoctorate: [],
  coursesOther: [],
  coursesOtherSpecialization: [],
  coursesTechnicalProfessional: [],
  user: null,
  fullConversation: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INITIALIZE_USER_JSON:
      return {
        ...state,
        user: { ...state.user, initialized: true }
      };

    case LOAD_DATA_STATE:
      return {
        ...state,
        states: action.result
      };

    case LOAD_DATA_PLACE_INTEREST:
      return {
        ...state,
        placesInterests: action.result
      };

    case LOAD_DATA_IMPORTANCE_RANGE:
      return {
        ...state,
        importanceRanges: action.result
      };

    case LOAD_DATA_CONTRACT_MODALITY:
      return {
        ...state,
        contractingModalities: action.result
      };

    case LOAD_DATA_SKILL:
      return {
        ...state,
        skills: action.result
      };

    case LOAD_DATA_SPECIAL_NEED:
      return {
        ...state,
        specialNeeds: action.result
      };

    case LOAD_DATA_SHIFT_PREFERENCE:
      return {
        ...state,
        shiftPreferences: action.result
      };

    case LOAD_DATA_EDUCATION_LEVEL:
      return {
        ...state,
        educationLevels: action.result
      };

    case LOAD_DATA_ACADEMIC_DEGREE:
      return {
        ...state,
        academicDegrees: action.result
      };

    case LOAD_DATA_OCUPATION:
      return {
        ...state,
        ocupations: action.result
      };

    case LOAD_DATA_EXPERIENCE_PERIOD:
      return {
        ...state,
        experiencePeriods: action.result
      };

    case LOAD_DATA_SEGMENT:
      return {
        ...state,
        segments: action.result
      };

    case LOAD_DATA_EDUCATION_SITUATION:
      return {
        ...state,
        educationSituations: action.result
      };

    case LOAD_DATA_COURSE:
      return {
        ...state,
        coursesGraduation: action.coursesGraduation,
        coursesMaster: action.coursesMaster,
        coursesProfessionalMaster: action.coursesProfessionalMaster,
        coursesDoctorate: action.coursesDoctorate,
        coursesOther: action.coursesOther,
        coursesOtherSpecialization: action.coursesOtherSpecialization,
        coursesTechnicalProfessional: action.coursesTechnicalProfessional
      };

    case LOAD_DATA_USER:
      return {
        ...state,
        user: action.user
      };

    case LOAD_DATA_CONVERSATION:
      return {
        ...state,
        fullConversation: chatFullConversation(action.data)
      };

    case LOAD_DATA:
      return {
        ...state,
        placesInterests: action.placesInterests,
        contractingModalities: action.contractingModalities,
        shiftPreferences: action.shiftPreferences,
        specialNeeds: action.specialNeeds,
        skills: action.skills,
        states: action.states,
        educationLevels: action.educationLevels,
        academicDegrees: action.academicDegrees,
        ocupations: action.ocupations,
        experiencePeriods: action.experiencePeriods,
        segments: action.segments,
        educationSituations: action.educationSituations,
        coursesGraduation: action.coursesGraduation,
        coursesMaster: action.coursesMaster,
        coursesProfessionalMaster: action.coursesProfessionalMaster,
        coursesDoctorate: action.coursesDoctorate,
        coursesOther: action.coursesOther,
        coursesOtherSpecialization: action.coursesOtherSpecialization,
        coursesTechnicalProfessional: action.coursesTechnicalProfessional,
        user: action.user,
        fullConversation: chatFullConversation(action)
      };

    default:
      return state;
  }
};

export const doLoadOcupations = (token, firstChars) => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/ocupations/" + firstChars;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            if (e.length === 0) {
              e.push({
                text: firstChars
              });
            }
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doLoadCoursesByType = (token, type, firstChars) => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL +
      "/form-data/courses/" +
      type +
      "/" +
      firstChars;

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            if (e.length === 0) {
              e.push({
                text: firstChars
              });
            }
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doLoadEducationSituations = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/education-situations";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadExperiencePeriods = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/experience-periods";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadSkills = token => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_SERVER_URL + "/form-data/skills";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadSpecialNeeds = token => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_SERVER_URL + "/form-data/special-needs";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadShiftPreferences = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/shift-preferences";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadContractingModalities = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/contracting-modalities";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadSegments = token => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_SERVER_URL + "/form-data/segments";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadGenders = token => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_SERVER_URL + "/form-data/genders";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadAgeRanges = token => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_SERVER_URL + "/form-data/age-ranges";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadAcademicDegrees = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/academic-degrees";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadAndKeepEducationLevels = token => {
  return dispatch => {
    doLoadEducationLevels(token).then(result => {
      dispatch({
        type: LOAD_DATA_EDUCATION_LEVEL,
        result: result
      });
    });
  };
};

export const doLoadEducationLevels = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/education-levels";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadImportanceRange = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/importance-range";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadPlacesInterests = token => {
  return new Promise((resolve, reject) => {
    const url =
      process.env.REACT_APP_SERVER_URL + "/form-data/places-interests";

    let cacheData = getCacheData(url);
    if (cacheData) {
      resolve(cacheData);
    } else {
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token
        }
      })
        .then(function(result) {
          if (result.ok) {
            result.json().then(e => {
              putCacheData(url, e);
              resolve(e);
            });
          } else {
            result.text().then(e => {
              reject(e);
            });
          }
        })
        .catch(function(error) {
          reject(error);
        });
    }
  });
};

export const doLoadUser = token => {
  return new Promise((resolve, reject) => {
    const url = process.env.REACT_APP_SERVER_URL + "/form-data/user";

    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doLoadStates = msg => {
  return async dispatch => {
    const url = process.env.REACT_APP_SERVER_URL + "/form-data/states";
    const cacheData = getCacheData(url);
    if (cacheData) {
      dispatch({
        type: LOAD_DATA_STATE,
        result: cacheData
      });
    } else {
      const allStates = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });

      if (allStates.ok) {
        const allStatesJson = await allStates.json();
        putCacheData(url, allStatesJson);
        dispatch({
          type: LOAD_DATA_STATE,
          result: allStatesJson
        });
      }
    }
  };
};
