import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  doClearLoginMessage,
  doLogin,
  doLoginReset,
  doUpdateData
} from "../../modules/login";
import i18n from "../../util/i18n";
import {
  ContainerBox,
  ContainerJobSpot,
  LoadingContent,
  Logo,
  SubmitButton
} from "./styles";

import { Slide, Dialog } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

import Persona from "../../components/Persona";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";

import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Jasypt = require('jasypt');
const jasypt = new Jasypt();

class Login extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();

    const search = window.location.search;
    const params = new URLSearchParams(search);

    this.redirect = params.get("redirect");
    this.autoLoginToken = params.get("auto_login");

    this.phone = undefined;
    this.password = undefined;
  }

  state = {
    vertical: "top",
    horizontal: "center",
    openConfirmationMethod: false,
    openMessageToPerson: false,
    isHoveringWhatsApp: false,
    isHoveringEmail: false,
    showPassword: false
  };

  handleEnterKeyPressOnForm = e => {
    if (e.keyCode === 13) {
      this.handleSubmit();
    }
  };

  handleClose = () => {
    this.props.showMessage = false;
  };

  handleSubmit = () => {
    if (!this.formRef.current) {
      this.props.doLoginReset();
      return;
    }

    if (this.formRef.current.reportValidity())
      this.props.doLogin(this.redirect);
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleAutoSubmit = () => {
    jasypt.setPassword('!g3n-on3-t1m3!');
    try {
    const decryptMsg = jasypt.decrypt(this.autoLoginToken);

    const splittedMessage = decryptMsg.split(";");
    this.phone = splittedMessage[0];
    this.password = splittedMessage[1];
    
    this.props.doLogin(this.redirect, splittedMessage[0], splittedMessage[1]);
    } catch(error) {
      console.log("Something happened! ", error);
    }

  };

  handleClickCompany = () => {
    this.setState({ openConfirmationMethod: false });

  }

  handleChooseValidationForm = () => {
    this.setState({ openConfirmationMethod: true });
  }

  handleMouseEnterWhatsApp = () => {
    this.setState({ isHoveringWhatsApp: true });
  };

  handleMouseLeaveWhatsApp = () => {
    this.setState({ isHoveringWhatsApp: false });
  };

  handleMouseEnterEmail = () => {
    this.setState({ isHoveringEmail: true });
  };

  handleMouseLeaveEmail = () => {
    this.setState({ isHoveringEmail: false });
  };

  handleSendUserWhatsApp = () => {
    this.setState({ openConfirmationMethod: false });
    this.setState({ openMessageToPerson: true});
  }

  handleSendUserToLogin = () => {
    window.location.href = "https://wa.me/555140422006?text=Quero%20acessar%20meu%20perfil";
  }

  componentDidMount () {
    if (process.env.REACT_APP_ENV !== "DEV" &&
    process.env.REACT_APP_ENV !== "STAGING") {
      const ReactPixel = require('react-facebook-pixel');
      ReactPixel.default.init('2953446771628891');
      ReactPixel.default.track('PageView');
    }

    if (this.autoLoginToken !== null && this.autoLoginToken !== undefined) {
      this.handleAutoSubmit();
    }

  }

  render() {
    if (this.props.isLoggingIn) {
      this.handleSubmit();
    }

    const {
      message,
      showMessage,
      isLoggingIn,
      password,
      doClearLoginMessage
    } = this.props;
    const { vertical, horizontal, openConfirmationMethod, openMessageToPerson } = this.state;

    return (
      <ContainerJobSpot component="main" maxWidth="xs">

          <Dialog
            TransitionComponent={Transition}
            maxWidth={"xl"}
            onClose={() => { this.setState({ openConfirmationMethod: false }) }}
            open={openConfirmationMethod}
            aria-labelledby="responsive-dialog-title"
            style={{ display: "flex", flexDirection: "column"}}
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Está procurando emprego? Ou você gostaria de contratar alguém?
              </DialogContentText>

              <Card sx={{ minWidth: 250 }}           
                style={{
                  cursor: 'pointer',
                  backgroundColor: this.state.isHoveringWhatsApp ? 'rgb(0,126,252)' : '',

                  color: this.state.isHoveringWhatsApp ? 'white' : '',
                }}
                onMouseEnter={this.handleMouseEnterWhatsApp}
                onMouseLeave={this.handleMouseLeaveWhatsApp}
                onClick={this.handleSendUserWhatsApp}
              >
                  <CardContent>
                    <PersonIcon style={{ color: this.state.isHoveringWhatsApp ? 'white' : 'rgb(0,126,252)', position: "relative", bottom: "-5" }} /> Procuro emprego! (Sou pessoa física)
                  </CardContent>
              </Card>

              <br />
              <Link to="/create-account" variant="subtitle1" style={{ textDecoration: "none" }}>
                <Card sx={{ minWidth: 250 }} 
                    style={{
                      cursor: 'pointer',
                      backgroundColor: this.state.isHoveringEmail ? 'rgb(0,126,252)' : '',
                      color: this.state.isHoveringEmail ? 'white' : '',
                    }}
                    onMouseEnter={this.handleMouseEnterEmail}
                    onMouseLeave={this.handleMouseLeaveEmail}
                    onClick={this.handleSendUserEmail}
                    >
                  <CardContent>
                    <BusinessIcon style={{ color: this.state.isHoveringEmail ? 'white' : 'rgb(0,126,252)', position: "relative", bottom: "-5" }} /> Quero contratar! (Sou pessoa jurídica)
                  </CardContent>
                </Card>
              </Link>
              <br />
              
            </DialogContent>
          </Dialog>

          <Dialog
            TransitionComponent={Transition}
            maxWidth={"xl"}
            onClose={() => { this.setState({ openMessageToPerson: false }) }}
            open={openMessageToPerson}
            aria-labelledby="responsive-dialog-title"
            style={{ display: "flex", flexDirection: "column"}}
          >
            <DialogContent>

              <Persona
                    text={`Obrigado pelo seu interesse na JobSpot! Faça seu cadastro pelo WhatsApp para que novas empresas entrem em contato diretamente com você!`}
              />

                <SubmitButton
                  type="button"
                  fullWidth
                  style={{ padding: "0px", marginTop: '0px !important', width: "100%", position: "relative", bottom: "30px" }}
                  variant="contained"
                  color="primary"
                  onClick={ _ =>  window.location.href='https://wa.me/555140422006?text=Quero%20me%20cadastrar%20na%20JobSpot!' }
                >
                  {i18n.t("sendMeToJobinBot")}
                </SubmitButton>

            </DialogContent>
          </Dialog>

        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={showMessage}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{message}</span>}
          autoHideDuration={6000}
          onClose={() => doClearLoginMessage()}
        />

        <ContainerBox>
          <Logo>
            <img src={require("../../images/logo.png")} alt="Logo JobSpot" />
          </Logo>

          <form
            noValidate
            ref={this.formRef}
            onKeyDown={this.handleEnterKeyPressOnForm}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="phone"
              id="phone"
              disabled={ this.phone ? true : false }
              label={ this.phone ? i18n.t("phone") : i18n.t("email") }
              value={ this.phone ? "***********" : this.props.phone }
              onChange={e => {
                let txt = e.target.value;

                this.props.doUpdateData({
                  phone: txt.trim()
                });
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              disabled={ this.password ? true : false }
              fullWidth
              name="password"
              label={i18n.t("password")}
              type={ this.state.showPassword ? "text" : "password" }
              id="password"
              autoComplete="current-password"
              value={ this.password ? "********" : password }
              onChange={e => {
                this.props.doUpdateData({
                  password: e.target.value
                });
              }}
              InputProps={{
                endAdornment: 
                      <IconButton
                          onClick={() => this.handleClickShowPassword()}
                      >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
              }}
            />

            {isLoggingIn ? (
              <LoadingContent>
                <CircularProgress />
              </LoadingContent>
            ) : (
              <>
                <SubmitButton
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isLoggingIn}
                  onClick={this.handleSubmit}
                >
                  {i18n.t("enterCompany")}
                </SubmitButton>

              <Grid container>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  align="center"
                  style={{ marginTop: 25 }}
                >
                  <Link onClick={this.handleChooseValidationForm} to="#" variant="subtitle1">
                    {i18n.t("createAccount")}
                  </Link>
                </Typography>
              </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    align="center"
                    style={{ marginTop: 25 }}
                  >
                    <Link to="/forgot-password" variant="subtitle1">
                      {i18n.t("forgotPassword")}
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              </>
            )}
          </form>
        </ContainerBox>
        <div id="candidateLogin" style={{ display: "flex", alignContent: "center", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "space-evenly" }}>
          <p style={{ marginTop: "15px"}} >Você é candidato?</p>
          <SubmitButton
            type="button"
            variant="contained"
            style={{ backgroundColor: "#5ec749", color: "white" }}
            onClick={() => this.handleSendUserToLogin()}
          >
            {i18n.t("enterPerson")}
          </SubmitButton>
        </div>
      </ContainerJobSpot>
    );
  }
}

const mapStateToProps = state => ({
  loggedIn: state.login.loggedIn,
  isLoggingIn: state.login.isLoggingIn,
  phone: state.login.phone,
  password: state.login.password,
  message: state.login.message,
  showMessage: state.login.showMessage,
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogin,
      doUpdateData,
      doLoginReset,
      doClearLoginMessage
    },
    dispatch
  );

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
