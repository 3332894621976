// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import buscoTrabalhoIcon from "../../images/busco-trabalho-icon.png";
import buscoTrabalhadorIcon from "../../images/busco-trabalhador-icon.png";

class Initial extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 0
    };
  }
  handleChange = (event, value) => {
    this.setState({ value: value });

    switch (value) {
      case 0:
        this.props.history.push("/initial");
        break;
      case 1:
        this.props.history.push("/settings");
        break;
      default:
        this.props.history.push("/initial");
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Paper
              elevation={0}
              className={classNames(classes.paper, classes.questionLine)}
            >
              O que você busca?
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              className={classes.demo}
              justifyContent="center"
              spacing={40}
            >
              <Grid item>
                <a href={"/find-bot"} className={classes.buttonLink}>
                  <Paper
                    elevation={0}
                    className={classNames(classes.paper, classes.button)}
                  >
                    <div>
                      <img src={buscoTrabalhoIcon} alt="Job Search Icon" />
                    </div>
                    <div>
                      BUSCO
                      <br />
                      TRABALHO
                    </div>
                  </Paper>
                </a>
              </Grid>
              <Grid item>
                <a href={"/job"} className={classes.buttonLink}>
                  <Paper
                    elevation={0}
                    className={classNames(classes.paper, classes.button)}
                  >
                    <div>
                      <img
                        src={buscoTrabalhadorIcon}
                        alt="Worker Search Icon"
                      />
                    </div>
                    <div>
                      BUSCO
                      <br />
                      TRABALHADORES
                    </div>
                  </Paper>
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  buttonLink: {},
  gridRoot: {
    flexGrow: 1
  },
  paper: {},
  button: {
    backgroundColor: "#FAFAFA",
    color: "#4BA9F4",
    textAlign: "center",
    padding: theme.spacing.unit,
    height: 160,
    width: 160
  },
  questionLine: {
    padding: theme.spacing.unit * 2,
    textAlign: "center",
    color: "#B8B8B8"
  },
  bottomNav: {
    width: "100vw",
    position: "absolute",
    bottom: 0,
    backgroundColor: "#FAFAFA"
  }
});

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Initial))
);
