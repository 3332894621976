// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  withStyles,
  withMobileDialog,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  Input,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress
} from "@material-ui/core";

// Material icons
import { Search as SearchIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

import {
  doLoadCoursesByType,
  doLoadAcademicDegrees
} from "../../../../modules/form-data";
import { doConfirmCourse } from "../../../../modules/profile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectCourse extends Component {
  constructor(props) {
    super(props);

    this.academicDegrees = [];

    this.state = {
      courses: [],
      courseName: null,
      searching: false,

      search: "",
      data: [],
      openDialog: true,
      loadingSearch: false
    };
  }

  componentDidMount() {
    doLoadAcademicDegrees(this.props.token).then(data => {
      this.academicDegrees = data;
    });
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  handleDoSearch = evt => {
    const { token, courseLevel } = this.props;

    if (evt.length < 3) return;

    this.setState({
      loadingSearch: true,
      searching: true,
      courseName: evt
    });

    doLoadCoursesByType(token, courseLevel.value, evt).then(data => {
      this.setState({
        searching: false,
        courses: data,
        loadingSearch: false
      });
    });
  };

  handleDialogClose = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const {
      classes,
      fullScreen,
      courseLevel,
      doConfirmCourse,
      OpenDialogSelectCourseSituation
    } = this.props;
    const { openDialog, courses, loadingSearch } = this.state;

    return (
      <Fragment>
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                {courseLevel.text}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogTitle id="responsive-dialog-title" className={classes.header}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              autoFocus={true}
              ref={inputElement => {
                this.inputField = inputElement;
              }}
              placeholder="Busque pelo curso"
              className={classes.inputSearch}
              onChange={evt => {
                this.handleDoSearch(evt.target.value);
              }}
            />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <List>
              {loadingSearch && <CircularProgress color="secondary" />}
              {courses && courses.length > 0 ? (
                courses.map((e, key) => {
                  return (
                    <Fragment key={key}>
                      <ListItem
                        component="li"
                        button
                        onClick={() => {
                          doConfirmCourse(e);
                          OpenDialogSelectCourseSituation();
                        }}
                      >
                        <ListItemText
                          primary={this.capitalizeFirstLetter(e.text)}
                        />
                      </ListItem>
                      <Divider component="li" />
                    </Fragment>
                  );
                })
              ) : (
                <ListItem component="li">
                  <ListItemText
                    primary={`Nada foi encontrado!`}
                    className={classes.notFoundText}
                  />
                </ListItem>
              )}
            </List>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

SelectCourse.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  OpenDialogSelectCourseSituation: PropTypes.func
};

const mapStateToProps = state => ({
  token: state.login.token,
  courseLevel: state.profile.courseLevel
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourse
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(SelectCourse)))
);
