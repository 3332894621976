// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { withStyles } from "@material-ui/core/styles";
import {
  withMobileDialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Input,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress
} from "@material-ui/core";
import { Search as SearchIcon, Close as CloseIcon } from "@material-ui/icons";

import {
  doLoadCoursesByType,
  doLoadAcademicDegrees
} from "../../../../modules/form-data";
import { doConfirmCourse, doAddNewCourse } from "../../../../modules/job";

import Persona from "../../../../components/Persona";
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectCourse extends Component {
  constructor(props) {
    super(props);

    this.academicDegrees = [];

    this.state = {
      openDialog: true,
      courses: [],
      courseName: null,
      searching: false
    };
  }

  componentDidMount() {
    doLoadAcademicDegrees(this.props.token).then(data => {
      this.academicDegrees = data;
    });
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  handleDoSearch = evt => {
    if (evt.length < 3) return;

    this.setState({
      searching: true,
      courseName: evt
    });

    doLoadCoursesByType(
      this.props.token,
      this.props.courseLevel.value,
      evt
    ).then(data => {
      this.setState({
        searching: false,
        courses: data
      });
    });
  };

  handleSelectCourse = item => {
    const { doConfirmCourse, handleSelectedCourse } = this.props;
    doConfirmCourse(item);
    this.setState({ openDialog: false });
    setTimeout(() => {
      handleSelectedCourse();
    }, 100);
  };

  handleCloseDialog = () => {
    const { handleManagerDialogSelectCouse } = this.props;
    this.setState({ openDialog: false });
    setTimeout(() => {
      handleManagerDialogSelectCouse();
    }, 100);
  };

  render() {
    const { classes, fullScreen, courseLevel } = this.props;
    const { openDialog, courses, searching } = this.state;

    return (
      <Fragment>
        {!openDialog && <CircularProgress color="secondary" />}
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.handleCloseDialog()}
                aria-label="Close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                {courseLevel.text}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogTitle id="responsive-dialog-title" className={classes.header}>
            <SearchIcon className={classes.searchIcon} />
            <Input
              autoFocus={true}
              type="text"
              ref={inputElement => {
                this.inputField = inputElement;
              }}
              placeholder="Digite"
              className={classes.inputSearch}
              onChange={evt => {
                this.handleDoSearch(evt.target.value);
              }}
            />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <List>
              {searching && <CircularProgress color="secondary" />}
              {courses && courses.length > 0 ? (
                courses.map((e, key) => {
                  if (e.id) {
                    return (
                      <Fragment key={key}>
                        <ListItem
                          component="li"
                          button
                          onClick={() => this.handleSelectCourse(e)}
                        >
                          <ListItemText
                            primary={this.capitalizeFirstLetter(e.text)}
                          />
                        </ListItem>
                        <Divider component="li" />
                      </Fragment>
                    );
                  } else {
                    return (
                      <ListItem component="li">
                      <Persona
                        text={`Não encontrei nenhum curso com esses termos... Tente buscar novamente.`}
                      />
                    </ListItem>
                    );
                  }
                })
              ) : (
                <ListItem component="li">
                  <Persona
                    text={`Aqui você pode escolher o curso que deseja. Poderá escolher também cursos semelhantes ao que você deseja. `}
                  />
                </ListItem>
              )}
            </List>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

SelectCourse.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  token: state.login.token,
  courseLevel: state.job.courseLevel
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourse,
      doAddNewCourse
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(SelectCourse)))
);
