export default theme => ({
  root: {},
  header: {
    left: "240px",
    right: "0px",
    top: "65px",
    width: "auto",
    borderLeft: "1px solid #f1f1f1",
    boxShadow: "none",
    backgroundColor: "white"
  },
  headerMobile: {
    left: "0px",
    top: "65px",
    backgroundColor: "white",
    maxWidth: "100%"
  },
  title: {
    flexGrow: 1
  }
});
