import React, { Component, Fragment } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withWidth
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";

import { Work as WorkIcon, LibraryAddCheck } from "@material-ui/icons";

import Loading from "../../containers/loading";

import Persona from "../../components/Persona";

import {
  doLoadPendingInvites,
  doAcceptPendingInvite
} from "../../modules/pending-invites";

import styles from "./styles";

class PendingInvites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      invites: [],
      isDialogOpen: false,
      dialogItemId: null
    };
  }

  componentDidMount() {
    this.props.doLoadPendingInvites();
  }

  handleCloseDialogOnCancel() {
    this.setState({
      isDialogOpen: false,
      dialogItemId: null
    });
  }

  handleCloseDialogOnAccept() {
    this.props.doAcceptPendingInvite(this.state.dialogItemId);
    this.setState({
      isDialogOpen: false,
      dialogItemId: null
    });
  }

  handleOpenDialog(id) {
    this.setState({
      isDialogOpen: true,
      dialogItemId: id
    });
  }

  render() {
    const { classes, width, loading, invites } = this.props;
    const isMobile = ["xs", "sm", "md"].includes(width);

    return (
      <div
        className={classNames(classes.root, {
          [classes.rootFulls]: isMobile
        })}
      >
        <Persona
          text={`Aqui você encontra os convites pendentes feitos pelos empregadores cadastrados.`}
        />

        <Dialog
          open={this.state.isDialogOpen}
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Deseja aceitar esse convite agora?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Você pode aceitar esse convite em outro momento, basta clicar em
              Cancelar
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => this.handleCloseDialogOnCancel()}
              color="primary"
            >
              Cancelar
            </Button>
            <Button
              onClick={() => this.handleCloseDialogOnAccept()}
              color="primary"
              autoFocus
            >
              Aceitar
            </Button>
          </DialogActions>
        </Dialog>

        {loading && <Loading />}

        {!loading && invites.length === 0 && (
          <Typography variant="h3" component="h1" className={classes.title}>
            Nenhum convite pendente
          </Typography>
        )}

        {!loading && invites.length > 0 && (
          <Fragment>
            <Typography variant="h3" component="h1" className={classes.title}>
              Convites pendentes
            </Typography>
            <List component="ul" style={{ padding: "0px", marginTop: "8px" }}>
              {invites.map((v, k) => (
                <Fragment key={k}>
                  <ListItem
                    component="li"
                    button
                    onClick={() => this.handleOpenDialog(v.id)}
                  >
                    <ListItemIcon>
                      <WorkIcon />
                    </ListItemIcon>
                    <ListItemText primary={v.job.ownerUserName} />
                    <LibraryAddCheck className={classes.goOption} />
                  </ListItem>
                  <Divider />
                </Fragment>
              ))}
            </List>
          </Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  invites: state.pendingInvites.invites,
  showMessage: state.pendingInvites.showMessage,
  loading: state.pendingInvites.loading,
  message: state.pendingInvites.message
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLoadPendingInvites,
      doAcceptPendingInvite
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(PendingInvites))
);
