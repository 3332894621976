// @flow
import { replace } from "react-router-redux";
import uuid from "../util/uuid";

export const JOB_ADD_QUALIFICATION_SELECT_COURSE_LEVEL =
  "job/JOB_ADD_QUALIFICATION_SELECT_COURSE_LEVEL";
export const JOB_ADD_QUALIFICATION_SELECT_COURSE_SITUATION =
  "job/JOB_ADD_QUALIFICATION_SELECT_COURSE_SITUATION";
export const JOB_ADD_QUALIFICATION_SELECT_COURSE =
  "job/JOB_ADD_QUALIFICATION_SELECT_COURSE";
export const JOB_ADD_QUALIFICATION_NEW_COURSE =
  "job/JOB_ADD_QUALIFICATION_NEW_COURSE";
export const JOB_SELECT_UPDATE_QUALIFICATION =
  "job/JOB_SELECT_UPDATE_QUALIFICATION";
export const JOB_SELECT_UPDATE_QUALIFICATION_LEVEL_GRADE =
  "job/JOB_SELECT_UPDATE_QUALIFICATION_LEVEL_GRADE";
export const JOB_TOGGLE_ACTIVE = "job/JOB_TOGGLE_ACTIVE";
export const JOB_TOGGLE_QUALIFICATION = "job/JOB_TOGGLE_QUALIFICATION";
export const JOB_SELECT_QUALIFICATION_GRADE =
  "job/JOB_SELECT_QUALIFICATION_GRADE";
export const JOB_TOGGLE_PROFISSAO = "job/JOB_TOGGLE_PROFISSAO";
export const JOB_SELECT_PROFISSAO_GRADE = "job/JOB_SELECT_PROFISSAO_GRADE";
export const JOB_PROFISSAO_ADD_PROFISSAO = "job/JOB_PROFISSAO_ADD_PROFISSAO";
export const JOB_PROFISSAO_ADD_NEW_PROFISSAO =
  "job/JOB_PROFISSAO_ADD_NEW_PROFISSAO";
export const JOB_ADD_PROFISSAO_SELECT_EXPERIENCE =
  "job/JOB_ADD_PROFISSAO_SELECT_EXPERIENCE";
export const JOB_CONFIRM_PROFISSAO = "job/JOB_CONFIRM_PROFISSAO";
export const JOB_SELECT_UPDATE_SPECIFIC_PROFISSAO_GRADE =
  "job/JOB_SELECT_UPDATE_SPECIFIC_PROFISSAO_GRADE";
export const JOB_SELECT_UPDATE_PROFISSAO = "job/JOB_SELECT_UPDATE_PROFISSAO";
export const JOB_TOGGLE_SPECIAL_NEEDS = "job/JOB_TOGGLE_SPECIAL_NEEDS";
export const JOB_SELECT_SPECIAL_NEEDS_GRADE =
  "job/JOB_SELECT_SPECIAL_NEEDS_GRADE";
export const JOB_SELECT_SPECIAL_NEED_SUB_OPTION_GRADE =
  "job/JOB_SELECT_SPECIAL_NEED_SUB_OPTION_GRADE";
export const JOB_TOGGLE_PLACES = "job/JOB_TOGGLE_PLACES";
export const PROFILE_UPDATE_AVAILABLE_CREDITS = "profile/PROFILE_UPDATE_AVAILABLE_CREDITS";
export const JOB_SELECT_PLACES_GRADE = "job/JOB_SELECT_PLACES_GRADE";
export const JOB_SELECT_PLACES_STATE = "job/JOB_SELECT_PLACES_STATE";
export const JOB_SELECT_PLACES_CITY = "job/JOB_SELECT_PLACES_CITY";
export const JOB_SELECT_PLACES_CURRENT_CITY_GRADE =
  "job/JOB_SELECT_PLACES_CURRENT_CITY_GRADE";
export const JOB_SELECT_PLACES_OTHER_CITY_GRADE =
  "job/JOB_SELECT_PLACES_OTHER_CITY_GRADE";
export const JOB_TOGGLE_HIRING = "job/JOB_TOGGLE_HIRING";
export const JOB_SELECT_HIRING_GRADE = "job/JOB_SELECT_HIRING_GRADE";
export const JOB_SELECT_HIRING_SUB_OPTION_GRADE =
  "job/JOB_SELECT_HIRING_SUB_OPTION_GRADE";
export const JOB_TOGGLE_AVAILABILITY = "job/JOB_TOGGLE_AVAILABILITY";
export const JOB_SELECT_AVAILABILITY_GRADE =
  "job/JOB_SELECT_AVAILABILITY_GRADE";
export const JOB_SELECT_AVAILABILITY_SUB_OPTION_GRADE =
  "job/JOB_SELECT_AVAILABILITY_SUB_OPTION_GRADE";
export const JOB_TOGGLE_AGE_RANGE = "job/JOB_TOGGLE_AGE_RANGE";
export const JOB_SELECT_AGE_RANGE_GRADE = "job/JOB_SELECT_AGE_RANGE_GRADE";
export const JOB_SELECT_AGE_RANGE_SUB_OPTION_GRADE =
  "job/JOB_SELECT_AGE_RANGE_SUB_OPTION_GRADE";
export const JOB_TOGGLE_SKILLS = "job/JOB_TOGGLE_SKILLS";
export const JOB_SELECT_SKILLS_GRADE = "job/JOB_SELECT_SKILLS_GRADE";
export const JOB_SELECT_SKILLS_SUB_OPTION_GRADE =
  "job/JOB_SELECT_SKILLS_SUB_OPTION_GRADE";
export const JOB_TOGGLE_GENDER = "job/JOB_TOGGLE_GENDER";
export const JOB_SELECT_GENDER_GRADE = "job/JOB_SELECT_GENDER_GRADE";
export const JOB_SELECT_GENDER_SUB_OPTION_GRADE =
  "job/JOB_SELECT_GENDER_SUB_OPTION_GRADE";
export const JOB_TOGGLE_TAGS = "job/JOB_TOGGLE_TAGS";
export const JOB_SELECT_TAGS_GRADE = "job/JOB_SELECT_TAGS_GRADE";
export const JOB_ADD_TAG = "job/JOB_ADD_TAG";
export const JOB_REMOVE_TAG = "job/JOB_REMOVE_TAG";
export const JOB_RESET_STATE = "job/JOB_RESET_STATE";
export const JOB_SAVE_ERROR = "job/JOB_SAVE_ERROR";
export const JOB_CLOSE_SNACKBAR = "job/JOB_CLOSE_SNACKBAR";
export const JOB_OPEN_SNACKBAR = "job/JOB_OPEN_SNACKBAR";
export const JOB_UPDATE_CANDIDATES = "job/JOB_UPDATE_CANDIDATES";
export const JOB_RESET_CANDIDATES = "job/JOB_RESET_CANDIDATES";
export const JOB_RESTORE_DB_STATE = "job/JOB_RESTORE_DB_STATE";
export const JOB_SHOW_ERROR = "job/JOB_SHOW_ERROR";
export const JOB_CLOSE_ERROR = "job/JOB_CLOSE_ERROR";
export const UPDATE_MY_JOBS = "job/UPDATE_MY_JOBS";

const DEFAULT_GRADE = 0.5;

const initialState = {
  jobId: null,
  jobActive: true,
  jobName: null,
  jobCandidates: {},
  courseLevel: {},
  course: {},
  courseSituations: {},
  academicDegrees: {},
  coursesConfirmed: [],
  qualificationsLevelsGrades: {},
  qualificationConfirmed: false,
  qualificationGrade: 0.5,
  profissaoConfirmed: false,
  profissaoGrade: 0.5,
  profissao: {},
  experiencePeriods: [],
  profissoesConfirmed: [],
  profissoesGrades: {},
  specialNeedsConfirmed: false,
  specialNeedsGrade: 0.5,
  specialNeedsSubOptions: {},
  placesConfirmed: false,
  placesGrade: 0.5,
  placesState: null,
  placesCity: null,
  placesLatitude: null,
  placesLongitude: null,
  currentCityGrade: 0,
  otherCityGrade: 0,
  hiringConfirmed: false,
  hiringGrade: 0.5,
  hiringSubOptions: {},
  availabilityConfirmed: false,
  availabilityGrade: 0.5,
  availabilitySubOptions: {},
  ageRangeConfirmed: false,
  ageRangeGrade: 0.5,
  ageRangeSubOptions: {},
  skillsConfirmed: false,
  skillsGrade: 0.5,
  skillsSubOptions: {},
  genderConfirmed: false,
  genderGrade: 0.5,
  genderSubOptions: {},
  tagsConfirmed: false,
  tagGrade: 0.5,
  tagsSubOptions: [],
  showSnackbar: false,
  errorOpen: false,
  errorTitle: "",
  errorMessage: "",
  myJobs: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case JOB_RESET_CANDIDATES:
      return {
        ...state,
        jobCandidates: {}
      };

    case JOB_SHOW_ERROR:
      return {
        ...state,
        errorOpen: true,
        errorTitle: action.title,
        errorMessage: action.message
      };
    case JOB_CLOSE_ERROR:
      return {
        ...state,
        errorOpen: false
      };

    case JOB_UPDATE_CANDIDATES:
      return {
        ...state,
        jobCandidates: { ...state.jobCandidates, ...action.candidatesResult }
      };

    case JOB_CLOSE_SNACKBAR:
      return {
        ...state,
        showSnackbar: false
      };

    case JOB_OPEN_SNACKBAR:
      return {
        ...state,
        showSnackbar: true
      };

    case JOB_RESET_STATE:
      return {
        ...initialState
      };

    case JOB_RESTORE_DB_STATE:
      return {
        ...action.job,
        jobId: action.job.id,
        jobActive: action.job.active,
        jobName: action.job.name
      };

    case JOB_SELECT_QUALIFICATION_GRADE:
      return {
        ...state,
        qualificationGrade: action.grade
      };

    case JOB_SELECT_UPDATE_QUALIFICATION:
      return {
        ...state,
        coursesConfirmed: [...action.courses]
      };

    case JOB_TOGGLE_ACTIVE:
      return {
        ...state,
        jobActive: !state.jobActive
      };

    case JOB_TOGGLE_QUALIFICATION:
      return {
        ...state,
        qualificationConfirmed: !state.qualificationConfirmed,
        qualificationGrade: 0.5
      };

    case JOB_SELECT_PROFISSAO_GRADE:
      return {
        ...state,
        profissaoGrade: action.grade
      };

    case JOB_TOGGLE_PROFISSAO:
      return {
        ...state,
        profissaoConfirmed: !state.profissaoConfirmed,
        profissaoGrade: 0.5
      };

    case JOB_ADD_QUALIFICATION_SELECT_COURSE_LEVEL:
      return {
        ...state,
        courseLevel: { ...action.courseLevel }
      };

    case JOB_SELECT_UPDATE_QUALIFICATION_LEVEL_GRADE:
      return {
        ...state,
        qualificationsLevelsGrades: { ...action.levels }
      };

    case JOB_ADD_QUALIFICATION_SELECT_COURSE_SITUATION:
      return {
        ...state,
        //courseLevel: {},
        course: {},
        courseSituations: {},
        academicDegrees: {},
        qualificationConfirmed: true,
        coursesConfirmed: [...state.coursesConfirmed, action.course]
      };

    case JOB_ADD_QUALIFICATION_SELECT_COURSE:
      return {
        ...state,
        course: { ...action.course }
      };

    case JOB_ADD_QUALIFICATION_NEW_COURSE:
      return {
        ...state,
        course: { ...action.course }
      };

    case JOB_PROFISSAO_ADD_PROFISSAO:
      return {
        ...state,
        profissao: { ...action.profissao }
      };

    case JOB_PROFISSAO_ADD_NEW_PROFISSAO:
      return {
        ...state,
        profissao: { ...action.profissao }
      };

    case JOB_ADD_PROFISSAO_SELECT_EXPERIENCE:
      return {
        ...state,
        experiencePeriods: [...action.experiencePeriods]
      };

    case JOB_CONFIRM_PROFISSAO:
      return {
        ...state,
        profissao: {},
        experiencePeriods: [],
        profissaoConfirmed: true,
        profissoesConfirmed: [...state.profissoesConfirmed, action.profissao]
      };

    case JOB_SELECT_UPDATE_SPECIFIC_PROFISSAO_GRADE:
      return {
        ...state,
        profissoesGrades: { ...action.profissoes }
      };

    case JOB_SELECT_UPDATE_PROFISSAO:
      return {
        ...state,
        profissoesConfirmed: [...action.profissoes]
      };

    case JOB_TOGGLE_SPECIAL_NEEDS:
      return {
        ...state,
        specialNeedsConfirmed: !state.specialNeedsConfirmed,
        specialNeedsGrade: state.specialNeedsGrade === 1 ? 0 : 1
      };

    case JOB_SELECT_SPECIAL_NEEDS_GRADE:
      return {
        ...state,
        specialNeedsGrade: action.grade
      };

    case JOB_SELECT_SPECIAL_NEED_SUB_OPTION_GRADE:
      return {
        ...state,
        specialNeedsSubOptions: { ...action.subOptions }
      };

    case JOB_TOGGLE_PLACES:
      return {
        ...state,
        placesConfirmed: !state.placesConfirmed,
        placesGrade: 0.5
      };

    case PROFILE_UPDATE_AVAILABLE_CREDITS:
      
      return {
        ...state
      };

    case JOB_SELECT_PLACES_GRADE:
      return {
        ...state,
        placesGrade: action.grade
      };

    case JOB_SELECT_PLACES_STATE:
      return {
        ...state,
        placesState: action.state
      };

    case JOB_SELECT_PLACES_CITY:
      return {
        ...state,
        placesCity: action.city,
        placesLatitude: action.latitude,
        placesLongitude: action.longitude
      };

    case JOB_SELECT_PLACES_CURRENT_CITY_GRADE:
      return {
        ...state,
        currentCityGrade: action.grade
      };

    case JOB_SELECT_PLACES_OTHER_CITY_GRADE:
      return {
        ...state,
        otherCityGrade: action.grade
      };

    case JOB_TOGGLE_HIRING:
      return {
        ...state,
        hiringConfirmed: !state.hiringConfirmed,
        hiringGrade: 0.5
      };

    case JOB_SELECT_HIRING_GRADE:
      return {
        ...state,
        hiringGrade: action.grade
      };

    case JOB_SELECT_HIRING_SUB_OPTION_GRADE:
      return {
        ...state,
        hiringSubOptions: { ...action.subOptions }
      };

    case JOB_TOGGLE_AVAILABILITY:
      return {
        ...state,
        availabilityConfirmed: !state.availabilityConfirmed,
        availabilityGrade: 0.5
      };

    case JOB_SELECT_AVAILABILITY_GRADE:
      return {
        ...state,
        availabilityGrade: action.grade
      };

    case JOB_SELECT_AVAILABILITY_SUB_OPTION_GRADE:
      return {
        ...state,
        availabilitySubOptions: { ...action.subOptions }
      };

    case JOB_TOGGLE_AGE_RANGE:
      return {
        ...state,
        ageRangeConfirmed: !state.ageRangeConfirmed,
        ageRangeGrade: 0.5,
        ageRanges: [
          { ageRange: "_14a17", grade: 0.5 },
          { ageRange: "_18a24", grade: 0.5 },
          { ageRange: "_25a39", grade: 0.5 },
          { ageRange: "_40a59", grade: 0.5 },
          { ageRange: "_60Mais", grade: 0.5 }
        ]
      };

    case JOB_SELECT_AGE_RANGE_GRADE:
      return {
        ...state,
        ageRangeGrade: action.grade
      };

    case JOB_SELECT_AGE_RANGE_SUB_OPTION_GRADE:
      return {
        ...state,
        ageRangeSubOptions: { ...action.subOptions }
      };

    case JOB_TOGGLE_SKILLS:
      return {
        ...state,
        skillsConfirmed: !state.skillsConfirmed,
        skillsGrade: 0.5
      };

    case JOB_SELECT_SKILLS_GRADE:
      return {
        ...state,
        skillsGrade: action.grade
      };

    case JOB_SELECT_SKILLS_SUB_OPTION_GRADE:
      return {
        ...state,
        skillsSubOptions: { ...action.subOptions }
      };

    case JOB_TOGGLE_GENDER:
      return {
        ...state,
        genderConfirmed: !state.genderConfirmed,
        genderGrade: 0.5
      };

    case JOB_SELECT_GENDER_GRADE:
      return {
        ...state,
        genderGrade: action.grade
      };

    case JOB_SELECT_GENDER_SUB_OPTION_GRADE:
      return {
        ...state,
        genderSubOptions: { ...action.subOptions }
      };

    case JOB_TOGGLE_TAGS:
      return {
        ...state,
        tagsConfirmed: !state.tagsConfirmed,
        tagGrade: 0.5
      };

    case JOB_SELECT_TAGS_GRADE:
      return {
        ...state,
        tagGrade: action.grade
      };

    case JOB_ADD_TAG:
      return {
        ...state,
        tagsSubOptions: [...state.tagsSubOptions, action.tag]
      };

    case JOB_REMOVE_TAG:
      return {
        ...state,
        tagsSubOptions: [...action.tagsSubOptions]
      };

    case UPDATE_MY_JOBS:
      return {
        ...state,
        myJobs: action.myJobs
      };

    default:
      return state;
  }
};

export const updateMyJobs = jobs => {
  return dispatch => {
    dispatch({
      type: UPDATE_MY_JOBS,
      myJobs: jobs
    });
  };
};

export const closeSnackbar = () => {
  return dispatch => {
    dispatch({
      type: JOB_CLOSE_SNACKBAR
    });
  };
};

export const doToggleJobActive = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_ACTIVE
    });
  };
};

export const doToggleQualification = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_QUALIFICATION
    });
  };
};

export const doSelectQualificationGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_QUALIFICATION_GRADE,
      grade: grade
    });
  };
};

export const doSelectQualificationSituationGrade = course => {
  return (dispatch, getState) => {
    let courses = getState().job.coursesConfirmed;

    console.log(courses);
    console.log(course);

    courses.map(c => {
      if (c.uuid === course.uuid) {
        return course;
      }

      return c;
    });

    dispatch({
      type: JOB_SELECT_UPDATE_QUALIFICATION,
      courses: courses
    });
  };
};

export const doSelectQualificationLevelGrade = (level, value) => {
  return (dispatch, getState) => {
    let levels = { ...getState().job.qualificationsLevelsGrades };
    levels[level] = value;

    dispatch({
      type: JOB_SELECT_UPDATE_QUALIFICATION_LEVEL_GRADE,
      levels: levels
    });
  };
};

export const doConfirmCourseLevel = courseLevel => {
  return dispatch => {
    dispatch({
      type: JOB_ADD_QUALIFICATION_SELECT_COURSE_LEVEL,
      courseLevel: courseLevel
    });
  };
};

export const doAddNewCourse = course => {
  return (dispatch, getState) => {
    dispatch({
      type: JOB_ADD_QUALIFICATION_NEW_COURSE,
      course: course
    });

    let nextSelection = null;
    if (course.academicDegrees) {
      nextSelection =
        "/job-qualificacao-add-choice-select-course-academic-degree";
    } else {
      nextSelection = "/job-qualificacao-add-choice-select-course-situation";
    }

    dispatch(replace(nextSelection));
  };
};

export const doConfirmCourse = course => {
  return dispatch => {
    dispatch({
      type: JOB_ADD_QUALIFICATION_SELECT_COURSE,
      course: course
    });

    // let nextSelection = null;
    // if (course.academicDegrees) {
    //   nextSelection =
    //     "/job-qualificacao-add-choice-select-course-academic-degree";
    // } else {
    //   nextSelection = "/job-qualificacao-add-choice-select-course-situation";
    // }

    // dispatch(replace(nextSelection));
  };
};

export const doConfirmCourseAcademicDegree = acadSits => {
  return (dispatch, getState) => {
    const courseLevel = getState().job.courseLevel;
    const course = getState().job.course;

    acadSits.forEach(acadSit => {
      dispatch({
        type: JOB_ADD_QUALIFICATION_SELECT_COURSE_SITUATION,
        course: {
          uuid: uuid(),
          course: course,
          courseLevel: courseLevel,
          situation: acadSit.situation,
          academicDegree: acadSit.academicDegree,
          grade: 0.5
        }
      });
    });

    if (isNaN(getState().job.qualificationsLevelsGrades[courseLevel.value])) {
      let levels = { ...getState().job.qualificationsLevelsGrades };
      levels[courseLevel.value] = 0.5;

      dispatch({
        type: JOB_SELECT_UPDATE_QUALIFICATION_LEVEL_GRADE,
        levels: levels
      });
    }

    // dispatch(replace("/job-qualificacao-choices"));
  };
};

export const doConfirmCourseSituation = situations => {
  return (dispatch, getState) => {
    const courseLevel = getState().job.courseLevel;
    const course = getState().job.course;

    situations.forEach(situation => {
      dispatch({
        type: JOB_ADD_QUALIFICATION_SELECT_COURSE_SITUATION,
        course: {
          uuid: uuid(),
          course: course,
          courseLevel: courseLevel,
          situation: situation,
          grade: 0.5
        }
      });
    });

    if (isNaN(getState().job.qualificationsLevelsGrades[courseLevel.value])) {
      let levels = { ...getState().job.qualificationsLevelsGrades };
      levels[courseLevel.value] = 0.5;

      dispatch({
        type: JOB_SELECT_UPDATE_QUALIFICATION_LEVEL_GRADE,
        levels: levels
      });
    }

    // dispatch(replace("/job-qualificacao-choices"));
  };
};

export const doToggleProfissao = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_PROFISSAO
    });
  };
};

export const doSelectProfissaoGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_PROFISSAO_GRADE,
      grade: grade
    });
  };
};

export const doSelectSpecificProfissaoGrade = (profissao, value) => {
  return (dispatch, getState) => {
    let profissoes = { ...getState().job.profissoesGrades };
    profissoes[profissao.id + "-" + profissao.text] = value;

    dispatch({
      type: JOB_SELECT_UPDATE_SPECIFIC_PROFISSAO_GRADE,
      profissoes: profissoes
    });
  };
};

export const doSelectSpecificProfissaoItemGrade = profissao => {
  return (dispatch, getState) => {
    let profissoes = getState().job.profissoesConfirmed;
    profissoes.map(p => {
      if (p.uuid === profissao.uuid) return profissao;

      return p;
    });

    dispatch({
      type: JOB_SELECT_UPDATE_PROFISSAO,
      profissoes: profissoes
    });
  };
};

export const doConfirmProfissao = profissao => {
  return (dispatch, getState) => {
    dispatch({
      type: JOB_PROFISSAO_ADD_PROFISSAO,
      profissao: profissao
    });

    // let nextSelection = "/job-profissao-add-choice-select-experience";
    // dispatch(replace(nextSelection));
  };
};

export const doAddNewProfissao = profissao => {
  return (dispatch, getState) => {
    dispatch({
      type: JOB_PROFISSAO_ADD_NEW_PROFISSAO,
      profissao: profissao
    });

    let nextSelection = "/job-profissao-add-choice-select-experience";
    dispatch(replace(nextSelection));
  };
};

export const doConfirmExperiencePeriods = experiencePeriods => {
  return (dispatch, getState) => {
    dispatch({
      type: JOB_ADD_PROFISSAO_SELECT_EXPERIENCE,
      experiencePeriods: experiencePeriods
    });

    //dispatch(replace("/job-profissao-add-choice-select-segment"));
  };
};

export const doConfirmExperienceSegments = segments => {
  return (dispatch, getState) => {
    const experiencePeriods = getState().job.experiencePeriods;
    const profissao = getState().job.profissao;

    experiencePeriods.forEach(period => {
      dispatch({
        type: JOB_CONFIRM_PROFISSAO,
        profissao: {
          uuid: uuid(),
          profissao: profissao,
          experiencePeriod: period,
          grade: 0.5
        }
      });
    });

    segments.forEach(segment => {
      dispatch({
        type: JOB_CONFIRM_PROFISSAO,
        profissao: {
          uuid: uuid(),
          profissao: profissao,
          segment: segment,
          grade: 0.5
        }
      });
    });

    if (
      isNaN(
        getState().job.profissoesGrades[profissao.id + "-" + profissao.text]
      )
    ) {
      let profissoes = { ...getState().job.profissoesGrades };
      profissoes[profissao.id + "-" + profissao.text] = 0.5;

      dispatch({
        type: JOB_SELECT_UPDATE_SPECIFIC_PROFISSAO_GRADE,
        profissoes: profissoes
      });
    }

    // dispatch(replace("/job-profissao-choices"));
  };
};

export const doToggleSpecialNeeds = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_SPECIAL_NEEDS
    });
  };
};

export const doSelectSpecialNeedsGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_SPECIAL_NEEDS_GRADE,
      grade: grade
    });
  };
};

export const doSelectSpecialNeedSubOptionGrade = (option, grade) => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job.specialNeedsSubOptions };
    subOptions[option] = grade;

    dispatch({
      type: JOB_SELECT_SPECIAL_NEED_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doTogglePlaces = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_PLACES
    });
  };
};

export const doUpdateAvailableCredits = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_UPDATE_AVAILABLE_CREDITS
    });
  };
}

export const doSelectPlacesGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_PLACES_GRADE,
      grade: grade
    });
  };
};

export const doSelecPlacesState = state => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_PLACES_STATE,
      state: state.id
    });
  };
};

export const doSelecPlacesCity = city => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_PLACES_CITY,
      city: city.id,
      latitude: city.latitude,
      longitude: city.longitude
    });
  };
};

export const doSelectCurrentCitySelectGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_PLACES_CURRENT_CITY_GRADE,
      grade: grade
    });
  };
};

export const doSelectOtherCitySelectGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_PLACES_OTHER_CITY_GRADE,
      grade: grade
    });
  };
};

export const doToggleHiring = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_HIRING
    });
  };
};

export const doSelectHiringGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_HIRING_GRADE,
      grade: grade
    });
  };
};

export const doSelectHiringSubOptionGrade = (option, grade) => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job.hiringSubOptions };
    subOptions[option] = grade;

    dispatch({
      type: JOB_SELECT_HIRING_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doMountDefaultHiringOptions = options => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job["hiringSubOptions"] };

    options.forEach(option => {
      subOptions[option.value] = DEFAULT_GRADE;
    });

    dispatch({
      type: JOB_SELECT_HIRING_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doMountDefaultPlacesOptions = options => {
  return (dispatch, getState) => {
    dispatch({
      type: JOB_SELECT_PLACES_OTHER_CITY_GRADE,
      grade: DEFAULT_GRADE
    });

    dispatch({
      type: JOB_SELECT_PLACES_CURRENT_CITY_GRADE,
      grade: DEFAULT_GRADE
    });
  };
};

export const doMountDefaultAvailabilityOptions = options => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job["availabilitySubOptions"] };

    options.forEach(option => {
      subOptions[option.value] = DEFAULT_GRADE;
    });

    dispatch({
      type: JOB_SELECT_AVAILABILITY_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doMountDefaultAgeRangeOptions = options => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job["ageRangeSubOptions"] };

    options.forEach(option => {
      subOptions[option.value] = DEFAULT_GRADE;
    });

    dispatch({
      type: JOB_SELECT_AGE_RANGE_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doMountDefaultGenderOptions = options => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job["genderSubOptions"] };

    options.forEach(option => {
      subOptions[option.value] = DEFAULT_GRADE;
    });

    dispatch({
      type: JOB_SELECT_GENDER_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doToggleAvailability = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_AVAILABILITY
    });
  };
};

export const doSelectAvailabilityGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_AVAILABILITY_GRADE,
      grade: grade
    });
  };
};

export const doSelectAvailabilitySubOptionGrade = (option, grade) => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job.availabilitySubOptions };
    subOptions[option] = grade;

    dispatch({
      type: JOB_SELECT_AVAILABILITY_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doToggleAgeRange = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_AGE_RANGE
    });
  };
};

export const doSelectAgeRangeGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_AGE_RANGE_GRADE,
      grade: grade
    });
  };
};

export const doSelectAgeRangeSubOptionGrade = (option, grade) => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job.ageRangeSubOptions };
    subOptions[option] = grade;

    dispatch({
      type: JOB_SELECT_AGE_RANGE_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doToggleSkills = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_SKILLS
    });
  };
};

export const doSelectSkillsGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_SKILLS_GRADE,
      grade: grade
    });
  };
};

export const doSelectSkillsubOptionGrade = (option, grade) => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job.skillsSubOptions };
    subOptions[option] = grade;

    dispatch({
      type: JOB_SELECT_SKILLS_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doToggleGender = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_GENDER
    });
  };
};

export const doSelectGenderGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_GENDER_GRADE,
      grade: grade
    });
  };
};

export const doSelectGenderSubOptionGrade = (option, grade) => {
  return (dispatch, getState) => {
    let subOptions = { ...getState().job.genderSubOptions };
    subOptions[option] = grade;

    dispatch({
      type: JOB_SELECT_GENDER_SUB_OPTION_GRADE,
      subOptions: subOptions
    });
  };
};

export const doToggleTags = () => {
  return dispatch => {
    dispatch({
      type: JOB_TOGGLE_TAGS
    });
  };
};

export const doSelecttagGrade = grade => {
  return dispatch => {
    dispatch({
      type: JOB_SELECT_TAGS_GRADE,
      grade: grade
    });
  };
};

export const doAddTagSubOption = tag => {
  return dispatch => {
    dispatch({
      type: JOB_ADD_TAG,
      tag: tag
    });
  };
};

export const doRemoveTagSubOption = tag => {
  return (dispatch, getState) => {
    const tagsSubOptions = getState().job.tagsSubOptions.filter(t => t !== tag);

    dispatch({
      type: JOB_REMOVE_TAG,
      tagsSubOptions: tagsSubOptions
    });
  };
};

export const getDbJson = (dbResult, state) => { 
  let json = {
    idVaga: dbResult.id,
    idContratante: state.formData.user.id,
    criterios: {
      SEXO: {
        grau: state.job.genderGrade,
        selecionado: state.job.genderConfirmed,
        caracteristicas: {}
      },
      FAIXA_ETARIA: {
        grau: state.job.ageRangeGrade,
        selecionado: state.job.ageRangeConfirmed,
        caracteristicas: {}
      },
      FORMA_CONTRATACAO: {
        grau: state.job.hiringGrade,
        selecionado: state.job.hiringConfirmed,
        caracteristicas: {}
      },
      DISPONIBILIDADE: {
        grau: state.job.availabilityGrade,
        selecionado: state.job.availabilityConfirmed,
        caracteristicas: {}
      },
      HABILIDADES: {
        grau: state.job.skillsGrade,
        selecionado: state.job.skillsConfirmed,
        caracteristicas: {}
      },
      NECESSIDADES_ESPECIAIS: {
        grau: state.job.specialNeedsGrade,
        selecionado: state.job.specialNeedsConfirmed,
        caracteristicas: {}
      },
      QUALIFICACAO: {
        grau: state.job.qualificationGrade,
        selecionado: state.job.qualificationConfirmed,
        _INSTRUCAO: []
      },
      PROFISSAO: {
        grau: state.job.profissaoGrade,
        selecionado: state.job.profissaoConfirmed,
        _PROFISSOES: []
      },
      TAG: {
        grau: state.job.tagGrade,
        selecionado: state.job.tagsConfirmed,
        tags: []
      },
      LOCALIDADE: {
        grau: state.job.placesGrade,
        selecionado: state.job.placesConfirmed,
        caracteristicas: {}
      }
    }
  };

  if (state.job.currentCityGrade)
    json.criterios.LOCALIDADE.caracteristicas._CIDADE_VAGA =
      state.job.currentCityGrade;

  if (state.job.otherCityGrade)
    json.criterios.LOCALIDADE.caracteristicas._OUTRAS_CIDADES =
      state.job.otherCityGrade;

  if (state.job.placesLatitude)
    json.criterios.LOCALIDADE.caracteristicas._LAT = state.job.placesLatitude;

  if (state.job.placesLongitude)
    json.criterios.LOCALIDADE.caracteristicas._LONG = state.job.placesLongitude;

  if (state.job.placesState)
    json.criterios.LOCALIDADE.caracteristicas._IDUF = state.job.placesState;

  if (state.job.placesCity)
    json.criterios.LOCALIDADE.caracteristicas._IDCIDADE = state.job.placesCity;

  state.job.coursesConfirmed.forEach(course => {
    
    if(course.course !== undefined && course.course !== null) {
      if (isNaN(course.course.id) && course.course.text) {
        let dbCourse = dbResult.courses.find(
          c => c.course.text === course.course.text
        );
        course.course.id = dbCourse.course.id;
      }
    }

    // Busca por instr  
    let existingCourse = json.criterios.QUALIFICACAO._INSTRUCAO.find(
      c =>
        c.__TIPO === course.courseLevel.value &&
        (course.course.id === null || c.__CURSO_ID === course.course.id) &&
        (course.academicDegree === null || c.__SITUACAO !== "" ||
          c.__NIVEIS[0].__NIVEL === course.academicDegree.value)
    );

    if (existingCourse !== null && existingCourse !== undefined) {
      existingCourse.__NIVEIS[0].__SITUACAO[course.situation.value] =
        course.grade;
    } else {
      let c = {
        __GRAU: state.job.qualificationsLevelsGrades[course.courseLevel.value],
        __TIPO: course.courseLevel.value,
        __CURSO_ID: -1,
        __CURSO_NOME: course.course.text,
        __NIVEIS: []
      };

      if (course.course.id !== null) {
        c = {
          ...c,
          __CURSO_ID: course.course.id
        };
      }

      let courseLevel = {
        __NIVEL: "",
        __GRAU: state.job.qualificationsLevelsGrades[course.courseLevel.value],
        __SITUACAO: {}
      };

      if (course.academicDegree)
        courseLevel.__NIVEL = course.academicDegree.value;

      courseLevel.__SITUACAO[course.situation.value] = course.grade;

      c.__NIVEIS.push(courseLevel);

      json.criterios.QUALIFICACAO._INSTRUCAO = [
        ...json.criterios.QUALIFICACAO._INSTRUCAO,
        c
      ];
    }
  });

  state.job.profissoesConfirmed.forEach(profissao => {
    let profissaoId = profissao.profissao.id;
    if (!profissaoId) {
      profissaoId = dbResult.professions.find(
        p => p.profession.text === profissao.profissao.text
      ).profession.id;
    }

    let existingProfissaoIdx = json.criterios.PROFISSAO._PROFISSOES.findIndex(
      p => p.__CODIGO_CBO === profissaoId
    );

    if (existingProfissaoIdx < 0) {
      json.criterios.PROFISSAO._PROFISSOES.push({
        __CODIGO_CBO: profissaoId,
        __TEMPO_EXPERIENCIA: {},
        __SEGMENTOS: {}
      });
      existingProfissaoIdx = json.criterios.PROFISSAO._PROFISSOES.length - 1;
    }

    let existingProfissao =
      json.criterios.PROFISSAO._PROFISSOES[existingProfissaoIdx];
    if (profissao.experiencePeriod) {
      existingProfissao.__TEMPO_EXPERIENCIA[profissao.experiencePeriod.value] =
        profissao.grade;
    } else if (profissao.segment) {
      existingProfissao.__SEGMENTOS[profissao.segment.value] = profissao.grade;
    }
  });

  Object.keys(state.job.genderSubOptions).forEach(gender => {
    json.criterios.SEXO.caracteristicas[gender] =
      state.job.genderSubOptions[gender];
  });

  Object.keys(state.job.ageRangeSubOptions).forEach(ageRange => {
    json.criterios.FAIXA_ETARIA.caracteristicas[ageRange] =
      state.job.ageRangeSubOptions[ageRange];
  });

  Object.keys(state.job.availabilitySubOptions).forEach(shiftPreference => {
    json.criterios.DISPONIBILIDADE.caracteristicas[shiftPreference] =
      state.job.availabilitySubOptions[shiftPreference];
  });

  Object.keys(state.job.hiringSubOptions).forEach(contractingModality => {
    json.criterios.FORMA_CONTRATACAO.caracteristicas[contractingModality] =
      state.job.hiringSubOptions[contractingModality];
  });

  Object.keys(state.job.skillsSubOptions).forEach(skill => {
    json.criterios.HABILIDADES.caracteristicas[skill] =
      state.job.skillsSubOptions[skill];
  });

  state.job.tagsSubOptions.forEach(tag => {
    json.criterios.TAG.tags.push(tag);
  });

  Object.keys(state.job.specialNeedsSubOptions).forEach(specialNeed => {
    json.criterios.NECESSIDADES_ESPECIAIS.caracteristicas[specialNeed] =
      state.job.specialNeedsSubOptions[specialNeed];
  });

  return json;
};

export const doResetJobState = () => {
  return dispatch => {
    dispatch({
      type: JOB_RESET_STATE
    });
  };
};

export const doPersistJob = jobName => {
  return (dispatch, getState) => {
    const token = getState().login.token;

    let jobObj = {
      id: getState().job.jobId,
      active: getState().job.jobActive,
      name: jobName ? jobName : getState().job.jobName,
      user: getState().formData.user.id,
      qualificationSelected: getState().job.qualificationConfirmed,
      qualificationGrade: getState().job.qualificationGrade,
      courses: [],
      professionSelected: getState().job.profissaoConfirmed,
      professionGrade: getState().job.profissaoGrade,
      professions: [],
      genderSelected: getState().job.genderConfirmed,
      genderGrade: getState().job.genderGrade,
      genders: [],
      ageRangeSelected: getState().job.ageRangeConfirmed,
      ageRangeGrade: getState().job.ageRangeGrade,
      ageRanges: [],
      shiftPreferenceSelected: getState().job.availabilityConfirmed,
      shiftPreferenceGrade: getState().job.availabilityGrade,
      shiftPreferences: [],
      contractingModalitySelected: getState().job.hiringConfirmed,
      contractingModalityGrade: getState().job.hiringGrade,
      contractingModalities: [],
      tagSelected: getState().job.tagsConfirmed,
      tagGrade: getState().job.tagGrade,
      tags: [],
      skillSelected: getState().job.skillsConfirmed,
      skillGrade: getState().job.skillsGrade,
      skills: [],
      specialNeedSelected: getState().job.specialNeedsConfirmed,
      specialNeedGrade: getState().job.specialNeedsGrade,
      specialNeeds: [],
      placeInterestSelected: getState().job.placesConfirmed,
      placeInterestGrade: getState().job.placesGrade,
      currentCityGrade: getState().job.currentCityGrade,
      otherCityGrade: getState().job.otherCityGrade,
      state: getState().job.placesState,
      city: {
        id: getState().job.placesCity,
        latitude: getState().job.placesLatitude,
        longitude: getState().job.placesLongitude
      }
    };

    // Education level grades
    let jobEducationLevelGrades = [];
    Object.keys(getState().job.qualificationsLevelsGrades).forEach(
      qualificationLevel => {
        let qualificationLevelGrade = getState().job.qualificationsLevelsGrades[
          qualificationLevel
        ];
        let qualificationLevelObj = getState().formData.educationLevels.find(
          e => e.value === qualificationLevel
        );
        jobEducationLevelGrades.push({
          educationLevel: qualificationLevelObj.id,
          grade: qualificationLevelGrade
        });
      }
    );
    jobObj.educationLevelGrades = jobEducationLevelGrades;

    // courses
    let jobCoursesById = [];
    let jobEducationLevelsById = [];

    getState().job.coursesConfirmed.forEach(course => {

      if(course.course !== undefined && course.course !== null) {
        if (course.course.id || course.course.text) {
          let jobCourseIn =
            jobCoursesById[course.course.id + " - " + course.course.text];
          if (!jobCourseIn) {
            jobCourseIn = {
              course: {
                id: course.course.id,
                text: course.course.text
              },
              educationLevels: [],
              academicDegrees: []
            };
  
            jobCoursesById[
              course.course.id + " - " + course.course.text
            ] = jobCourseIn;
          }
  
          if (course.academicDegree) {
            let idx = jobCourseIn.academicDegrees.findIndex(
              e => e.academicDegree === course.academicDegree.id
            );
            if (idx < 0) {
              idx = jobCourseIn.academicDegrees.length;
              jobCourseIn.academicDegrees.push({
                academicDegree: course.academicDegree.id,
                educationLevel: course.courseLevel.id,
                situations: []
              });
            }
            let jobCourseAcademicDegree = jobCourseIn.academicDegrees[idx];
            jobCourseAcademicDegree.situations.push({
              situation: course.situation.id,
              grade: course.grade
            });
          } else {
            let idx = jobCourseIn.educationLevels.findIndex(
              e => e.educationLevel === course.courseLevel.id
            );
            if (idx < 0) {
              idx = jobCourseIn.educationLevels.length;
              jobCourseIn.educationLevels.push({
                educationLevel: course.courseLevel.id,
                situations: []
              });
            }
            let jobCourseLevel = jobCourseIn.educationLevels[idx];
            jobCourseLevel.situations.push({
              situation: course.situation.id,
              grade: course.grade
            });
          }
        } else {
          let jobEducationLevelIn =
            jobEducationLevelsById[
              course.courseLevel.id + " - " + course.courseLevel.text
            ];
          if (!jobEducationLevelIn) {
            jobEducationLevelIn = {
              educationLevel: course.courseLevel.id,
              situations: []
            };
  
            jobEducationLevelsById[
              course.courseLevel.id + " - " + course.courseLevel.text
            ] = jobEducationLevelIn;
          }
  
          jobEducationLevelIn.situations.push({
            situation: course.situation.id,
            grade: course.grade
          });
        }
      }

    });
    jobObj.educationLevels = Object.values(jobEducationLevelsById);
    jobObj.courses = Object.values(jobCoursesById);

    // professions
    let jobProfessionsById = [];
    getState().job.profissoesConfirmed.forEach(profession => {
      let jobProfessionIn =
        jobProfessionsById[
          profession.profissao.id + " - " + profession.profissao.text
        ];
      if (!jobProfessionIn) {
        jobProfessionIn = {
          profession: {
            id: profession.profissao.id,
            name: profession.profissao.text
          },
          segments: [],
          experiencePeriods: [],
          grade: getState().job.profissoesGrades[
            profession.profissao.id + "-" + profession.profissao.text
          ]
        };

        jobProfessionsById[
          profession.profissao.id + " - " + profession.profissao.text
        ] = jobProfessionIn;
      }

      if (profession.experiencePeriod) {
        jobProfessionIn.experiencePeriods.push({
          experiencePeriod: profession.experiencePeriod.id,
          grade: profession.grade
        });
      } else if (profession.segment) {
        jobProfessionIn.segments.push({
          segment: profession.segment.id,
          grade: profession.grade
        });
      }
    });
    jobObj.professions = Object.values(jobProfessionsById);

    // genders
    let genders = [];
    Object.keys(getState().job.genderSubOptions).forEach(gender => {
      let grade = getState().job.genderSubOptions[gender];
      genders.push({
        gender: gender,
        grade: grade
      });
    });
    jobObj.genders = genders;

    // age ranges
    let ageRanges = [];
    Object.keys(getState().job.ageRangeSubOptions).forEach(ageRange => {
      let grade = getState().job.ageRangeSubOptions[ageRange];
      ageRanges.push({
        ageRange: ageRange,
        grade: grade
      });
    });
    jobObj.ageRanges = ageRanges;

    // shift preferences
    let shiftPreferences = [];
    Object.keys(getState().job.availabilitySubOptions).forEach(
      shiftPreference => {
        let grade = getState().job.availabilitySubOptions[shiftPreference];
        shiftPreferences.push({
          shiftPreference: shiftPreference,
          grade: grade
        });
      }
    );
    jobObj.shiftPreferences = shiftPreferences;

    // contracting modalities
    let contractingModalities = [];
    Object.keys(getState().job.hiringSubOptions).forEach(
      contractingModality => {
        let grade = getState().job.hiringSubOptions[contractingModality];
        contractingModalities.push({
          contractingModality: contractingModality,
          grade: grade
        });
      }
    );
    jobObj.contractingModalities = contractingModalities;

    // skills
    let skills = [];
    Object.keys(getState().job.skillsSubOptions).forEach(skill => {
      let grade = getState().job.skillsSubOptions[skill];
      skills.push({
        skill: skill,
        grade: grade
      });
    });
    jobObj.skills = skills;

    // special needs
    let specialNeeds = [];
    Object.keys(getState().job.specialNeedsSubOptions).forEach(specialNeed => {
      let grade = getState().job.specialNeedsSubOptions[specialNeed];
      specialNeeds.push({
        specialNeed: specialNeed,
        grade: grade
      });
    });
    jobObj.specialNeeds = specialNeeds;

    // tags
    let tags = [];
    getState().job.tagsSubOptions.forEach(tag => {
      tags.push({
        tag: tag
      });
    });
    jobObj.tags = tags;

    let url = process.env.REACT_APP_SERVER_URL + "/job";
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify(jobObj)
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            let jobJsonObj = getDbJson(e, getState());

            fetch(url + "/update-json", {
              method: "PUT",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: token
              },
              body: JSON.stringify(jobJsonObj)
            })
              .then(function(result) {
                if (e.newJob) {
                  if (!result.ok) {
                    dispatch({
                      type: JOB_SAVE_ERROR,
                      error: result
                    });
                  } else {
                    dispatch({
                      type: JOB_OPEN_SNACKBAR
                    });
                    dispatch(replace("/jobs"));
                  }
                }
              })
              .catch(function(error) {
                dispatch({
                  type: JOB_SAVE_ERROR,
                  error: error
                });
              });
          });
        } else {
          result.text().then(e => {
            dispatch({
              type: JOB_SAVE_ERROR,
              error: e
            });
          });
        }
      })
      .catch(function(error) {
        dispatch({
          type: JOB_SAVE_ERROR,
          error: error
        });
      });
  };
};

export const doRestoreJobDbState = dbJob => {
  return (dispatch, getState) => {
    let job = {
      id: dbJob.id,
      name: dbJob.name,
      user: dbJob.user.id,
      active: dbJob.active,
      qualificationConfirmed: dbJob.qualificationSelected,
      qualificationGrade: dbJob.qualificationGrade,
      profissaoConfirmed: dbJob.professionSelected,
      profissaoGrade: dbJob.professionGrade,
      profissoesGrades: {},
      profissoesConfirmed: [],
      qualificationsLevelsGrades: {},
      coursesConfirmed: [],
      genderConfirmed: dbJob.genderSelected,
      genderGrade: dbJob.genderGrade,
      genderSubOptions: {},
      ageRangeConfirmed: dbJob.ageRangeSelected,
      ageRangeGrade: dbJob.ageRangeGrade,
      ageRangeSubOptions: {},
      availabilityConfirmed: dbJob.shiftPreferenceSelected,
      availabilityGrade: dbJob.shiftPreferenceGrade,
      availabilitySubOptions: {},
      hiringConfirmed: dbJob.contractingModalitySelected,
      hiringGrade: dbJob.contractingModalityGrade,
      hiringSubOptions: {},
      tagsConfirmed: dbJob.tagSelected,
      tagGrade: dbJob.tagGrade,
      tagsSubOptions: {},
      specialNeedsConfirmed: dbJob.specialNeedSelected,
      specialNeedsGrade: dbJob.specialNeedGrade,
      specialNeedsSubOptions: {},
      skillsConfirmed: dbJob.skillSelected,
      skillsGrade: dbJob.skillGrade,
      skillsSubOptions: {},
      placesConfirmed: dbJob.placeInterestSelected,
      placesGrade: dbJob.placeInterestGrade,
      currentCityGrade: dbJob.currentCityGrade,
      otherCityGrade: dbJob.otherCityGrade,
      placesState: dbJob.state ? dbJob.state.id : null,
      placesCity: dbJob.city ? dbJob.city.id : null,
      placesLatitude: dbJob.city ? dbJob.city.latitude : null,
      placesLongitude: dbJob.city ? dbJob.city.longitude : null
    };

    let genders = [];
    dbJob.genders.map(g => {
      genders[g.gender.value] = g.grade;
      return g;
    });
    job.genderSubOptions = genders;

    let ageRanges = [];
    dbJob.ageRanges.map(g => {
      ageRanges[g.ageRange.value] = g.grade;
      return g;
    });
    job.ageRangeSubOptions = ageRanges;

    let shiftPreferences = [];
    dbJob.shiftPreferences.map(g => {
      shiftPreferences[g.shiftPreference.value] = g.grade;
      return g;
    });
    job.availabilitySubOptions = shiftPreferences;

    let contractingModalities = [];
    dbJob.contractingModalities.map(g => {
      contractingModalities[g.contractingModality.value] = g.grade;
      return g;
    });
    job.hiringSubOptions = contractingModalities;

    let tags = [];
    dbJob.tags.map(g => {
      tags.push(g.tag);
      return g;
    });
    job.tagsSubOptions = tags;

    let specialNeeds = [];
    dbJob.specialNeeds.map(g => {
      specialNeeds[g.specialNeed.value] = g.grade;
      return g;
    });
    job.specialNeedsSubOptions = specialNeeds;

    let skills = [];
    dbJob.skills.map(g => {
      skills[g.skill.value] = g.grade;
      return g;
    });
    job.skillsSubOptions = skills;

    // Education level
    let qualificationsLevelsGrades = [];
    dbJob.educationLevelGrades.map(e => {
      qualificationsLevelsGrades[e.educationLevel.value] = e.grade;
      return e;
    });
    job.qualificationsLevelsGrades = qualificationsLevelsGrades;

    // Courses
    let coursesConfirmed = [];

    dbJob.educationLevels.map(e => {
      e.situations.map(s => {
        coursesConfirmed.push({
          uuid: uuid(),
          course: {},
          courseLevel: e.educationLevel,
          situation: s.situation,
          grade: s.grade
        });

        return s;
      });

      return e;
    });

    dbJob.courses.map(c => {
      c.academicDegrees.map(a => {
        a.situations.map(s => {
          coursesConfirmed.push({
            uuid: uuid(),
            course: c.course,
            courseLevel: a.educationLevel,
            academicDegree: a.academicDegree,
            situation: s.situation,
            grade: s.grade
          });

          return s;
        });

        return a;
      });

      c.educationLevels.map(e => {
        e.situations.map(s => {
          coursesConfirmed.push({
            uuid: uuid(),
            course: c.course,
            courseLevel: e.educationLevel,
            situation: s.situation,
            grade: s.grade
          });

          return s;
        });

        return e;
      });

      return c;
    });
    job.coursesConfirmed = coursesConfirmed;

    // Professions
    let profissoesGrades = {};
    let profissoesConfirmed = [];
    dbJob.professions.map(p => {
      profissoesGrades[p.profession.id + "-" + p.profession.text] = p.grade;

      p.experiencePeriods.map(ep => {
        profissoesConfirmed.push({
          uuid: uuid(),
          profissao: p.profession,
          experiencePeriod: ep.experiencePeriod,
          grade: ep.grade
        });

        return ep;
      });

      p.segments.map(s => {
        profissoesConfirmed.push({
          uuid: uuid(),
          profissao: p.profession,
          segment: s.segment,
          grade: s.grade
        });

        return s;
      });

      return p;
    });
    job.profissoesGrades = profissoesGrades;
    job.profissoesConfirmed = profissoesConfirmed;

    dispatch({
      type: JOB_RESTORE_DB_STATE,
      job: job
    });
  };
};

export const doLoadJobs = token => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/job/";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doLoadJob = (token, id) => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/job/" + id;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doSaveRating = (token, jobId, candidateId, ratingObject) => {
  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/job/${jobId}/candidate/${candidateId}/rating`;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      },
      body: JSON.stringify({
        rating: ratingObject.rating,
        commentary: ratingObject.commentary == null ? "" : ratingObject.commentary
      })
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doDeleteJob = (token, id) => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/job/" + id;
    fetch(url, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doLoadJobCandidates = (token, id) => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/job/get-candidates/" + id;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doLoadJobCandidatesProfiles = (token, id, size = 25) => {
  return new Promise((resolve, reject) => {
    let url =
      process.env.REACT_APP_SERVER_URL +
      "/job/get-candidates-profiles/" +
      id +
      "?size=" +
      size;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const doResetJobCandidates = () => {
  return dispatch => {
    dispatch({
      type: JOB_RESET_CANDIDATES
    });
  };
};

export const doUpdateJobCandidates = (jobId, candidatesResult) => {

  return (dispatch, getState) => {
    let jobCandidates = {};
    jobCandidates[jobId] = candidatesResult;

    dispatch({
      type: JOB_UPDATE_CANDIDATES,
      candidatesResult: jobCandidates
    });
  };
};

export const sendChatInvitation = (
  token,
  jobId,
  candidateId,
  candidateName,
  candidateEmail
) => {
  return new Promise((resolve, reject) => {
    let url =
      process.env.REACT_APP_SERVER_URL +
      "/job/send-chat-invitation/" +
      jobId +
      "/" +
      candidateId;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          //send email
          fetch(`${process.env.REACT_APP_NODE_API_URL}/invite`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user_id: candidateId,
              name: candidateName,
              email: candidateEmail
            })
          })
            .fetch(resultNode => {
              resolve();
            })
            .catch(function(error) {
              resolve();
            });
        } else {
          //reject();
          fetch(`${process.env.REACT_APP_NODE_API_URL}/invite`, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              user_id: candidateId,
              name: candidateName,
              email: candidateEmail
            })
          })
            .fetch(resultNode => {
              resolve();
            })
            .catch(function(errorNode) {
              resolve();
            });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const favoriteCandidate = (token, jobId, candidateId) => {
  return new Promise((resolve, reject) => {
    let url =
      process.env.REACT_APP_SERVER_URL +
      "/job/favorite/" +
      jobId +
      "/" +
      candidateId;
    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const generatePDF = (token, candidatePhone) => {
  return new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_SERVER_URL}/whatsapp-bot/curriculum/${candidatePhone}`;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          resolve(result.json());
        } else {
          reject();
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const sendDataAccessRequest = (token, jobId, candidateId, currentStatus) => {

  const updatedStatus = "1" + currentStatus.substring(1);

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/job/${jobId}/candidate/${candidateId}/hiring-process/${updatedStatus}`;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const sendUpdatePreferences = (token, userPhone, visibilityStatus) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/user/${userPhone}/profile-visibility/${visibilityStatus}`;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const unlockCandidateStatusProfile = (token, jobId, candidateId, statusCode) => {

  return new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_SERVER_URL}/job/${jobId}/candidate/${candidateId}/hiring-process/${statusCode}`;
    fetch(url, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const getInvitedCandidates = token => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/job/get-pending-candidates";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

/**
 * Returns the current user pending invites (invites that the user has not accepted)
 * @returns []
 */

// 541
// 51995361297


export const getPendingInvites = async token => {
  try {
    const url = `${process.env.REACT_APP_SERVER_URL}/job/get-pending-invites`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    });
    if (!response.ok) return false;
    else {
      const parsedJSON = await response.json();
      return parsedJSON;
    }
  } catch (e) {
    return false;
  }
};

export const getConfirmedCandidates = token => {
  return new Promise((resolve, reject) => {
    let url =
      process.env.REACT_APP_SERVER_URL + "/job/get-confirmed-candidates";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const getConfirmedEmployers = token => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/job/get-confirmed-employers";
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const getJobOwnerUser = (token, jobId) => {
  return new Promise((resolve, reject) => {
    let url = process.env.REACT_APP_SERVER_URL + "/job/find-owner/" + jobId;
    fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token
      }
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(e => {
            resolve(e);
          });
        } else {
          result.text().then(e => {
            reject(e);
          });
        }
      })
      .catch(function(error) {
        reject(error);
      });
  });
};

export const showJobError = (title, message) => {
  return dispatch => {
    dispatch({
      type: JOB_SHOW_ERROR,
      title: title,
      message: message
    });
  };
};

export const closeJobError = () => {
  return dispatch => {
    dispatch({
      type: JOB_CLOSE_ERROR
    });
  };
};
