// @flow
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import "moment/locale/pt-br";
import Checkbox from "@material-ui/core/Checkbox";

import i18n from "../../util/i18n";
import { Snackbar, withStyles } from "@material-ui/core";

import {
  doCreateAccountStep2Company,
  doUpdateData,
  doClearCreateAccountMessage
} from "../../modules/create-account";
import { doLoadStates } from "../../modules/form-data";
import { ContainerPolicyBox, Logo, SubmitButton } from "./styles";

moment.locale("pt-br");

class CreateAccountJobspotPolicy extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      vertical: "top",
      horizontal: "center",
      acceptedUsage: false,
      acceptedPolicy: false,
      acceptedCookies: false
    };
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  capitalizeEachFirstLetter(string) {
    let arr = string.split(" ");
    arr = arr.map(s => {
      return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
    });
    return arr.join(" ");
  }

  componentDidMount() {
    if (this.props.states.length === 0) {
      this.props.doLoadStates();
    }
  }

  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  handleSubmit = () => {
      this.props.doCreateAccountStep2Company(true);
  };

  handleCheckboxClickUsage = () => {
    this.setState({ acceptedUsage: !this.state.acceptedUsage });
  }

  handleCheckboxClickPrivacy = () => {
    this.setState({ acceptedPolicy: !this.state.acceptedPolicy });
  }

  handleCheckboxClickCookies = () => {
    this.setState({ acceptedCookies: !this.state.acceptedCookies });
  }

  render() {
    const {
      history,
      showMessage,
      message,
      doClearCreateAccountMessage
    } = this.props;
    const { vertical, horizontal, acceptedUsage, acceptedPolicy, acceptedCookies } = this.state;

    return(
          <Fragment>
            
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={showMessage}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{message}</span>}
            autoHideDuration={6000}
            onClose={() => doClearCreateAccountMessage()}
          />

          <ContainerPolicyBox
            container
            justifyContent="center"
          >
            <IconButton
              onClick={() => history.replace("/create-account-step-2-company")}
              color="inherit"
              aria-label="Menu"
            >
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <Logo>
              <img src={require("../../images/logo.png")} alt="Logo" />
            </Logo>
                <div>Antes de finalizar, verifique se você está de acordo com as políticas de uso do JobSpot.</div>
                <br />
                <h3>Termos de Uso</h3>
                <iframe title="termos" src="termos-uso.html" style={{ width: "1000px", height: "200px", borderRadius: '5px !important' }}></iframe>
                <br />
                <h3>Política de Privacidade</h3>
                <iframe title="politica" src="politica.html" style={{ width: "1000px", height: "200px", borderRadius: '5px !important' }}></iframe>
                <br />
                <h3>Política de Cookies</h3>
                <iframe title="cookies" src="cookies.html" style={{ width: "1000px", height: "200px"}}></iframe>
                <br />
                <div style={{ justifyContent: "flex-start" }}>
                <Checkbox
                    color="primary"
                    value={acceptedUsage}
                    onClick={this.handleCheckboxClickUsage}
                />
                </div>
                  <small>Estou de acordo com os Termos de Uso</small>
                <div style={{ justifyContent: "flex-start" }}>
                <Checkbox
                    color="primary"
                    value={acceptedPolicy}
                    onClick={this.handleCheckboxClickPrivacy}
                />
                    <small>Estou de acordo com as Políticas de Privacidade</small>
                </div>
                <div style={{ justifyContent: "flex-start" }}>
                <Checkbox
                    color="primary"
                    value={acceptedCookies}
                    onClick={this.handleCheckboxClickCookies}
                />
                  <small>Estou de acordo com as Políticas de Uso de Cookies.</small>
                </div>
                <br />
            <Grid item xs={24}>
                <SubmitButton
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={(!acceptedPolicy || !acceptedUsage || !acceptedCookies)}
                  onClick={this.handleSubmit}
                >
                  {i18n.t("createAccount")}
                </SubmitButton>
            </Grid>
          </ContainerPolicyBox>

          </Fragment>
          )
  }
}

const mapStateToProps = state => ({
  phone: state.createAccount.phone,
  phoneConfirmation: state.createAccount.phoneConfirmation,
  password: state.createAccount.password,
  passwordConfirmation: state.createAccount.passwordConfirmation,
  firstNameLastName: state.createAccount.firstNameLastName,
  socialNumber: state.createAccount.socialNumber,
  state: state.createAccount.state,
  city: state.createAccount.city,
  email: state.createAccount.email,
  isCreating: state.createAccount.isCreating,
  message: state.createAccount.message,
  showMessage: state.createAccount.showMessage,
  states: state.formData.states,
  companyName: state.createAccount.companyName
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateAccountStep2Company,
      doUpdateData,
      doLoadStates,
      doClearCreateAccountMessage
    },
    dispatch
  );

const styles = theme => ({
  progress: {
    margin: theme.spacing.unit(2)
  },
  root: {
    flexGrow: 1,
    height: "100vh"
  },
  flex: {
    flex: 1
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    boxShadow: "none"
  },
  menuButton: {
    color: "#787878"
  },
  mainContainer: {
    margin: 20,
    maxHeight: "calc(100vh - 125px)",
    overflowY: "auto",
    color: "#787878"
  },
  centerAlign: {
    textAlign: "center",
    height: 50,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: 20
  },
  textField: {
    marginTop: 20
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120
  }
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(CreateAccountJobspotPolicy))
);
