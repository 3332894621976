export default theme => ({
  root: {},
  title: {
    flexGrow: 1,
    fontWeight: "bold"
  },
  content: {
    margin: 0,
    marginTop: "15px",
    padding: 0,
    display: "flex"
  },
  inputText: {
    flexGrow: 1,
    marginRight: "15px"
  }
});
