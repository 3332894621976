// @flow
import {
  Button,
  Card,
  FormControlLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Edit as EditIcon, Star as StarIcon } from "@material-ui/icons";
import React, { Component } from "react";
import styles from "./styles";

class JobCardLevel extends Component {
  state = {
    selected: ""
  };

  handleChangeOption = option => {
    if (this.props.active && this.props.selectGradeFn)
      this.props.selectGradeFn(option.target.value);

    if (this.props.active && this.props.jobId && this.props.persistJobFn)
      this.props.persistJobFn();

    this.setState({ selected: option.target.value });
  };

  handleEdit = () => {
    const { history, choicesComponentUrl } = this.props;
    history.replace(choicesComponentUrl);
  };

  render() {
    const {
      classes,
      master,
      active,
      grade,
      cardTitle,
      contentType
    } = this.props;
    const { selected } = this.state;

    return (
      <Card className={classes.card}>
        {master ? (
          <h3 className={classes.masterTitle}>
            {master && <StarIcon />} {cardTitle}
          </h3>
        ) : (
          <div className={classes.header}>
            <h3>{cardTitle}</h3>
            {contentType === "_PROFISSAO" && (
              <Button
                onClick={() => this.handleEdit()}
                style={{ textTransform: "none" }}
              >
                <EditIcon /> Detalhar
              </Button>
            )}
          </div>
        )}

        <RadioGroup
          aria-label="position"
          name="position"
          value={selected}
          onChange={this.handleChangeOption}
          row
          className={classes.radioGroup}
        >
          {this.props.importanceRange.map((v, k) => (
            <FormControlLabel
              key={k}
              value={v.value}
              control={<Radio color="primary" />}
              label={v.text}
              labelPlacement="top"
              className={classes.label}
              readOnly={!active}
              checked={Number(v.value) === Number(grade)}
            />
          ))}
        </RadioGroup>
      </Card>
    );
  }
}

export default withStyles(styles)(JobCardLevel);
