import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";

import {
  ContainerJobSpot,
  Logo,
  ContainerBox,
  SubmitButton,
  LoadingContent
} from "./styles";

import {
  doUpdateData,
  doClearResetPasswordMessage,
  doResetPassword,
  doValidateToken
} from "../../modules/reset-password";

import i18n from "../../util/i18n";
import { Icon, IconButton } from "@material-ui/core";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    vertical: "top",
    horizontal: "center"
  };

  handleClose = () => {
    this.props.showMessage = false;
  };

  handleSubmit = () => {
    if (this.formRef.current.reportValidity()) {
      this.props.doResetPassword();
    }
  };

  componentDidMount() {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get("token");
    this.props.doUpdateData({
      token
    });
    this.props.doValidateToken();
  }

  render() {
    const {
      message,
      showMessage,
      newPassword,
      newPasswordConfirmation,
      doUpdateData,
      isProcessing,
      isTokenValid,
      doClearResetPasswordMessage,
      history
    } = this.props;
    const { vertical, horizontal } = this.state;

    if (isTokenValid) {
      return (
        <ContainerJobSpot component="main" maxWidth="xs">
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={showMessage}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{message}</span>}
            autoHideDuration={6000}
            onClose={() => doClearResetPasswordMessage()}
          />

          <ContainerBox>
            <IconButton
              onClick={() => history.replace("/")}
              color="inherit"
              aria-label="Menu"
            >
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <Logo>
              <img src={require("../../images/logo.png")} alt="Logo JobSpot" />
            </Logo>
            <Typography style={{ textAlign: "center" }}>
              Redefina a sua senha
            </Typography>

            <form noValidate ref={this.formRef}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                label={i18n.t("newPassword")}
                value={newPassword}
                onChange={e => {
                  doUpdateData({
                    newPassword: e.target.value
                  });
                }}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                type="password"
                label={i18n.t("newPasswordConfirmation")}
                value={newPasswordConfirmation}
                onChange={e => {
                  doUpdateData({
                    newPasswordConfirmation: e.target.value
                  });
                }}
              />
              {isProcessing ? (
                <LoadingContent>
                  <CircularProgress />
                </LoadingContent>
              ) : (
                <SubmitButton
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isProcessing}
                  onClick={this.handleSubmit}
                >
                  {i18n.t("send")}
                </SubmitButton>
              )}
            </form>
          </ContainerBox>
        </ContainerJobSpot>
      );
    } else {
      return <Fragment></Fragment>;
    }
  }
}

const mapStateToProps = state => ({
  newPassword: state.resetPassword.newPassword,
  newPasswordConfirmation: state.resetPassword.newPasswordConfirmation,
  isTokenValid: state.resetPassword.isTokenValid,
  showMessage: state.resetPassword.showMessage,
  message: state.resetPassword.message,
  isProcessing: state.resetPassword.isProcessing
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateData,
      doClearResetPasswordMessage,
      doResetPassword,
      doValidateToken
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
);
