import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";

export const ContainerJobSpot = styled(Container)`
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
`;

export const Logo = styled.div`
  width: 100%;
  max-width: 230px;
  margin: 15px auto;
  padding-top: 20px;
  img {
    width: 100%;
  }
`;

export const ContainerBox = styled(Grid)`
  border: 1px solid #dadce0;
  border-radius: 6px;
  padding: 30px;

  @media (max-width: 600px) {
    border: 0;
    padding: 0;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 15px !important;
`;

export const LoadingContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;
