// @flow
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  withStyles,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  Slide,
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  Snackbar
} from "@material-ui/core";

import { doConfirmCourseAcademicDegree } from "../../../../modules/job";
import { doLoadEducationSituations } from "../../../../modules/form-data";

// Material icons
import { Save as SaveIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectCourseSituation extends Component {
  constructor(props) {
    super(props);

    this.educationSituations = [];
    this.selectedCheckboxes = new Set();
    this.selectedValues = [];

    this.state = {
      selectedsQty: 0,
      openDialog: true,
      showMsg: false
    };
  }

  componentDidMount() {
    doLoadEducationSituations(this.props.token).then(data => {
      this.educationSituations = data;
      this.forceUpdate();
    });
  }

  toggleCheckbox = (academicDegree, situation) => {
    const academicDegreeKey = academicDegree.value;
    const situationKey = situation.value;
    const key = academicDegreeKey + situationKey;

    if (this.selectedCheckboxes.has(key)) {
      this.selectedCheckboxes.delete(key);
      delete this.selectedValues[key];
    } else {
      this.selectedCheckboxes.add(key);

      this.selectedValues[key] = {
        academicDegree: academicDegree,
        situation: situation
      };
    }

    this.setState({ selectedsQty: this.selectedCheckboxes.size });
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleSaveSituation = () => {
    let values = Object.values(this.selectedValues).filter(
      item => item !== null
    );
    this.props.doConfirmCourseAcademicDegree(values);
    this.setState({ openDialog: false });
    this.props.handleCloseSelectSituation();
  };

  render() {
    const { classes, course, courseLevel } = this.props;
    const { openDialog, selectedsQty, showMsg } = this.state;

    let cardTitle = null;
    if (
      courseLevel.value === "ENSINO_FUNDAMENTAL" ||
      courseLevel.value === "ENSINO_MEDIO"
    ) {
      cardTitle = this.capitalizeFirstLetter(courseLevel.text);
    } else {
      cardTitle = this.capitalizeFirstLetter(course.text);
    }

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar position="static">
          <Toolbar>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {cardTitle}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogTitle id="responsive-dialog-title" className={classes.header}>
          Selecione o grau acadêmico
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          {this.props.course.academicDegrees.map((vl, k1) => (
            <FormControl
              key={k1}
              component="fieldset"
              className={classes.formControl}
            >
              <FormLabel component="legend">{vl.text}</FormLabel>
              <FormGroup
                aria-label="Situacao"
                name="situacao1"
                className={classes.group}
              >
                {this.educationSituations.map((el, k) => (
                  <FormControlLabel
                    key={k}
                    value={el.value}
                    control={<Checkbox />}
                    label={el.text}
                    onChange={() => this.toggleCheckbox(vl, el)}
                  />
                ))}
              </FormGroup>
            </FormControl>
          ))}

          {selectedsQty > 0 && (
            <Fab
              color="secondary"
              variant="extended"
              aria-label="Save"
              style={{ margin: "15px auto", display: "flex" }}
              onClick={() => this.handleSaveSituation()}
            >
              <SaveIcon style={{ marginRight: "10px" }} />
              Confirmar
            </Fab>
          )}
        </DialogContent>

        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          open={showMsg}
          onClose={() => {
            this.setState({ showMsg: false });
          }}
          autoHideDuration={3000}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">Adicionado com sucesso!</span>}
        />
      </Dialog>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  courseLevel: state.job.courseLevel,
  course: state.job.course
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourseAcademicDegree
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(SelectCourseSituation))
);
