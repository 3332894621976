// @flow
import React from "react";
import { Box } from "@material-ui/core";
import CircularProgress from '@mui/material/CircularProgress';

import i18n from "../../util/i18n";

class LoadingSplash extends React.Component {
  render() {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center"
        height="100vh" >
          {i18n.t("loading")}
          <CircularProgress />
      </Box>
    );
  }
}

export default LoadingSplash;
