import React, { Fragment } from "react";
import { withRouter, Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
// Externals
import classNames from "classnames";
import compose from "recompose/compose";
import PropTypes from "prop-types";

// Material helpers
import { Tooltip, withStyles } from "@material-ui/core";

// Material components
import { IconButton, Toolbar } from "@material-ui/core";

// Material icons
import { Input as InputIcon } from "@material-ui/icons";

// Component styles
import styles from "./styles";

import { LOAD_DATA_USER, doLoadUser } from "../../../modules/form-data";
import { doUpdateProfile, forceReloadProfile } from "../../../modules/profile";

function Topbar({
  classes,
  className,
  title,
  isSidebarOpen,
  onToggleSidebar,
  updatedData,
  dispatch,
  token,
  profileType
}) {
  const rootClassName = classNames(classes.root, className);

  function handleSignOut() {
    //dispatch(signOut());

    dispatch({
      type: "FACTORY_RESET"
    });

    if (profileType === "COMPANY") {
      window.location.replace('https://jobspot.com.br/quero-contratar/');
    }

    if (profileType === "PERSON") {
      window.location.replace('https://jobspot.com.br/busco-trabalho-2/');
    }
  }

  function reloadUser() {
    doLoadUser(token).then(userData => {
      dispatch({
        type: LOAD_DATA_USER,
        user: userData
      });
      updatedData(userData);
    })
  }

  return (
    <Fragment>
      <div className={rootClassName}>
        <Toolbar className={classes.toolbar}>
          <Link to="/" style={{ maxWidth: 200 }}>
            <img
              src={require("../../../images/logo_white.png")}
              alt="White JobSpot Logo"
              onClick={reloadUser}
            />
          </Link>

          <Tooltip title="Logout" enterTouchDelay={50}>
            <IconButton
              className={classes.signOutButton}
              onClick={handleSignOut}
            >
              <InputIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </div>
    </Fragment>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  isSidebarOpen: PropTypes.bool,
  onToggleSidebar: PropTypes.func,
  title: PropTypes.string
};

Topbar.defaultProps = {
  onToggleSidebar: () => {}
};

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      dispatch,
      doUpdateProfile,
      forceReloadProfile
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withRouter, withStyles(styles))(Topbar))
);
