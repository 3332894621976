// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  withStyles,
  withMobileDialog,
  AppBar,
  Toolbar,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Slide,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider,
  CircularProgress,
  Checkbox
} from "@material-ui/core";

//Component styles
import styles from "./styles";

import { doConfirmExperienceSegments } from "../../../../modules/job";
import { doLoadSegments } from "../../../../modules/form-data";
import Persona from "../../../../components/Persona";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectSegment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      openDialog: true,
      segments: [],
      gridSegments: [],
      selectedSegments: new Set(),
      selectedSegmentsIds: new Set()
    };
  }

  componentDidMount() {
    this.loadSegments();
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  loadSegments = () => {
    const { token } = this.props;

    doLoadSegments(token).then(data => {
      this.setState({
        segments: data
      });
    });
  };

  toggleSegment = opt => {
    if (this.state.selectedSegmentsIds.has(opt.id)) {
      this.state.selectedSegments.delete(opt);
      this.state.selectedSegmentsIds.delete(opt.id);
    } else {
      this.state.selectedSegments.add(opt);
      this.state.selectedSegmentsIds.add(opt.id);
    }

    this.setState({
      selectedSegments: this.state.selectedSegments,
      selectedSegmentsIds: this.state.selectedSegmentsIds
    });
  };

  handleSubmit = () => {
    const { doConfirmExperienceSegments } = this.props;
    const { selectedSegments } = this.state;

    const values = [...selectedSegments];
    doConfirmExperienceSegments(values);
    this.handleSaveEdit();
  };

  handleSaveEdit = () => {
    const { selectedSegment } = this.props;
    selectedSegment();
  };

  render() {
    const { classes, fullScreen } = this.props;
    const { openDialog, loading, segments, selectedSegmentsIds } = this.state;

    return (
      <Fragment>
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                Áreas de interesse
              </Typography>
              <Button color="inherit" onClick={() => this.handleSubmit()}>
                Confirmar
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent className={classes.dialogContent}>
            <Persona
              text={`Aqui você pode escolher alguma área específica que o candidato já tenha trabalhado. Escolha quantas quiser.`}
            />

            <List>
              {loading && <CircularProgress color="secondary" />}

              {segments && segments.length > 0 ? (
                segments.map((item, key) => {
                  return (
                    <Fragment key={key}>
                      <ListItem component="li" button onClick={() => this.toggleSegment(item)}>
                        <ListItemText
                          id={`item-${item.id}`}
                          primary={this.capitalizeFirstLetter(item.text)}
                        />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={() => this.toggleSegment(item)}
                            checked={
                              [...selectedSegmentsIds].indexOf(item.id) >= 0
                            }
                            inputProps={{
                              "aria-labelledby": `item-${item.id}`
                            }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <Divider component="li" />
                    </Fragment>
                  );
                })
              ) : (
                <ListItem component="li">
                  <ListItemText
                    primary={`Nada foi encontrado!`}
                    className={classes.notFoundText}
                  />
                </ListItem>
              )}
            </List>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

SelectSegment.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  selectedSegment: PropTypes.func
};

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmExperienceSegments
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(withMobileDialog()(SelectSegment)))
);
