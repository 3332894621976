// Material helpers
import { withStyles } from "@material-ui/core";
import { Rating } from "@material-ui/lab";
import classNames from "classnames";
// Externals
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import ManIcon from "../../../../svg-icons/man-icon";
import UnknownPersonIcon from "../../../../svg-icons/unknown-person-icon";
import WomanIcon from "../../../../svg-icons/woman-icon";
import { Favorite as FavoriteBorderIcon } from "@material-ui/icons";
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import LockIcon from '@mui/icons-material/Lock';
import Tooltip from '@material-ui/core/Tooltip';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';

import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

//Component styles
import styles from "./styles";

class Item extends Component {
  state = {
    gender: null,
    name: null
  };

  buildSpeechIcon(hiringStatus) {
    
    if (hiringStatus[0] === '1') {
      return (
        <Tooltip title="Solicitação de contato para vaga enviada" placement="top">
          <QuestionAnswerIcon style={{ textTransform: "none", position: "relative", color: "#0062E4", bottom: "1px", left: "20px" }} />
        </Tooltip>
      )
    }

    if (hiringStatus[0] === '3') {
      return (
        <Tooltip title="Solicitação de contato recusada pelo candidato" placement="top">
          <QuestionAnswerIcon style={{ textTransform: "none", position: "relative", color: "#ff2e2e", bottom: "1px", left: "20px" }} />
        </Tooltip>
      )
    }

    if (hiringStatus[0] === '4') {
      return (
        <Tooltip title="Solicitação de contato aceita pelo candidato" placement="top">
          <QuestionAnswerIcon style={{ textTransform: "none", position: "relative", color: "green", bottom: "1px", left: "20px" }} />
        </Tooltip>
      )
    }

    return (
      <Tooltip title="Solicitação de contato para vaga não enviada" placement="top">
        <QuestionAnswerIcon style={{ textTransform: "none", position: "relative", color: "lightgray", bottom: "1px", left: "20px" }} />
      </Tooltip>
    )
  }

  buildRatingIcon(rating) {
    if (rating === 1) {
      return (
        <Tooltip title="Candidato avaliado como: Muito ruim" placement="top">
          <SentimentVeryDissatisfiedIcon style={{ textTransform: "none", position: "relative", color: "#ff5656", bottom: "1px", left: "28px" }} />
        </Tooltip>
      )
    }

    if (rating === 2) {
      return (
        <Tooltip title="Candidato avaliado como: Ruim" placement="top">
          <SentimentDissatisfiedIcon style={{ textTransform: "none", position: "relative", color: "#ed7b2f", bottom: "1px", left: "28px" }} />
        </Tooltip>
      )
    }

    if (rating === 3) {
      return (
        <Tooltip title="Candidato avaliado como: Mediano" placement="top">
          <SentimentSatisfiedIcon style={{ textTransform: "none", position: "relative", color: "#FFB400", bottom: "1px", left: "28px" }} />
        </Tooltip>
      )
    }

    if (rating === 4) {
      return (
        <Tooltip title="Candidato avaliado como: Bom" placement="top">
          <SentimentSatisfiedAltIcon style={{ textTransform: "none", position: "relative", color: "#57cc6b", bottom: "1px", left: "28px" }} />
        </Tooltip>
      )
    }

    if (rating === 5) {
      return (
        <Tooltip title="Candidato avaliado como: Muito bom" placement="top">
          <SentimentVerySatisfiedIcon style={{ textTransform: "none", position: "relative", color: "green", bottom: "1px", left: "28px" }} />
        </Tooltip>
      )
    }

    if (rating === null) {
      return (
        <Tooltip title="Avaliação para o candidato" placement="top">
          <SentimentSatisfiedIcon style={{ textTransform: "none", position: "relative", color: "lightgray", bottom: "1px", left: "28px" }} />
        </Tooltip>
      )
    }
  }

  buildProfileVisibilityIcon(profileVisibility) {
    if (profileVisibility === "automatic") {
      return (
        <Tooltip title="Candidato com perfil pré-liberado para visualização" placement="top">
          <CheckCircleOutlineIcon style={{ textTransform: "none", position: "relative", color: "#57CC6B", bottom: "1px", left: "33px" }} />
        </Tooltip>
      )
    }

    if (profileVisibility === "requiresConfirmation" || profileVisibility === null || profileVisibility === undefined) {
      return (
        <Tooltip title="É necessário solicitar acesso aos dados do candidato" placement="top">
          <HighlightOffIcon style={{ textTransform: "none", position: "relative", color: "#ed7b2f", bottom: "1px", left: "33px" }} />
        </Tooltip>
      )
    }
  }

  buildGenderIcon = (c, hiringStatus) => {

    if (hiringStatus[0] !== '4') {
      return <UnknownPersonIcon size={24} opacity={0.5} />;
    } else {
      if (c.gender === "_FEM") {
        return <WomanIcon size={24} opacity={0.5} />;
      }
    }

    return <ManIcon size={24} opacity={0.5} />;
  };

  buildCandidateName = (c, hiringStatus) => {
    let name = c.user.publicId;

    if (hiringStatus[0] === '4') {
      name = c.user.name;
    }

    return name;
  }

  calculateStars = totalPoints => {
    const totalStars = 5;
    const totalStarsPercentage = 100;
    return (totalPoints * totalStars) / totalStarsPercentage;
  };

  render() {
    const { classes, isMobile, c } = this.props;

    if (c.job_user === undefined || c.job_user === null) {
      c.job_user = { hiringStatus: '00', favorite: false, rating: null, commentary: null };
    }

    return (
      <div className={classNames(classes.itemList, {
        [classes.itemListMobile]: isMobile
      })}>
        <div className={classes.itemListIcon}>
          <Fragment>
            { this.buildGenderIcon(c, c.job_user.hiringStatus) }
          </Fragment>
        </div>
        <div className={classes.itemListName}>
          <p style={{ flexGrow: "1", marginBottom: "0px", paddingRight: "15px", width: "80px", textAlign: "center"}}>
            {this.buildCandidateName(c, c.job_user.hiringStatus)}
          </p>
            <Rating
              name="simple-controlled"
              size="small"
              readOnly={true}
              value={this.calculateStars(c.totalPontos)}
              precision={0.1}
            />
        </div>
        <div id="status_icons">
        {c.job_user.hiringStatus[0] === '3' ?
        
          <Tooltip title="Solicitação de contato recusada" placement="top">
            <HeartBrokenIcon style={{ textTransform: "none", position: "relative", color: "#ff2e2e" }} />
          </Tooltip> :
          c.job_user.favorite ?
          <Tooltip title="Candidato favorito para vaga" placement="top">
            <FavoriteBorderIcon style={{ textTransform: "none", position: "relative", color: "#0062e4" }} />
          </Tooltip>:
          // nada a ser mostrado
          <Tooltip title="Candidato para a vaga" placement="top">
            <FavoriteBorderIcon style={{ textTransform: "none", position: "relative", color: "lightgray" }} />
          </Tooltip>
        }
        {c.job_user.hiringStatus[1] === '5' ?
          <Tooltip title="Informações profissionais disponíveis" placement="top">
            <LockOpenIcon style={{ textTransform: "none", position: "relative", color: "#0062E4", bottom: "1px", left: "10px" }} />
          </Tooltip>
          :
          <Tooltip title="Informações profissionais não disponíveis" placement="top">
            <LockIcon style={{ textTransform: "none", position: "relative", color: "lightgray", bottom: "1px", left: "10px" }} />
          </Tooltip>
        }
        {
          this.buildSpeechIcon(c.job_user.hiringStatus)
        }
        {
          this.buildRatingIcon(c.job_user.rating)
        }
        {
          this.buildProfileVisibilityIcon(c.user.profileVisibility)
        }
        </div>

      </div>
    );
  }
}

Item.propTypes = {
  range: PropTypes.number,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Item);
