// @flow
import React from "react";
import { CSSTransitionGroup } from "react-transition-group";

export default class SubLevelOptions extends React.Component {
  render() {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          width: "100vw"
        }}
      >
        <div className="card">
          <div className="list-group list-group-flush">
            <CSSTransitionGroup
              transitionName="fade"
              transitionEnterTimeout={500}
              transitionLeaveTimeout={300}
            >
              {this.props.subLevelOptions &&
                this.props.subLevelOptions.map((e, key) => {
                  return (
                    <div
                      className="list-group-item"
                      key={key}
                      onClick={() => {
                        this.props.doHandleSubLevelOption(e);
                      }}
                    >
                      {e.text}
                    </div>
                  );
                })}
            </CSSTransitionGroup>
          </div>
        </div>
      </div>
    );
  }
}
