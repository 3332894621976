import styled from "styled-components";
import Button from "@material-ui/core/Button";

export default theme => ({
  root: {
    padding: theme.spacing(4)
  },
  progress: {
    margin: theme.spacing(4)
  },
  fab: {
    margin: "8px auto 0px auto",
    marginTop: "8px",
    boxShadow: "unset",
    display: "flex"
  }
});

export const SubmitButton = styled(Button)`
  margin-top: 15px !important;
`;
