// @flow
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

import Loading from "../loading";

import { doLoadSegments } from "../../modules/form-data";
import { doConfirmExperienceSegments } from "../../modules/job";

class JobProfissaoAddChoiceSelectSegment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      segments: [],
      gridSegments: [],
      selectedSegments: new Set(),
      selectedSegmentsIds: new Set()
    };
  }
  toggleSegment = opt => {
    if (this.state.selectedSegmentsIds.has(opt.id)) {
      this.state.selectedSegments.delete(opt);
      this.state.selectedSegmentsIds.delete(opt.id);
    } else {
      this.state.selectedSegments.add(opt);
      this.state.selectedSegmentsIds.add(opt.id);
    }

    this.setState({
      selectedSegments: this.state.selectedSegments,
      selectedSegmentsIds: this.state.selectedSegmentsIds
    });
  };
  componentDidMount() {
    doLoadSegments(this.props.token).then(data => {
      this.setState({
        segments: data
      });

      let gridSegments = [];
      let aux = [];

      data.forEach((el, k) => {
        aux.push(el);

        if ((k + 1) % 2 === 0) {
          gridSegments.push(aux);
          aux = [];
        }
      });

      this.setState({
        gridSegments: gridSegments
      });
    });
  }
  render() {
    const { classes } = this.props;

    if (this.state.gridSegments.length === 0) {
      return <Loading />;
    } else {
      return (
        <div>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                className={classes.menuButton}
                color="inherit"
                aria-label="Menu"
                onClick={() => {
                  this.props.history.replace(
                    "/job-profissao-add-choice-select-experience"
                  );
                }}
              >
                <Icon className={classes.icon}>keyboard_arrow_left</Icon>
              </IconButton>
              <Typography
                variant="title"
                color="inherit"
                className={classes.flex}
              >
                Áreas de interesse
              </Typography>
              <Button
                color="inherit"
                onClick={() => {
                  let values = [...this.state.selectedSegments];
                  this.props.doConfirmExperienceSegments(values);
                }}
              >
                Confirmar
              </Button>
            </Toolbar>
          </AppBar>
          <Grid container className={classes.root} spacing={16}>
            {this.state.gridSegments.map((row, k1) => {
              return (
                <Grid key={k1} item xs={12}>
                  <Grid container justifyContent="center" spacing={16}>
                    {row.map((col, k2) => {
                      return (
                        <Grid key={k2} item>
                          <Paper
                            className={
                              [...this.state.selectedSegmentsIds].indexOf(
                                col.id
                              ) >= 0
                                ? classes.smallCardSelected
                                : classes.smallCard
                            }
                            onClick={() => {
                              this.toggleSegment(col);
                            }}
                          >
                            <div style={{ textAlign: "center" }}>
                              <img
                                src={require("../../images/84b12ae895a9177449241d9d65b92f3c_757575.svg")}
                                alt="Graduation hat icon"
                                width={50}
                                height={50}
                              />
                              <div className={classes.smallCardText}>
                                {col.text}
                              </div>
                            </div>
                          </Paper>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
          <div className={classes.footer} />
        </div>
      );
    }
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: 20,
    marginTop: 5,
    maxHeight: "calc(100vh - 120px)",
    overflowY: "auto"
  },
  smallCard: {
    height: 150,
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  smallCardSelected: {
    height: 150,
    width: 150,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#B3DFDB"
  },
  smallCardText: {
    fontSize: 13
  },
  footer: {
    position: "fixed",
    bottom: 20,
    left: "50%",
    transform: "translateX(-50%)"
  },
  flex: {
    flex: 1
  }
});

const mapStateToProps = state => ({
  token: state.login.token
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmExperienceSegments
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(JobProfissaoAddChoiceSelectSegment))
);
