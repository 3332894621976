import { replace } from "react-router-redux";
import i18n from "../util/i18n";

const initialState = {
  isProcessing: false,
  newPassword: "",
  newPasswordConfirmation: "",
  message: "",
  showMessage: false,
  token: "",
  isTokenValid: false
};

export const RESET_PASSWORD_UPDATE_DATA =
  "reset-password/RESET_PASSWORD_UPDATE_DATA";
export const RESET_PASSWORD_REQUESTED =
  "reset-password/RESET_PASSWORD_REQUESTED";
export const RESET_PASSWORD_EMPTY_FIELDS =
  "reset-password/RESET_PASSWORD_EMPTY_FIELDS";
export const RESET_PASSWORD_NON_MATCHING =
  "reset-password/RESET_PASSWORD_NON_MATCHING";
export const RESET_PASSWORD_CLEAR_MESSAGE =
  "reset-password/RESET_PASSWORD_CLEAR_MESSAGE";
export const RESET_PASSWORD_SUCCESSFUL =
  "reset-password/RESET_PASSWORD_SUCCESSFUL";
export const RESET_PASSWORD_NOT_SUCCESSFUL =
  "reset-password/RESET_PASSWORD_NOT_SUCCESSFUL";
export const RESET_PASSWORD_ERROR = "reset-password/RESET_PASSWORD_ERROR";
export const RESET_PASSWORD_MIN_LENGTH =
  "reset-password/RESET_PASSWORD_MIN_LENGTH";
export const RESET_PASSWORD_VALIDATE_TOKEN_REQUESTED =
  "reset-password/RESET_PASSWORD_VALIDATE_TOKEN_REQUESTED";
export const RESET_PASSWORD_VALID_TOKEN =
  "reset-password/RESET_PASSWORD_VALID_TOKEN";
export const RESET_PASSWORD_INVALID_TOKEN =
  "reset-password/RESET_PASSWORD_INVALID_TOKEN";

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_PASSWORD_UPDATE_DATA:
      return {
        ...state,
        ...action.data
      };
    case RESET_PASSWORD_REQUESTED:
      return {
        ...state,
        isProcessing: true,
        message: "",
        showMessage: false
      };
    case RESET_PASSWORD_EMPTY_FIELDS:
      return {
        ...state,
        isProcessing: false,
        message: i18n.t("allFieldsRequired"),
        showMessage: true
      };
    case RESET_PASSWORD_NON_MATCHING:
      return {
        ...state,
        isProcessing: false,
        message: i18n.t("nonMatchingPasswords"),
        showMessage: true
      };
    case RESET_PASSWORD_CLEAR_MESSAGE:
      return {
        ...state,
        isProcessing: false,
        message: "",
        showMessage: false
      };
    case RESET_PASSWORD_SUCCESSFUL:
      return {
        ...state,
        isProcessing: false,
        message: i18n.t("resetPasswordSuccess"),
        showMessage: true
      };
    case RESET_PASSWORD_NOT_SUCCESSFUL:
      return {
        ...state,
        isProcessing: false,
        message: i18n.t("resetPasswordUnsuccesful"),
        showMessage: true
      };
    case RESET_PASSWORD_ERROR:
      return {
        ...state,
        isProcessing: false,
        message: i18n.t("genericError"),
        showMessage: true
      };
    case RESET_PASSWORD_MIN_LENGTH:
      return {
        ...state,
        isProcessing: false,
        message: i18n.t("minLengthPassword"),
        showMessage: true
      };
    case RESET_PASSWORD_VALID_TOKEN:
      return {
        ...state,
        isProcessing: false,
        message: "",
        showMessage: false,
        isTokenValid: true
      };
    case RESET_PASSWORD_INVALID_TOKEN:
      return {
        isProcessing: false,
        newPassword: "",
        newPasswordConfirmation: "",
        message: "",
        showMessage: false,
        token: "",
        isTokenValid: false
      };
    case RESET_PASSWORD_VALIDATE_TOKEN_REQUESTED:
      return {
        ...state,
        isProcessing: true,
        message: "",
        showMessage: false,
        isTokenValid: false
      };
    default:
      return state;
  }
};

export const doUpdateData = data => {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD_UPDATE_DATA,
      data: data
    });
  };
};

export const doClearResetPasswordMessage = () => {
  return dispatch => {
    dispatch({
      type: RESET_PASSWORD_CLEAR_MESSAGE
    });
  };
};

export const doResetPassword = () => {
  return async (dispatch, getState) => {
    dispatch({
      type: RESET_PASSWORD_REQUESTED
    });

    const {
      newPassword,
      newPasswordConfirmation,
      token
    } = getState().resetPassword;

    // Validate fields
    if (!newPassword || !newPasswordConfirmation) {
      dispatch({
        type: RESET_PASSWORD_EMPTY_FIELDS
      });
    } else if (newPassword !== newPasswordConfirmation) {
      dispatch({
        type: RESET_PASSWORD_NON_MATCHING
      });
    } else if (newPassword.length < 6 || newPasswordConfirmation.length < 6) {
      dispatch({
        type: RESET_PASSWORD_MIN_LENGTH
      });
    }
    // If OK, call backend
    else {
      const url = `${process.env.REACT_APP_SERVER_URL}/user/reset-password`;
      try {
        const result = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            validator: token,
            newPassword,
            newPasswordConfirmation
          })
        });
        if (result.ok) {
          dispatch({
            type: RESET_PASSWORD_SUCCESSFUL
          });
        } else {
          dispatch({
            type: RESET_PASSWORD_NOT_SUCCESSFUL
          });
        }
      } catch (e) {
        dispatch({
          type: RESET_PASSWORD_ERROR
        });
      }
    }
  };
};

export const doValidateToken = () => {
  return async (dispatch, getState) => {
    const { token } = getState().resetPassword;

    if (!token || token.length === 0) {
      dispatch({
        type: RESET_PASSWORD_INVALID_TOKEN
      });

      dispatch(replace("/"));
    }
    else {
      const url = `${process.env.REACT_APP_SERVER_URL}/user/reset-password/validate?token=${token}`;

      try {
        const result = await fetch(url, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        });
        if (result.ok) {
          dispatch({
            type: RESET_PASSWORD_VALID_TOKEN
          });
        } else {
          dispatch({
            type: RESET_PASSWORD_INVALID_TOKEN
          });

          dispatch(replace("/"));
        }
      } catch (e) {
        dispatch({
          type: RESET_PASSWORD_INVALID_TOKEN
        });

        dispatch(replace("/"));
      }
    }
  };
};
