// @flow
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
  doCreateAccount,
  doUpdateData,
  doClearCreateAccountMessage
} from "../../modules/create-account";
import { doLoadStates } from "../../modules/form-data";
import i18n from "../../util/i18n";

import Persona from "../../components/Persona";

import {
  CircularProgress,
  TextField,
  IconButton,
  Snackbar
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import {
  ContainerJobSpot,
  Logo,
  ContainerBox,
  SubmitButton,
  LoadingContent
} from "./styles";

class CreateAccount extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    vertical: "top",
    horizontal: "center",
    showPassword: false,
    showPasswordConfirmation: false
  };

  componentDidMount() {
    if (this.props.states.length === 0) {
      this.props.doLoadStates();
    }
  }

  handleBack = () => {
    const { history } = this.props;
    history.goBack();
  };

  handleSubmit = () => {
    if (this.formRef.current.reportValidity()) {
      this.props.doUpdateData({ userType: 'COMPANY' });
      this.props.doCreateAccount();
    }
  };

  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleClickShowPasswordConfirmation = () => {
    this.setState({ showPasswordConfirmation: !this.state.showPasswordConfirmation });
  }

  render() {
    const {
      showMessage,
      message,
      password,
      passwordConfirmation,
      doUpdateData,
      doClearCreateAccountMessage,
      isCreating
    } = this.props;
    const { vertical, horizontal } = this.state;

    return (
      <Fragment>
        <ContainerJobSpot component="main" maxWidth="xs">
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            key={`${vertical},${horizontal}`}
            open={showMessage}
            ContentProps={{
              "aria-describedby": "message-id"
            }}
            message={<span id="message-id">{message}</span>}
            autoHideDuration={6000}
            onClose={() => doClearCreateAccountMessage()}
          />

          <ContainerBox
            container
            justifyContent="center"
            alignItems="center"
          >
            <IconButton onClick={this.handleBack}>
              <ArrowBackIcon />
            </IconButton>
            <Logo>
              <img 
                src={require("../../images/logo.png")}
                alt="Jobspot logo" 
              />
            </Logo>

            <form noValidate ref={this.formRef}>

              <Persona
                hideJobin={ true }
                text={`Seu e-mail e senha informados no cadastro serão utilizados para acessar a plataforma! Não esqueça!`}
              />
              <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  style={{ width: "100%" }}
                  id="email"
                  name="email"
                  label={i18n.t("corporateEmail")}
                  value={this.props.email}
                  onChange={e => {
                    this.props.doUpdateData({
                      email: e.target.value
                    });
                  }}
                />

                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="emailConfirmation"
                  name="emailConfirmation"
                  label={i18n.t("confirmCorporateEmail")}
                  value={this.props.emailConfirmation}
                  onChange={e => {
                    this.props.doUpdateData({
                      emailConfirmation: e.target.value
                    });
                  }}
                />
                
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ width: "100%" }}
                name="password"
                label={i18n.t("password")}
                type={ this.state.showPassword ? "text" : "password" }
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => {
                  doUpdateData({
                    password: e.target.value
                  });
                }}
                InputProps={{
                  endAdornment: 
                    <IconButton onClick={() => this.handleClickShowPassword()}>
                        {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                }}
              />
              
              <TextField
                variant="outlined"
                margin="normal"
                required
                style={{ width: "100%" }}
                name="passwordConfirmation"
                label={i18n.t("confirmPassword")}
                type={ this.state.showPasswordConfirmation ? "text" : "password" }
                id="passwordConfirmation"
                autoComplete="current-password"
                value={passwordConfirmation}
                onChange={e => {
                  doUpdateData({
                    passwordConfirmation: e.target.value
                  });
                }}
                InputProps={{
                  endAdornment: 
                    <IconButton onClick={() => this.handleClickShowPasswordConfirmation()}>
                        {this.state.showPasswordConfirmation ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                }}
              />

              {isCreating ? (
                <LoadingContent>
                  <CircularProgress />
                </LoadingContent>
              ) : (
                <SubmitButton
                  type="button"
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={isCreating}
                  onClick={this.handleSubmit}
                >
                  {i18n.t("nextStep")}
                </SubmitButton>
              )}
            </form>
          </ContainerBox>
        </ContainerJobSpot>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  email: state.createAccount.email,
  emailConfirmation: state.createAccount.emailConfirmation,
  password: state.createAccount.password,
  passwordConfirmation: state.createAccount.passwordConfirmation,
  isCreating: state.createAccount.isCreating,
  message: state.createAccount.message,
  showMessage: state.createAccount.showMessage,
  states: state.formData.states,
  userType: state.createAccount.userType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateAccount,
      doUpdateData,
      doLoadStates,
      doClearCreateAccountMessage
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
);
