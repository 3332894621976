export default theme => ({
  root: {
    padding: theme.spacing(3)
  },
  header: {
    left: "240px",
    borderLeft: "1px solid #f1f1f1",
    boxShadow: "none",
    backgroundColor: "white",
    top: "65px"
  },
  headerMobile: {
    left: "0px",
    backgroundColor: "white",
    top: "65px"
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "115px",
    "& h4": {
      fontSize: "14px",
      textAlign: "center",
      margin: "0px",
      color: "#555"
    },
    "& svg": {
      width: "42px",
      height: "42px",
      color: "#989ca0"
    }
  },
  iconActive: {
    color: `${theme.palette.background.active} !important`
  }
});
