// @flow
import React from "react";
import { withRouter } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { bindActionCreators } from "redux";
import CircularProgress from '@mui/material/CircularProgress';
import { connect } from "react-redux";

import i18n from "../../util/i18n";

class Loading extends React.Component {
  render() {
    return (
      <Container style={{ marginTop: 100 }}>
        <Row>
          <Col
            className="d-flex justify-content-center align-items-center"
            style={{ padding: 25 }}
          >
            {i18n.t("loading")}
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center">
            <CircularProgress />
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Loading)
);
