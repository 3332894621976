import i18n from "../util/i18n";

const initialState = {
  loading: false,
  invites: [],
  showMessage: false,
  message: ""
};

export const PENDING_INVITES_LOAD_DATA_START =
  "pending-invites/PENDING_INVITES_LOAD_DATA_START";
export const PENDING_INVITES_LOAD_DATA_END =
  "pending-invites/PENDING_INVITES_LOAD_DATA_END";
export const PENDING_INVITES_LOAD_DATA_ERROR =
  "pending-invites/PENDING_INVITES_LOAD_DATA_ERROR";
export const PENDING_INVITES_ACCEPT_INVITE_START =
  "pending-invites/PENDING_INVITES_ACCEPT_INVITE_START";
export const PENDING_INVITES_ACCEPT_INVITE_END =
  "pending-invites/PENDING_INVITES_ACCEPT_INVITE_END";
export const PENDING_INVITES_ACCEPT_INVITE_ERROR =
  "pending-invites/PENDING_INVITES_ACCEPT_INVITE_ERROR";

export default (state = initialState, action) => {
  switch (action.type) {
    case PENDING_INVITES_LOAD_DATA_START:
      return {
        loading: true,
        invites: [],
        showMessage: false,
        message: ""
      };
    case PENDING_INVITES_LOAD_DATA_END:
      return {
        loading: false,
        invites: action.result,
        showMessage: false,
        message: ""
      };
    case PENDING_INVITES_LOAD_DATA_ERROR:
      return {
        loading: false,
        invites: [],
        showMessage: true,
        message: i18n.t("genericError")
      };
    case PENDING_INVITES_ACCEPT_INVITE_START:
      return {
        ...state,
        loading: true,
        showMessage: false,
        message: ""
      };
    case PENDING_INVITES_ACCEPT_INVITE_END:
      return {
        ...state,
        loading: false,
        showMessage: true,
        message: i18n.t("acceptedInvite"),
        invites: action.result
      };
    case PENDING_INVITES_ACCEPT_INVITE_ERROR:
      return {
        loading: false,
        showMessage: true,
        message: i18n.t("genericError")
      };
    default:
      return state;
  }
};

export const doLoadPendingInvites = () => {
  return async (dispatch, getState) => {
    dispatch({ type: PENDING_INVITES_LOAD_DATA_START });

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/job/get-pending-invites`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().login.token
        }
      });
      if (!response.ok) dispatch({ type: PENDING_INVITES_LOAD_DATA_ERROR });
      else {
        const parsedJSON = await response.json();
        dispatch({
          type: PENDING_INVITES_LOAD_DATA_END,
          result: parsedJSON
        });
      }
    } catch (e) {
      dispatch({
        type: PENDING_INVITES_LOAD_DATA_ERROR
      });
    }
  };
};

export const doAcceptPendingInvite = jobUser_id => {
  return async (dispatch, getState) => {
    dispatch({
      type: PENDING_INVITES_ACCEPT_INVITE_START
    });

    try {
      const url = `${process.env.REACT_APP_SERVER_URL}/job/accept-chat-invitation/${jobUser_id}`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: getState().login.token
        }
      });
      if (!response.ok) dispatch({ type: PENDING_INVITES_ACCEPT_INVITE_ERROR });
      else {
        const updatedInvites = getState().pendingInvites.invites.filter(
          elem => elem.id !== jobUser_id
        );
        dispatch({
          type: PENDING_INVITES_ACCEPT_INVITE_END,
          result: updatedInvites
        });
      }
    } catch (e) {
      dispatch({
        type: PENDING_INVITES_ACCEPT_INVITE_ERROR
      });
    }
  };
};
