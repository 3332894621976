// @flow
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import { store, persistor, history } from "./util/store";
import { hotjar } from "react-hotjar";
//import Main from "./containers/main";
import Routes from "./routes";
import { PersistGate } from "redux-persist/integration/react";

import { register } from "./theme/common/serviceWorker";

// import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

// Material helpers
import { ThemeProvider } from "@material-ui/styles";

// Theme
import theme from "./theme";

import LoadingSplash from "./components/LoadingSplash";

const onBeforeLift = async () => {
  if (store.getState().login && store.getState().login.token) {
    const url = process.env.REACT_APP_SERVER_URL + "/token/check-validity";
    return await fetch(url, {
      method: "GET",
      headers: {
        Authorization: store.getState().login.token
      }
    })
      .then(function(result) {
        if (!result.ok) {
          store.dispatch({
            type: "FACTORY_RESET"
          });
        }
      })
      .catch(function() {
        store.dispatch({
          type: "FACTORY_RESET"
        });
      });
  }
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate
      loading={<LoadingSplash />}
      persistor={persistor}
      onBeforeLift={onBeforeLift}
    >
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <Routes />
        </ConnectedRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

window.addEventListener('load', (config) => {
  register(config);
})

if (process.env.REACT_APP_ENV === "PROD") 
  hotjar.initialize(2569060, 6);