import { Icon, IconButton } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import {
  doClearForgotPasswordMessage,
  doForgotPassword,
  doUpdateData
} from "../../modules/forgot-password";
import i18n from "../../util/i18n";
import {
  ContainerBox,
  ContainerJobSpot,
  LoadingContent,
  Logo,
  SubmitButton
} from "./styles";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  state = {
    vertical: "top",
    horizontal: "center"
  };

  handleClose = () => {
    this.props.showMessage = false;
  };

  handleSubmit = () => {
    if (this.formRef.current.reportValidity()) {
      this.props.doForgotPassword();
    }
  };

  render() {
    const {
      message,
      showMessage,
      email,
      doUpdateData,
      isProcessing,
      history
    } = this.props;
    const { vertical, horizontal } = this.state;

    return (
      <ContainerJobSpot component="main" maxWidth="xs">
        <Snackbar
          anchorOrigin={{ vertical, horizontal }}
          key={`${vertical},${horizontal}`}
          open={showMessage}
          ContentProps={{
            "aria-describedby": "message-id"
          }}
          message={<span id="message-id">{message}</span>}
          autoHideDuration={6000}
          onClose={() => this.props.doClearForgotPasswordMessage()}
        />

        <ContainerBox>
          <IconButton
            onClick={() => history.replace("/")}
            color="inherit"
            aria-label="Menu"
          >
            <Icon>keyboard_arrow_left</Icon>
          </IconButton>
          <Logo>
            <img src={require("../../images/logo.png")} alt="Logo JobSpot" />
          </Logo>
          <Typography style={{ textAlign: "center" }}>
            Recupere a sua senha
          </Typography>

          <form noValidate ref={this.formRef}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              type="email"
              label={i18n.t("email")}
              value={email}
              onChange={e => {
                doUpdateData({
                  email: e.target.value
                });
              }}
            />
            {isProcessing ? (
              <LoadingContent>
                <CircularProgress />
              </LoadingContent>
            ) : (
              <SubmitButton
                type="button"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isProcessing}
                onClick={this.handleSubmit}
              >
                {i18n.t("send")}
              </SubmitButton>
            )}
          </form>
        </ContainerBox>
      </ContainerJobSpot>
    );
  }
}

const mapStateToProps = state => ({
  email: state.forgotPassword.email,
  message: state.forgotPassword.message,
  showMessage: state.forgotPassword.showMessage,
  isProcessing: state.forgotPassword.isProcessing
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateData,
      doClearForgotPasswordMessage,
      doForgotPassword
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
