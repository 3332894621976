// @flow
import { Card, CardContent, Grid, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { School as SchoolIcon } from "@material-ui/icons";
import classNames from "classnames";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import ImportanceRange from "../../../components/ImportanceRange";
import Persona from "../../../components/Persona";
import TopBar from "../../../components/TopBar";
import {
  doLoadEducationLevels,
  doLoadImportanceRange
} from "../../../modules/form-data";

import {
  doConfirmCourseLevel,
  doPersistJob,
  doSelectQualificationLevelGrade
} from "../../../modules/job";
import DialogEdit from "../components/DialogEdit";
import SelectEducationSituation from "../components/SelectEducationSituation";
import styles from "./styles";

class JobQualificacaoChoices extends Component {
  constructor(props) {
    super(props);

    this.importanceRange = [];
    this.educationLevels = [];
    this.gridEducationLevels = [];
    this.coursesConfirmedList = [];
    this.coursesConfirmedListIds = [];

    this.state = {
      openDialogSelectCourseSituation: false,
      selected: null,
      opendEditDialog: false,
      selectedItem: null
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    doLoadEducationLevels(this.props.token).then(data => {
      this.educationLevels = data;
      this.gridEducationLevels = data.filter(item => item.value !== "NA");
      this.forceUpdate();
    });

    this.coursesConfirmedList = this.props.coursesConfirmed
      .map(l => l.courseLevel)
      .filter(
        (value, index, self) => self.map(l => l.id).indexOf(value.id) === index
      );

    this.coursesConfirmedList.map(item =>
      this.coursesConfirmedListIds.push(item.id)
    );

    doLoadImportanceRange(this.props.token).then(data => {
      this.importanceRange = data;
      this.forceUpdate();
    });
  };

  handleSelectChoice = choice => {
    const { history, doConfirmCourseLevel } = this.props;
    doConfirmCourseLevel(choice);

    if (this.coursesConfirmedListIds.includes(choice.id)) {
      this.setState({
        selectedItem: choice,
        opendEditDialog: true
      });
    } else if (
      choice.value === "ENSINO_FUNDAMENTAL" ||
      choice.value === "ENSINO_MEDIO"
    ) {
      this.setState({
        openDialogSelectCourseSituation: true,
        selected: choice.value
      });
    } else {
      history.replace(`/job-qualificacao-level-choices/${choice.value}`);
    }
  };

  handleBack = () => {
    if (this.props.jobId)
      this.props.history.replace("/job-summary/" + this.props.jobId);
    else this.props.history.replace("/job");
  };

  handleCloseSelectSituation = () => {
    this.setState({ openDialogSelectCourseSituation: false });
    setTimeout(() => {
      this.loadData();
    }, 200);
  };

  render() {
    const { classes } = this.props;
    const {
      openDialogSelectCourseSituation,
      selected,
      opendEditDialog,
      selectedItem
    } = this.state;
    const grid = {
      xs: 6,
      sm: 4,
      md: 4,
      lg: 3
    };

    return (
      <div className={classes.root}>
        <TopBar
          pageTitle="Qualificação"
          buttonTitle="Confirmar"
          hasButton={true}
          buttonColor="primary"
          variant="contained"
          actionButton={() => this.handleBack()}
          actionBack={() => this.handleBack()}
        />

        <Grid container spacing={2} style={{ marginTop: "50px" }}>
          <Persona
            text={`Escolha as opções ideais para essa vaga e também as que mais se aproximarem do perfil desejado. Detalhe quantos itens quiser. `}
          />

          {this.gridEducationLevels.map((el, k) => (
            <Grid
              key={k}
              item
              xs={grid.xs}
              sm={grid.sm}
              md={grid.md}
              lg={grid.lg}
            >
              <Card
                onClick={() => this.handleSelectChoice(el)}
                style={{ cursor: "pointer" }}
              >
                <CardContent className={classes.cardContent}>
                  <SchoolIcon
                    className={classNames(classes.icon, {
                      [classes.iconActive]: this.coursesConfirmedListIds.includes(
                        el.id
                      )
                    })}
                  />
                  <h4>{el.text}</h4>
                  {this.coursesConfirmedListIds.includes(el.id) && (
                    <ImportanceRange
                      range={Number(parseFloat(
                        this.props.qualificationsLevelsGrades[el.value]
                      ))}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {openDialogSelectCourseSituation && (
          <SelectEducationSituation
            courseSelected={selected}
            handleCloseSelectSituation={() => this.handleCloseSelectSituation()}
          />
        )}

        {opendEditDialog && (
          <DialogEdit
            cardTitle={selectedItem.text}
            editQualificacao={true}
            editQualificacaoItem={selectedItem.value}
            active={true}
            toggleCheckboxFn={() => console.log("tgChFN")}
            grade={this.props.qualificationsLevelsGrades[selectedItem.value]}
            selectGradeFn={this.props.doSelectQualificationLevelGrade}
            importanceRange={this.importanceRange}
            jobId={this.props.jobId}
            persistJobFn={this.props.doPersistJob}
            history={this.props.history}
            jobActive={true}
            actionCard={() =>
              this.props.history.replace(
                `/job-qualificacao-level-choices/${selectedItem.value}`
              )
            }
            actionClose={() => this.setState({ opendEditDialog: false })}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.login.token,
  coursesConfirmed: state.job.coursesConfirmed,
  qualificationsLevelsGrades: state.job.qualificationsLevelsGrades
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doConfirmCourseLevel,
      doSelectQualificationLevelGrade,
      doPersistJob
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(JobQualificacaoChoices))
);
