export default theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: "70px"
  },
  header: {
    left: "240px",
    borderLeft: "1px solid #f1f1f1",
    boxShadow: "none",
    backgroundColor: "white",
    top: "65px"
  },
  headerMobile: {
    left: "0px",
    backgroundColor: "white",
    top: "65px"
  },
  fab: {
    position: "fixed",
    right: "20px",
    bottom: "20px"
  }
});
