export default theme => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(4),
    paddingTop: "70px"
  },
  rootFull: {
    flexGrow: 1,
    padding: "0px",
    "& h1": {
      padding: "20px"
    }
  },
  fab: {
    margin: "8px auto 0px auto",
    boxShadow: "unset",
    display: "flex"
  },
  fabDeactivate: {
    margin: "8px auto 0px auto",
    boxShadow: "unset",
    display: "flex",
    backgroundColor: "#ED4740",
    color: "white",
    "&:hover": {
      cursor: "pointer",
      color: "white",
      backgroundColor: "#BF0E08"
    }
  },
  'icon-0': { color: 'grey' },
  'icon-1': { color: 'red' },
  'icon-2': { color: 'coral' },
  'icon-3': { color: 'orange' },
  'icon-4': { color: 'skyblue' },
  'icon-5': { color: 'green' },
  fabMobile: {
    position: "fixed",
    right: "15px",
    bottom: "95px"
  },
  veryUnsatisfied: {
    color: "grey",
    "&:hover": {
      cursor: "pointer",
      color: "#ff5656"
    }
  },
  veryUnsatisfiedChecked: {
    color: "#ff5656",
    "&:hover": {
      cursor: "pointer",
      color: "#ff5656"
    }
  },
  unsatisfied: {
    color: "grey",
    "&:hover": {
      cursor: "pointer",
      color: "#ed7b2f"
    }
  },
  unsatisfiedChecked: {
    color: "#ed7b2f",
    "&:hover": {
      cursor: "pointer",
      color: "#ed7b2f"
    }
  },
  neutral: {
    color: "grey",
    "&:hover": {
      cursor: "pointer",
      color: "#FFB400"
    }
  },
  neutralChecked: {
    color: "#FFB400",
    "&:hover": {
      cursor: "pointer",
      color: "#FFB400"
    }
  },
  satisfied: {
    color: "grey",
    "&:hover": {
      cursor: "pointer",
      color: "#57cc6b"
    }
  },
  satisfiedChecked: {
    color: "#57cc6b",
    "&:hover": {
      cursor: "pointer",
      color: "#57cc6b"
    }
  },
  verySatisfied: {
    color: "grey",
    "&:hover": {
      cursor: "pointer",
      color: "green"
    }
  },
  verySatisfiedChecked: {
    color: "green",
    "&:hover": {
      cursor: "pointer",
      color: "green"
    }
  },
  persona: {
    display: "flex",
    justifyContent: "center"
  },
  card: {
    marginTop: "13px",
    marginBottom: "13px"
  },
  cardHeader: {
    paddingBottom: "5px",
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#222",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    "& small": {
      color: theme.palette.background.active,
      fontSize: "12px",
      fontWeight: "normal"
    }
  },
  cardHeaderInactive: {
    paddingBottom: "5px",
    "& span": {
      fontSize: "16px",
      fontWeight: "bold",
      color: "#222",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center"
    },
    "& small": {
      color: "red",
      fontSize: "12px",
      fontWeight: "normal"
    }
  },
  avatar: {
    fontSize: "14px"
  },
  cardContent: {
    paddingTop: "5px"
  },
  cardContentInactive: {
    paddingTop: "5px",
    opacity: "0.35"
  },
  jobName: {
    flexGrow: 1
  },
  badge: {
    borderRadius: "4px",
    backgroundColor: theme.palette.background.topbar,
    fontSize: "11px",
    color: "#fff",
    textAlign: "center",
    padding: "3px 5px"
  },
  list: {
    margin: 0,
    padding: 0,
    "& li": {
      borderBottom: "1px solid #f1f1f1"
    },
    "& li:last-child": {
      borderBottom: "0px"
    }
  },
  listItemText: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  smallData: {
    fontSize: "11px"
  },
  iconActive: {
    color: theme.palette.background.active
  },
  iconInactive: {
    color: "#ccc"
  }
});
