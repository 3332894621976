// @flow
import React from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Snackbar from "@material-ui/core/Snackbar";
import Divider from "@material-ui/core/Divider";
import ChatIcon from "@material-ui/icons/Chat";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

import Loading from "../loading";

import ManIcon from "../../svg-icons/man-icon";
import WomanIcon from "../../svg-icons/woman-icon";
import UnknownPersonIcon from "../../svg-icons/unknown-person-icon";

import {
  doLoadJobCandidatesProfiles,
  doUpdateJobCandidates,
  sendChatInvitation,
  favoriteCandidate
} from "../../modules/job";

class JobCandidate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeStep: 0,
      candidatesProfilesLoaded: false,
      showSnackbar: false,
      snackbarMessage: ""
    };
  }

  handleNext = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(prevState => ({
      activeStep: prevState.activeStep - 1
    }));
  };

  handleStepChange = activeStep => {
    this.setState({ activeStep });
  };

  componentDidMount() {
    this.reloadScreen();
  }

  reloadScreen() {
    doLoadJobCandidatesProfiles(this.props.token, this.props.jobId).then(j => {
      let candidatos = j.candidatos.filter(c => c.user !== null);
      j.candidatos = candidatos;

      this.props.doUpdateJobCandidates(this.props.jobId, j);

      let activeStep = j.candidatos.findIndex(
        c => c.idCandidato === this.props.match.params.candidateId
      );
      if (activeStep < 0) activeStep = 0;

      this.setState({
        activeStep: activeStep,
        candidatesProfilesLoaded: true
      });
    });
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  render() {
    const { classes } = this.props;
    const { activeStep } = this.state;
    const maxSteps =
      this.props.jobCandidates && this.props.jobCandidates[this.props.jobId]
        ? this.props.jobCandidates[this.props.jobId].candidatos.length
        : 0;
    const candidate =
      this.props.jobCandidates && this.props.jobCandidates[this.props.jobId]
        ? this.props.jobCandidates[this.props.jobId].candidatos[activeStep]
        : null;
    const candidateAccepted =
      candidate &&
      candidate.user &&
      candidate.user.jobs
        .filter(j => j.accepted)
        .map(j => j.job.id)
        .indexOf(this.props.jobId) >= 0;

    return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              <Icon className={classes.icon}>keyboard_arrow_left</Icon>
            </IconButton>
            <Typography
              variant="title"
              color="inherit"
              className={classes.flex}
            >
              {this.props.jobName}
            </Typography>
          </Toolbar>
        </AppBar>
        {!this.state.candidatesProfilesLoaded && <Loading />}
        {this.state.candidatesProfilesLoaded && (
          <div>
            <SwipeableViews
              axis={"x"}
              index={this.state.activeStep}
              onChangeIndex={this.handleStepChange}
              enableMouseEvents
            >
              {this.props.jobCandidates[this.props.jobId].candidatos.map(
                (step, k) => (
                  <div className="swipableView" key={k}>
                    <Paper className={classes.paperCandidate} elevation={1}>
                      <Typography align="center" component="p">
                        {candidateAccepted &&
                          candidate.user.gender === "_MAS" && (
                            <ManIcon size={100} />
                          )}
                        {candidateAccepted &&
                          candidate.user.gender === "_FEM" && (
                            <WomanIcon size={100} />
                          )}
                        {!candidateAccepted && <UnknownPersonIcon size={100} />}
                      </Typography>
                      <Typography align="center" component="p">
                        {candidateAccepted ? candidate.user.name : "Candidato"}
                      </Typography>
                      <div className={classes.buttonsContainer}>
                        <div>
                          <Button
                            className={classes.button}
                            style={{
                              minWidth: 0,
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            onClick={() => {
                              favoriteCandidate(
                                this.props.token,
                                this.props.jobId,
                                candidate.user.id
                              ).then(() => this.reloadScreen());
                            }}
                          >
                            {candidate.user.jobs
                              .filter(j => j.favorite)
                              .map(j => j.job.id)
                              .indexOf(this.props.jobId) >= 0 && (
                              <FavoriteIcon />
                            )}
                            {candidate.user.jobs
                              .filter(j => j.favorite)
                              .map(j => j.job.id)
                              .indexOf(this.props.jobId) < 0 && (
                              <FavoriteBorderIcon />
                            )}
                          </Button>
                          <span>Favorito</span>
                        </div>
                        <div>
                          <Button
                            className={classes.button}
                            style={{
                              minWidth: 0,
                              marginTop: 0,
                              marginBottom: 0
                            }}
                            disabled={
                              candidate.user.jobs
                                .filter(j => j.invited)
                                .map(j => j.job.id)
                                .indexOf(this.props.jobId) >= 0
                            }
                            onClick={() => {
                              sendChatInvitation(
                                this.props.token,
                                this.props.jobId,
                                candidate.user.id
                              )
                                .then(r => {
                                  this.reloadScreen();

                                  this.setState({
                                    showSnackbar: true,
                                    snackbarMessage:
                                      "Convite enviado com sucesso!"
                                  });

                                  setTimeout(() => {
                                    this.setState({
                                      showSnackbar: false
                                    });
                                  }, 3000);
                                })
                                .catch(e => {
                                  this.setState({
                                    showSnackbar: true,
                                    snackbarMessage: "Algum erro ocorreu."
                                  });

                                  setTimeout(() => {
                                    this.setState({
                                      showSnackbar: false
                                    });
                                  }, 3000);
                                });
                            }}
                          >
                            <ChatIcon />
                          </Button>
                          <span
                            style={{
                              opacity:
                                candidate.user.jobs
                                  .filter(j => j.invited)
                                  .map(j => j.job.id)
                                  .indexOf(this.props.jobId) >= 0
                                  ? 0.5
                                  : 1
                            }}
                          >
                            Enviar convite para chat
                          </span>
                        </div>
                      </div>
                    </Paper>
                  </div>
                )
              )}
            </SwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              className={classes.mobileStepper}
              nextButton={
                <Button
                  size="small"
                  onClick={this.handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={this.handleBack}
                  disabled={activeStep === 0}
                >
                  <KeyboardArrowLeft />
                </Button>
              }
            />
            <List component="nav" className={classes.criteriaList}>
              {candidate.criterios.map((crit, k) => (
                <div key={k}>
                  <ListItem>
                    <div>
                      <Typography className={classNames(classes.padding)}>
                        {this.capitalizeFirstLetter(crit.idDescricao)}
                      </Typography>
                      <div className={classNames(classes.badge)}>
                        {parseFloat(crit.pontos).toFixed(1)}
                      </div>
                    </div>
                  </ListItem>
                  <Divider />
                </div>
              ))}
            </List>
            <Snackbar
              open={this.state.showSnackbar}
              autoHideDuration={3000}
              ContentProps={{
                "aria-describedby": "snackbar-fab-message-id",
                className: classes.snackbarContent
              }}
              message={
                <span id="snackbar-fab-message-id">
                  {this.state.snackbarMessage}
                </span>
              }
              className={classes.snackbar}
            />
          </div>
        )}
      </div>
    );
  }
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    height: "100vh",
    width: "100vw",
    backgroundColor: "#B3DFDB"
  },
  flex: {
    flex: 1
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing.unit * 4,
    marginBottom: 20,
    backgroundColor: "#B3DFDB"
  },
  swipableView: {
    height: 255,
    maxWidth: "100vw",
    overflow: "hidden",
    width: "100%"
  },
  paperCandidate: {
    ...theme.mixins.gutters(),
    margin: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2
  },
  center: {
    position: "relative",
    display: "block",
    top: "50%",
    transform: "translateY(-50%)",
    textAlign: "center"
  },
  personIcon: {
    width: "3em",
    height: "3em"
  },
  criteriaList: {
    backgroundColor: "#FFFFFF",
    paddingBottom: 0,
    maxHeight: "calc(100vh - 60px - 250px - 40px)",
    overflowY: "scroll"
  },
  buttonsContainer: {},
  button: {
    margin: theme.spacing.unit,
    color: "rgba(0, 0, 0, 1)"
  },
  badge: {
    position: "absolute",
    right: "30px",
    top: "12px",
    borderRadius: "50%",
    background: "#159587",
    padding: "2px",
    fontSize: "10px",
    color: "#FFFFFF",
    height: "15px",
    minWidth: "20px",
    textAlign: "center",
    paddingBottom: "17px"
  },
  padding: {
    padding: `0 ${theme.spacing.unit * 2}px`
  },
  snackbar: {
    position: "absolute"
  },
  snackbarContent: {
    width: 360
  }
});

const mapStateToProps = state => ({
  jobName: state.job.jobName,
  jobId: state.job.jobId,
  token: state.login.token,
  jobCandidates: state.job.jobCandidates
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateJobCandidates
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withStyles(styles)(JobCandidate))
);
