export default theme => ({
  bottomNav: {
    width: "100%",
    height: "80px",
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "#FAFAFA"
  }
});
