// @flow
import React, { Component } from "react";

//Component styles
import { OptionRow, OptionButton } from "./styles";

// Material icons
import { CheckCircleOutline as CheckCircleOutlineIcon } from "@material-ui/icons";

export default class Options extends Component {
  async confirm(e) {
    let next = null;
    if (typeof e.next === "string") next = e.next;
    else if (typeof e.next === "function") next = e.next(e);
    else if (typeof this.props._next === "string") next = this.props._next;
    else if (typeof this.props._next === "function") next = this.props._next(e);

    let value = null;
    if (this.props.evalValue)
      value = await this.props.evalValue(e, this.props.replies);
    else value = e.value ? e.value : e.id;

    let valueKey = this.props.valueKey;
    if (typeof valueKey === "function")
      valueKey = valueKey(e, this.props.replies);

    this.props.doSendUserMessage({
      text: e.text,
      value: value,
      next: next,
      valueKey: valueKey
    });
  }

  render() {
    return (
      <OptionRow>
        {this.props.options &&
          this.props.options
            .filter(option => option.value !== "NA")
            .map((e, key) => {
              return (
                <OptionButton
                  key={key}
                  type="button"
                  onClick={() => this.confirm(e)}
                >
                  {e.text}
                  {e.text === "Entendi" && <CheckCircleOutlineIcon />}
                </OptionButton>
              );
            })}
      </OptionRow>
    );
  }
}
