import {
  white,
  black,
  jobspotPrimary,
  jobspotSecondary
} from "./common/colors";

export default {
  common: {
    black,
    white,
    neutral: "#E4E7EB",
    muted: "#9EA0A4"
  },
  primary: {
    contrastText: white,
    main: "#0062e4",
    light: "#F6F9FD",
    dark: "#0B48A0"
  },
  secondary: {
    contrastText: white,
    main: "#5ec749",
    light: "",
    dark: "#418b33"
  },
  success: {
    contrastText: white,
    main: "#45B880",
    light: "#F1FAF5",
    dark: "#00783E"
  },
  info: {
    contrastText: white,
    main: "#1070CA",
    light: "#F1FBFC",
    dark: "#007489"
  },
  warning: {
    contrastText: white,
    main: "#FFB822",
    light: "#FDF8F3",
    dark: "#95591E"
  },
  danger: {
    contrastText: white,
    main: "#ED4740",
    light: "#FEF6F6",
    dark: "#BF0E08"
  },
  text: {
    primary: "#12161B",
    secondary: "#66788A",
    disabled: "#A6B1BB"
  },
  background: {
    default: "#f1f1f1",
    dark: "#172B4D",
    paper: white,
    login: white,
    topbar: jobspotPrimary,
    active: jobspotSecondary
  },
  border: "#DFE3E8",
  divider: "#DFE3E8"
};
