// @flow
import React, { Component } from "react";

import { withStyles, Fab, TextField } from "@material-ui/core";

// Material icons
import { Send as SendIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

class FreeText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: ""
    };
  }

  async confirm() {
    let value = this.state.value;
    if (this.props.evalValue)
      value = await this.props.evalValue(value, this.props.replies);

    let valueKey = this.props.valueKey;
    if (typeof valueKey === "function")
      valueKey = valueKey(this.state.value, this.props.replies);

    this.props.doSendUserMessage({
      text: this.state.value,
      value: value,
      next: this.props._next,
      valueKey: valueKey
    });
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div className={classes.content}>
        <TextField
          className={classes.inputText}
          variant="outlined"
          autoFocus={true}
          value={value}
          placeholder="Digite"
          onChange={evt => {
            this.setState({
              value: evt.target.value
            });
          }}
        />
        <Fab
          color="primary"
          aria-label="Send"
          className={classes.submitButton}
          onClick={() => this.confirm()}
        >
          <SendIcon />
        </Fab>
      </div>
    );
  }
}

export default withStyles(styles)(FreeText);
