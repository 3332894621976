import styled from "styled-components";

export const Logo = styled.div`
  width: 100%;
  max-width: 280px;
  margin: 15px auto;
  padding-top: 20px;
  img {
    width: 100%;
  }
`;
