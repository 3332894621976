import React, { Component } from "react";

// Externals
import PropTypes from "prop-types";

// Material helpers
import { withStyles } from "@material-ui/core";
import classNames from "classnames";

//Component styles
import styles from "./styles";

class ImportanceRange extends Component {
  render() {
    const { classes, range } = this.props;

    return (
      <span className={classes.content}>
        <span className={classNames(classes.inactive, {
            [classes.first]: range > 0.0
          })}>1</span>
        <span
          className={classNames(classes.inactive, {
            [classes.second]: range > 0.05
          })}
        >
          2
        </span>

        <span
          className={classNames(classes.inactive, {
            [classes.third]: range > 0.2
          })}
        >
          3
        </span>

        <span
          className={classNames(classes.inactive, {
            [classes.fourth]: range > 0.5
          })}
        >
          4
        </span>

        <span
          className={classNames(classes.inactive, {
            [classes.fifth]: range > 0.8
          })}
        >
          5
        </span>
      </span>
    );
  }
}

ImportanceRange.propTypes = {
  range: PropTypes.number,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ImportanceRange);
