// @flow
import { Link } from "@material-ui/core";
import React, { Component, Fragment } from "react";
import { BeatLoader } from "react-spinners";
import Jobin from "../../../../images/jobin.jpg";

export default class Bubble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bubbleState: 1,
      msg: "",
      msgReady: false,
      options: null
    };

    this.timeouts = [];
  }

  componentWillUnmount() {
    this.timeouts.forEach(t => {
      clearTimeout(t);
    });
  }

  componentDidMount() {
    const intvl = this.props.waitTime;

    let timeout1 = setTimeout(() => {
      this.setState({
        bubbleState: 2
      });

      let timeout2 = setTimeout(() => {
        if (typeof this.props.msg === "string") {
          this.setState({
            msg: this.props.msg,
            msgReady: true
          });

          if (this.props.next) {
            this.goToNext();
          } else if (this.props.lastMsg) {
            setTimeout(() => {
              this.props.history.replace("/");
            }, intvl);
          } else {
            this.handleUserActions();
          }
        } else if (typeof this.props.msg === "function") {
          this.props.msg(this.props.replies).then(text => {
            this.setState({
              msg: text,
              msgReady: true
            });

            if (this.props.next) {
              this.goToNext();
            } else {
              this.handleUserActions();
            }
          });
        }
      }, intvl);
      this.timeouts.push(timeout2);
    }, intvl / 2);
    this.timeouts.push(timeout1);
  }

  componentDidUpdate() {
    this.props.mainBottomEl.scrollIntoView({ behavior: "smooth" });
  }

  handleUserActions() {
    this.props.doLoadUserAction();

    if (this.props.options) {
      if (typeof this.props.options === "function") {
        this.props.options().then(data => {
          this.setState({
            options: data
          });
          this.goToNext();
        });
      } else {
        this.setState({
          options: this.props.options
        });
        this.goToNext();
      }
    } else if (this.props.multipleOptions) {
      if (typeof this.props.multipleOptions === "function") {
        this.props.multipleOptions().then(data => {
          this.setState({
            multipleOptions: data
          });
          this.goToNext();
        });
      } else {
        this.setState({
          multipleOptions: this.props.multipleOptions
        });
        this.goToNext();
      }
    } else if (this.props.freeText) {
      this.goToNext();
    } else if (this.props.autocompleteOptions) {
      this.setState({
        autocompleteOptions: this.props.autocompleteOptions
      });
      this.goToNext();
    }
  }

  goToNext() {
    this.setState({
      bubbleState: 3
    });

    if (this.props.next) {
      let next = this.props.next;
      if (next === "function") next = next(this.props);

      this.props.doSendBotMessageId(next);
    } else if (this.state.options) {
      let props = { ...this.props, ...this.state };
      if (typeof props.options === "string")
        props.options = props.additionalData[props.options];

      this.props.doShowUserOptions(props);
    } else if (this.props.multipleOptions) {
      let props = { ...this.props, ...this.state };
      if (typeof props.multipleOptions === "string")
        props.multipleOptions = props.additionalData[props.multipleOptions];

      this.props.doShowUserMultipleOptions(props);
    } else if (this.props.freeText) {
      let props = { ...this.props, ...this.state };
      this.props.doShowUserFreeText(props);
    } else if (this.props.autocompleteOptions) {
      let props = { ...this.props, ...this.state };
      if (typeof props.autocompleteOptions === "string")
        props.autocompleteOptions =
          props.additionalData[props.autocompleteOptions];

      this.props.doShowUserAutocompleteOptions(props);
    }
  }

  render() {
    return (
      <Fragment>
        {this.state.bubbleState === 1 && <div />}
        {this.state.bubbleState > 1 && (
          <div style={this.props.bot ? styles.bubble : styles.userBubble}>
            {this.props.bot && (
              <img src={Jobin} alt="Jobin" style={styles.jobinImg} />
            )}
            <div
              style={
                this.props.bot
                  ? this.props.styles && this.props.styles.text
                    ? { ...styles.text, ...this.props.styles.text }
                    : styles.text
                  : styles.userText
              }
            >
              {!this.state.msgReady && <BeatLoader loading={true} size={2} />}
              {this.state.msgReady && this.state.msg}
              {this.state.msgReady &&
                this.props.links &&
                this.props.links.map(ent => (
                  <Fragment>
                    <Link
                      href={ent.value}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {ent.name}
                    </Link>{" "}
                  </Fragment>
                ))}
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

const styles = {
  bubble: {
    alignItems: "flex-start",
    display: "flex",
    justifyContent: "flex-start"
  },
  userBubble: {
    alignItems: "flex-end",
    display: "flex",
    justifyContent: "flex-end"
  },
  spacer: {
    order: 0,
    padding: 6
  },
  userSpacer: {
    order: 1,
    padding: 6
  },
  text: {
    backgroundColor: "#fff",
    borderRadius: "18px 18px 18px 0",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.15)",
    display: "inline-block",
    maxWidth: "75%",
    padding: 12,
    position: "relative",
    overflow: "hidden",
    margin: "0 0 10px 0",
    fontSize: 14
  },
  userText: {
    backgroundColor: "#1787FB",
    color: "#fff",
    borderRadius: "18px 18px 0px 18px",
    boxShadow: "0 1px 2px 0 rgba(0,0,0,0.15)",
    display: "inline-block",
    maxWidth: "75%",
    padding: 12,
    position: "relative",
    overflow: "hidden",
    margin: "0 0 10px 0",
    fontSize: 14
  },
  jobinImg: {
    width: "32px",
    height: "auto",
    borderRadius: "50%"
  }
};
