import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

//Externals
import compose from "recompose/compose";

// Material helpers
import { withStyles } from "@material-ui/core";

// Material components
import { Grid } from "@material-ui/core";

//Component styles
import styles from "./styles";

function AuthLayout({ classes, children }) {
  return (
    <div className={classes.root}>
      <Grid
        className={classes.grid}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid className={classes.content} item lg={5} xs={12}>
          {children}
        </Grid>
      </Grid>
    </div>
  );
}

AuthLayout.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired
};

export default compose(withRouter, withStyles(styles))(AuthLayout);
