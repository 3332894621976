// @flow
import { replace } from "react-router-redux";
import uuid from "../util/uuid";
import { doGetProfile } from "./user";

export const PROFILE_UPDATE = "profile/PROFILE_UPDATE";
export const PROFILE_TOGGLE_EDITING = "profile/PROFILE_TOGGLE_EDITING";
export const PROFILE_RESTART_EDITING = "profile/PROFILE_RESTART_EDITING";
export const PROFILE_TOGGLE_QUALIFICATION =
  "profile/PROFILE_TOGGLE_QUALIFICATION";
export const PROFILE_ADD_QUALIFICATION_SELECT_COURSE_SITUATION =
  "profile/PROFILE_ADD_QUALIFICATION_SELECT_COURSE_SITUATION";
export const PROFILE_TOGGLE_OCUPATION = "profile/PROFILE_TOGGLE_OCUPATION";
export const PROFILE_TOGGLE_SPECIAL_NEEDS =
  "profile/PROFILE_TOGGLE_SPECIAL_NEEDS";
export const PROFILE_TOGGLE_PLACES_INTERESTS =
  "profile/PROFILE_TOGGLE_PLACES_INTERESTS";
export const PROFILE_TOGGLE_CONTRACTING_MODALITIES =
  "profile/PROFILE_TOGGLE_CONTRACTING_MODALITIES";
export const PROFILE_TOGGLE_SHIFT_PREFERENCES =
  "profile/PROFILE_TOGGLE_SHIFT_PREFERENCES";
export const PROFILE_TOGGLE_SKILLS = "profile/PROFILE_TOGGLE_SKILLS";
export const PROFILE_ADD_QUALIFICATION_SELECT_COURSE_LEVEL =
  "profile/PROFILE_ADD_QUALIFICATION_SELECT_COURSE_LEVEL";
export const PROFILE_ADD_QUALIFICATION_NEW_COURSE =
  "profile/PROFILE_ADD_QUALIFICATION_NEW_COURSE";
export const PROFILE_ADD_QUALIFICATION_SELECT_COURSE =
  "profile/PROFILE_ADD_QUALIFICATION_SELECT_COURSE";
export const PROFILE_QUALIFICATION_SHOW_CONFIRM_DIALOG =
  "profile/PROFILE_QUALIFICATION_SHOW_CONFIRM_DIALOG";
export const PROFILE_QUALIFICATION_HIDE_CONFIRM_DIALOG =
  "profile/PROFILE_QUALIFICATION_HIDE_CONFIRM_DIALOG";
export const PROFILE_ADD_PROFISSAO_SELECT_PROFISSAO =
  "profile/PROFILE_ADD_PROFISSAO_SELECT_PROFISSAO";
export const PROFILE_ADD_PROFISSAO_SELECT_EXPERIENCE =
  "profile/PROFILE_ADD_PROFISSAO_SELECT_EXPERIENCE";
export const PROFILE_ADD_PROFISSAO_SELECT_SEGMENTS =
  "profile/PROFILE_ADD_PROFISSAO_SELECT_SEGMENTS";

const initialState = {
  editing: false,
  qualificationOpen: false,
  ocupationOpen: false,
  specialNeedsOpen: false,
  placesInterestOpen: false,
  contractingModalitiesOpen: false,
  shiftPreferencesOpen: false,
  skillsOpen: false,
  courseLevel: {},
  course: {},
  academicDegree: {},
  profissao: {},
  experiencePeriod: {},
  confirmOpen: false,
  confirmTitle: "",
  confirmText: "",
  confirmKey: "",
  confirmObjId: null,
  profile: null,
  loading: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case PROFILE_UPDATE:
      return {
        ...state,
        profile: action.profile,
        loading: false
      };

    case PROFILE_TOGGLE_EDITING:
      return {
        ...state,
        editing: !state.editing,
        qualificationOpen: !state.editing,
        ocupationOpen: !state.editing,
        specialNeedsOpen: !state.editing,
        placesInterestOpen: !state.editing,
        contractingModalitiesOpen: !state.editing,
        shiftPreferencesOpen: !state.editing,
        skillsOpen: !state.editing
      };

    case PROFILE_RESTART_EDITING:
      return {
        ...state,
        editing: false,
        qualificationOpen: false,
        ocupationOpen: false,
        specialNeedsOpen: false,
        placesInterestOpen: false,
        contractingModalitiesOpen: false,
        shiftPreferencesOpen: false,
        skillsOpen: false
      };

    case PROFILE_TOGGLE_QUALIFICATION:
      return {
        ...state,
        qualificationOpen: !state.qualificationOpen
      };

    case PROFILE_TOGGLE_OCUPATION:
      return {
        ...state,
        ocupationOpen: !state.ocupationOpen
      };

    case PROFILE_TOGGLE_SPECIAL_NEEDS:
      return {
        ...state,
        specialNeedsOpen: !state.specialNeedsOpen
      };

    case PROFILE_TOGGLE_PLACES_INTERESTS:
      return {
        ...state,
        placesInterestOpen: !state.placesInterestOpen
      };

    case PROFILE_TOGGLE_CONTRACTING_MODALITIES:
      return {
        ...state,
        contractingModalitiesOpen: !state.contractingModalitiesOpen
      };

    case PROFILE_TOGGLE_SHIFT_PREFERENCES:
      return {
        ...state,
        shiftPreferencesOpen: !state.shiftPreferencesOpen
      };

    case PROFILE_TOGGLE_SKILLS:
      return {
        ...state,
        skillsOpen: !state.skillsOpen
      };

    case PROFILE_ADD_QUALIFICATION_SELECT_COURSE_LEVEL:
      return {
        ...state,
        courseLevel: action.courseLevel
      };

    case PROFILE_ADD_QUALIFICATION_SELECT_COURSE:
      return {
        ...state,
        course: action.course
      };

    case PROFILE_ADD_QUALIFICATION_SELECT_COURSE_SITUATION:
      return {
        ...state,
        courseLevel: {},
        course: {},
        academicDegree: {}
      };

    case PROFILE_QUALIFICATION_SHOW_CONFIRM_DIALOG:
      return {
        ...state,
        confirmOpen: true,
        confirmTitle: action.confirmTitle,
        confirmText: action.confirmText,
        confirmKey: action.confirmKey,
        confirmObjId: action.confirmObjId
      };

    case PROFILE_QUALIFICATION_HIDE_CONFIRM_DIALOG:
      return {
        ...state,
        confirmOpen: false,
        confirmTitle: "",
        confirmText: "",
        confirmKey: "",
        confirmObjId: ""
      };

    case PROFILE_ADD_PROFISSAO_SELECT_PROFISSAO:
      return {
        ...state,
        profissao: action.profissao
      };

    case PROFILE_ADD_PROFISSAO_SELECT_EXPERIENCE:
      return {
        ...state,
        experiencePeriod: action.experiencePeriod
      };

    case PROFILE_ADD_PROFISSAO_SELECT_SEGMENTS: {
      return {
        ...state,
        profissao: {},
        experiencePeriod: {}
      };
    }

    default:
      return state;
  }
};

export const doUpdateProfile = p => {
  return dispatch => {
    if (p.educations) {
      p.educations.map(e => {
        e.uuid = uuid();
        return e;
      });
    }

    if (p.ocupations) {
      p.ocupations.map(o => {
        o.uuid = uuid();
        return o;
      });
    }

    dispatch({
      type: PROFILE_UPDATE,
      profile: p
    });
  };
};

export const forceReloadProfile = token => {
  return dispatch => {
    doGetProfile(token)
      .then(p => {
        if (p.educations) {
          p.educations.map(e => {
            e.uuid = uuid();
            return e;
          });
        }

        if (p.ocupations) {
          p.ocupations.map(o => {
            o.uuid = uuid();
            return o;
          });
        }

        dispatch({
          type: PROFILE_UPDATE,
          profile: p
        });
      })
      .catch(error => {
        console.error(error);
      });
  };
};

export const doConfirmCourseLevel = courseLevel => {
  return dispatch => {
    dispatch({
      type: PROFILE_ADD_QUALIFICATION_SELECT_COURSE_LEVEL,
      courseLevel: courseLevel
    });

    // let nextSelection = null;
    // if (
    //   courseLevel.value === "ENSINO_FUNDAMENTAL" ||
    //   courseLevel.value === "ENSINO_MEDIO"
    // ) {
    //   nextSelection = "/profile-add-qualificacao-select-course-situation";
    // } else {
    //   nextSelection = "/profile-add-qualificacao-select-course";
    // }

    // dispatch(replace(nextSelection));
  };
};

export const doAddNewCourse = course => {
  return (dispatch, getState) => {
    dispatch({
      type: PROFILE_ADD_QUALIFICATION_NEW_COURSE,
      course: course
    });

    let nextSelection = null;
    if (course.academicDegrees) {
      nextSelection = "/profile-add-qualificacao-select-course-academic-degree";
    } else {
      nextSelection = "/profile-add-qualificacao-select-course-situation";
    }

    dispatch(replace(nextSelection));
  };
};

export const doConfirmCourse = course => {
  return dispatch => {
    dispatch({
      type: PROFILE_ADD_QUALIFICATION_SELECT_COURSE,
      course: course
    });

    // let nextSelection = null;
    // if (course.academicDegrees) {
    //   nextSelection =
    //     "/profile-add-qualificacao-select-qualificacao-academic-degree";
    // } else {
    //   nextSelection = "/profile-add-qualificacao-select-course-situation";
    // }

    // dispatch(replace(nextSelection));
  };
};

export const doConfirmCourseSituation = situation => {
  return (dispatch, getState) => {
    const courseLevel = getState().profile.courseLevel;
    const course = getState().profile.course;
    const academicDegree = getState().profile.academicDegree;

    dispatch({
      type: PROFILE_ADD_QUALIFICATION_SELECT_COURSE_SITUATION
    });

    const userData = getState().profile.profile;
    userData.educations.push({
      uuid: uuid(),
      course: course.id ? course : null,
      educationLevel: courseLevel.id ? courseLevel : null,
      academicDegree: academicDegree.id ? academicDegree : null,
      situation: situation
    });

    dispatch({
      type: PROFILE_UPDATE,
      profile: userData
    });

    // dispatch(replace("/profile"));
  };
};

export const doConfirmCourseAcademicDegree = (academicDegree, situation) => {
  return (dispatch, getState) => {
    const courseLevel = getState().profile.courseLevel;
    const course = getState().profile.course;

    if (academicDegree && situation) {
      dispatch({
        type: PROFILE_ADD_QUALIFICATION_SELECT_COURSE_SITUATION
      });

      const userData = getState().profile.profile;
      userData.educations.push({
        uuid: uuid(),
        course: course.id ? course : null,
        educationLevel: courseLevel.id ? courseLevel : null,
        academicDegree: academicDegree,
        situation: situation
      });

      dispatch({
        type: PROFILE_UPDATE,
        profile: userData
      });
    }

    dispatch(replace("/profile"));
  };
};

export const doRemoveQualification = uuid => {
  return (dispatch, getState) => {
    const userData = getState().profile.profile;
    userData.educations = userData.educations.filter(el => el.uuid !== uuid);

    dispatch({
      type: PROFILE_UPDATE,
      profile: userData
    });

    dispatch({
      type: PROFILE_QUALIFICATION_HIDE_CONFIRM_DIALOG
    });
  };
};

export const doConfirmProfissao = profissao => {
  return (dispatch, getState) => {
    dispatch({
      type: PROFILE_ADD_PROFISSAO_SELECT_PROFISSAO,
      profissao: profissao
    });

    // let nextSelection = "/profile-add-profissao-select-experience";
    // dispatch(replace(nextSelection));
  };
};

export const doAddNewProfissao = profissao => {
  return (dispatch, getState) => {
    dispatch({
      type: PROFILE_ADD_PROFISSAO_SELECT_PROFISSAO,
      profissao: profissao
    });

    let nextSelection = "/profile-add-profissao-select-experience";
    dispatch(replace(nextSelection));
  };
};

export const doConfirmExperiencePeriod = experiencePeriod => {
  return (dispatch, getState) => {
    dispatch({
      type: PROFILE_ADD_PROFISSAO_SELECT_EXPERIENCE,
      experiencePeriod: experiencePeriod
    });

    // dispatch(replace("/profile-add-profissao-select-segment"));
  };
};

export const doConfirmExperienceSegments = segments => {
  return (dispatch, getState) => {
    const experiencePeriod = getState().profile.experiencePeriod;
    const profissao = getState().profile.profissao;

    if (experiencePeriod && profissao) {
      dispatch({
        type: PROFILE_ADD_PROFISSAO_SELECT_SEGMENTS
      });

      const userData = getState().profile.profile;
      userData.ocupations.push({
        uuid: uuid(),
        ocupation: profissao,
        experiencePeriod: experiencePeriod.id ? experiencePeriod : null,
        segments: segments ? segments : null
      });

      dispatch({
        type: PROFILE_UPDATE,
        profile: userData
      });

      //window.location.reload();
    }
    //dispatch(replace("/profile"));
  };
};

export const doRemoveOcupation = uuid => {
  return (dispatch, getState) => {
    const userData = getState().profile.profile;
    userData.ocupations = userData.ocupations.filter(el => el.uuid !== uuid);

    dispatch({
      type: PROFILE_UPDATE,
      profile: userData
    });

    dispatch({
      type: PROFILE_QUALIFICATION_HIDE_CONFIRM_DIALOG
    });
  };
};

export const restartEditing = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_RESTART_EDITING
    });
  };
};

export const toggleEditing = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_EDITING
    });
  };
};

export const toggleQualification = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_QUALIFICATION
    });
  };
};

export const toggleOcupation = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_OCUPATION
    });
  };
};

export const toggleSpecialNeeds = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_SPECIAL_NEEDS
    });
  };
};

export const togglePlacesInterests = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_PLACES_INTERESTS
    });
  };
};

export const toggleContractingModalities = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_CONTRACTING_MODALITIES
    });
  };
};

export const toggleShiftPreferences = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_SHIFT_PREFERENCES
    });
  };
};

export const toggleSkills = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_TOGGLE_SKILLS
    });
  };
};

export const showConfirmDialog = (title, text, key, id) => {
  return dispatch => {
    dispatch({
      type: PROFILE_QUALIFICATION_SHOW_CONFIRM_DIALOG,
      confirmTitle: title,
      confirmText: text,
      confirmKey: key,
      confirmObjId: id
    });
  };
};

export const hideConfirmDialog = () => {
  return dispatch => {
    dispatch({
      type: PROFILE_QUALIFICATION_HIDE_CONFIRM_DIALOG
    });
  };
};
