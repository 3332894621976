const avatarSize = "55px";
export default theme => ({
  root: {},
  personaContent: {
    margin: "10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    maxWidth: 600
  },
  avatar: {
    width: avatarSize,
    height: avatarSize,
    backgroundColor: "#fff",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)"
  },
  personaTextContent: {
    backgroundColor: "#fff",
    borderRadius: "0px 12px 12px 12px",
    padding: "6px 12px",
    marginLeft: "10px",
    boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
    "& p": {
      margin: "0px",
      fontSize: "12px"
    }
  }
});
