// @flow
import { replace } from "react-router-redux";
import i18n from "../util/i18n";

import { LOAD_DATA_USER, doLoadUser } from "../modules/form-data";

import loginRedirects from "../util/login-redirects";

export const LOGIN_REQUESTED = "login/LOGIN_REQUESTED";
export const LOGIN_SUCCESSFULL = "login/LOGIN_SUCCESSFULL";
export const LOGIN_FAILED = "login/LOGIN_FAILED";
export const LOGIN_RESET = "login/LOGIN_RESET";
export const LOGIN_UPDATE_DATA = "login/LOGIN_UPDATE_DATA";
export const CLEAR_LOGIN_MESSAGE = "login/CLEAR_LOGIN_MESSAGE";

const initialState = {
  loggedIn: false,
  isLoggingIn: false,
  phone: "",
  password: "",
  token: "",
  cloudMessagingToken: "",
  message: "",
  showMessage: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_UPDATE_DATA:
      return {
        ...state,
        ...action.data
      };

    case LOGIN_REQUESTED:
      return {
        ...state,
        isLoggingIn: true,
        showMessage: false
      };
    
    case LOGIN_RESET:
      return {
        ...state,
        isLoggingIn: false,
        showMessage: false
      }

    case LOGIN_SUCCESSFULL:
      return {
        ...state,
        isLoggingIn: false,
        loggedIn: true,
        token: action.token,
        showMessage: false
      };

    case LOGIN_FAILED:
      return {
        ...state,
        isLoggingIn: false,
        loggedIn: false,
        showMessage: true,
        message: i18n.t("invalidLogin")
      };

    case CLEAR_LOGIN_MESSAGE:
      return {
        ...state,
        isLoggingIn: false,
        loggedIn: false,
        showMessage: false,
        message: ""
      };

    default:
      return state;
  }
};

export const doUpdateData = data => {
  return dispatch => {
    dispatch({
      type: LOGIN_UPDATE_DATA,
      data: data
    });
  };
};

export const getUrlAccessData = (token) => {

    const url = `${process.env.REACT_APP_SERVER_URL}/whatsapp-bot/urlaccess/${token}`;

    const result = fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }).then((result) => {
      if (result.ok) {
        result.json().then(result => {
          return result;
        });
      } else {
        return "error!";
      }
    })
    .catch((error) => {
      console.log('Something happened! Error: ', error);
    });

    return result;
};

export const doLogin = (redirect, paramPhone, paramPassword) => {
  return (dispatch, getState) => {
    dispatch({
      type: LOGIN_REQUESTED
    });

    var url = process.env.REACT_APP_SERVER_URL + "/login";

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        phone: paramPhone ? paramPhone : getState().login.phone,
        password: paramPassword ? paramPassword : getState().login.password
      })
    })
      .then(function(result) {
        if (result.ok) {
          result.json().then(result => {
            doLoadUser(result.token).then(userData => {
              const redirectTo = { url: '/' };

              if (redirect && loginRedirects.includes(redirect)) {
                redirectTo.url = redirect;
              }

              if (userData.userType === 'COMPANY') {
                redirectTo.url = '/jobs';
              }

              if (userData.userType === 'PERSON') {
                if (userData.initialized) {
                  redirectTo.url = '/profile';
                } else {
                  redirectTo.url = '/find-bot';
                }
              }

              dispatch({
                type: LOAD_DATA_USER,
                user: userData
              });

              dispatch({
                type: LOGIN_SUCCESSFULL,
                token: result.token
              });

              dispatch(
                replace(redirectTo.url)
              );
            });
          });
        } else {
          dispatch({
            type: LOGIN_FAILED
          });
        }
      })
      .catch(function() {
        dispatch({
          type: LOGIN_FAILED
        });
      });
  };
};

export const doLoginReset = () => {
  return (dispatch, getState) => {
    dispatch({
      type: LOGIN_RESET
    });
  }
}

export const doClearLoginMessage = () => {
  return (dispatch, getState) => {
    dispatch({
      type: CLEAR_LOGIN_MESSAGE
    });
  };
};
