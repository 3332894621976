// Material helpers
import { Divider, Typography, withStyles } from "@material-ui/core";
import classNames from "classnames";
// Externals
import PropTypes from "prop-types";
import React, { Component } from "react";
//Component styles
import styles from "./styles"

import i18n from "../../../util/i18n";

class Footer extends Component {
  render() {
    const { classes, className } = this.props;
    const rootClassName = classNames(classes.root, className);

    return (
      <div className={rootClassName}>
        <Divider />
        <Typography className={classes.company} variant="body1">
          &copy; JobSpot {new Date().getFullYear()}
        </Typography>
        <Typography variant="caption">{ i18n.t("allRightsReserved") }</Typography>
      </div>
    );
  }
}

Footer.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Footer);
