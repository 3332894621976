// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import {
  withStyles,
  withMobileDialog,
  AppBar,
  Toolbar,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Slide,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress
} from "@material-ui/core";

import Persona from "../../../../components/Persona";

// Material icons
import { Search as SearchIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class AutoComplete extends Component {
  constructor(props) {
    super(props);

    this.mainDiv = React.createRef();
    //this.inputField = React.createRef();

    this.state = {
      search: "",
      data: [],
      openDialog: false,
      loadingSearch: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ openDialog: true });
      this.inputField.focus();
    }, 3000);
  }

  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  async confirm(e) {
    let next = null;
    if (e && typeof e.next === "string") next = e.next;
    else if (e && typeof e.next === "function") next = e.next(e);
    else if (typeof this.props._next === "string") next = this.props._next;
    else if (typeof this.props._next === "function")
      next = await this.props._next(e);

    if (typeof next === "object") {
      this.props.doRegisterAdditionalData(next.additionalData);

      next = next.id;
    }

    let selectedText = e
      ? this.capitalizeFirstLetter(e.text)
      : this.capitalizeFirstLetter(this.state.search);

    let value = null;
    if (this.props.evalValue)
      value = await this.props.evalValue(e, this.props.replies);
    else if (e.value) value = e.value;
    else if (e.id) value = e.id;
    else value = this.state.search;

    let valueKey = this.props.valueKey;
    if (typeof valueKey === "function")
      valueKey = valueKey(e, this.props.replies);

    this.setState({
      search: "",
      data: [],
      openDialog: false
    });

    this.props.doSendUserMessage({
      text: selectedText,
      value: value,
      next: next,
      valueKey: valueKey
    });
  }

  handleDialogClose = () => {
    this.setState({ openDialog: false });
  };

  handleDoSearch = search => {
    if (search.length < 3 && this.state.data.length > 0) {
      this.setState({
        data: [],
        search: search
      });
    } else if (search.length >= 3) {
      this.setState({ loadingSearch: true });
      this.doSearch(search);
    } else {
      this.setState({
        search: search
      });
    }
  };

  doSearch = search => {
    let opts = [];
    this.props.doLoadUserAction();

    if (typeof this.props.autocompleteOptions === "function") {
      this.props.autocompleteOptions(search).then(data => {
        this.props.doUnloadUserAction();

        this.setState({
          data: data,
          search: search,
          loadingSearch: false
        });
      });
    } else {
      for (let opt of this.props.autocompleteOptions) {
        if (opt.text.toUpperCase().includes(search.toUpperCase())) {
          opts.push(opt);
        }
      }

      this.props.doUnloadUserAction();

      this.setState({
        data: opts,
        search: search,
        loadingSearch: false
      });
    }
  };

  render() {
    const { classes, fullScreen, conversation } = this.props;
    const { openDialog, data, loadingSearch, search } = this.state;
    const title = conversation[conversation.length - 1].msg;

    return (
      <Fragment>
        {!openDialog && <CircularProgress color="secondary" />}
        <Dialog
          TransitionComponent={Transition}
          fullScreen={fullScreen}
          open={openDialog}
          onClose={this.handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <AppBar position="static">
            <Toolbar>
              <Typography
                variant="h6"
                color="inherit"
                className={classes.title}
              >
                {title}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogTitle id="responsive-dialog-title" className={classes.header}>
            <SearchIcon className={classes.searchIcon} />
            <input
              type="text"
              ref={inputElement => {
                this.inputField = inputElement;
              }}
              placeholder="Digite"
              className={classes.inputSearch}
              onChange={evt => {
                this.handleDoSearch(evt.target.value);
              }}
            />
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <List>
              {loadingSearch && <CircularProgress color="secondary" />}
              {data && data.length > 0 ? (
                data.map((e, key) => {
                  
                  if (e.id) {
                  return (
                    <Fragment key={key}>
                      <ListItem
                        component="li"
                        button
                        onClick={() => {
                          this.confirm(e);
                        }}
                      >
                        <ListItemText
                          primary={this.capitalizeFirstLetter(e.text)}
                        />
                      </ListItem>
                      <Divider component="li" />
                    </Fragment>
                  );
                      } else {
                        return (
                          <ListItem component="li">
                          <Persona
                            text={`Não encontrei nenhum resultado com esses termos... Tente buscar novamente.`}
                          />
                        </ListItem>
                        );
                      }
                })
              ) : (
                <ListItem component="li">
                  {search.length > 0 && (
                    <ListItemText
                      primary={`Nada foi encontrado!`}
                      className={classes.notFoundText}
                    />
                  )}
                </ListItem>
              )}
            </List>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

AutoComplete.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withStyles(styles)(withMobileDialog()(AutoComplete));
