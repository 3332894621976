// @flow
import moment from "moment";

import { LOAD_DATA_CONVERSATION, INITIALIZE_USER_JSON } from "./form-data";
import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_FROM_SERVER,
  doSaveProfile
} from "./user";

export const BOT_MSG = "findBot/BOT_MSG";
export const USER_MSG = "findBot/USER_MSG";
export const BOT_MSG_OBJECT = "findBot/BOT_MSG_OBJECT";
export const USER_MSG_OBJECT = "findBot/USER_MSG_OBJECT";
export const USER_OPTIONS = "findBot/USER_OPTIONS";
export const USER_MSG_TEXT = "findBot/USER_MSG_TEXT";
export const USER_MULTIPLE_OPTIONS = "findBot/USER_MULTIPLE_OPTIONS";
export const USER_FREE_TEXT = "findBot/USER_FREE_TEXT";
export const USER_SUB_LEVEL_OPTIONS = "findBot/USER_SUB_LEVEL_OPTIONS";
export const USER_AUTO_COMPLETE_OPTIONS = "findBot/USER_AUTO_COMPLETE_OPTIONS";
export const ADDITIONAL_DATA = "findBot/ADDITIONAL_DATA";
export const REGISTER_REPLY = "findBot/REGISTER_REPLY";
export const LOAD_USER_ACTION = "findBot/LOAD_USER_ACTION";
export const UNLOAD_USER_ACTION = "findBot/UNLOAD_USER_ACTION";
export const RESET_CONVERSATION = "findBot/RESET_CONVERSATION";

const initialState = {
  lastMsg: false,
  conversation: [],
  replies: [],
  options: null,
  autocompleteOptions: null,
  multipleOptions: null,
  freeText: false,
  _next: null,
  loadingUserAction: false,
  additionalData: {
    academicDegrees: []
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case RESET_CONVERSATION:
      return {
        ...state,
        conversation: [],
        _next: null
      };

    case ADDITIONAL_DATA:
      return {
        ...state,
        additionalData: { ...state.additionalData, ...action.data }
      };

    case REGISTER_REPLY:
      return {
        ...state,
        replies: [...state.replies, action.reply]
      };

    case USER_FREE_TEXT:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: null,
        _next: action._next,
        freeText: action.freeText,
        valueKey: action.valueKey,
        evalValue: action.evalValue,
        loadingUserAction: false
      };

    case USER_OPTIONS:
      return {
        ...state,
        options: action.options,
        autocompleteOptions: null,
        multipleOptions: null,
        _next: action._next,
        valueKey: action.valueKey,
        evalValue: action.evalValue,
        freeText: false,
        loadingUserAction: false
      };

    case USER_MULTIPLE_OPTIONS:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: action.multipleOptions,
        maxSelected: action.maxSelected,
        _next: action._next,
        freeText: false,
        valueKey: action.valueKey,
        evalValue: action.evalValue,
        loadingUserAction: false
      };

    case USER_SUB_LEVEL_OPTIONS:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: null,
        _next: action._next,
        freeText: false,
        valueKey: action.valueKey,
        evalValue: action.evalValue,
        loadingUserAction: false
      };

    case USER_AUTO_COMPLETE_OPTIONS:
      return {
        ...state,
        options: null,
        autocompleteOptions: action.autocompleteOptions,
        multipleOptions: null,
        _next: action._next,
        freeText: false,
        valueKey: action.valueKey,
        evalValue: action.evalValue,
        loadingUserAction: false
      };

    case BOT_MSG:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: null,
        conversation: [...state.conversation, action.msgObj],
        freeText: false,
        loadingUserAction: false
      };

    case USER_MSG:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: null,
        conversation: [...state.conversation, action.msgObj],
        _next: null,
        freeText: false,
        loadingUserAction: false
      };

    case BOT_MSG_OBJECT:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: null,
        conversation: [...state.conversation, action.msgObj],
        _next: null,
        freeText: false,
        loadingUserAction: false,
        lastMsg: action.msgObj.lastMsg
      };

    case USER_MSG_OBJECT:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: null,
        conversation: [...state.conversation, action.msgObj],
        freeText: false,
        loadingUserAction: false
      };

    case USER_MSG_TEXT:
      return {
        ...state,
        options: null,
        autocompleteOptions: null,
        multipleOptions: null,
        conversation: [...state.conversation, action.msg],
        freeText: false,
        loadingUserAction: false
      };

    case LOAD_USER_ACTION:
      return {
        ...state,
        loadingUserAction: true
      };

    case UNLOAD_USER_ACTION:
      return {
        ...state,
        loadingUserAction: false
      };

    default:
      return state;
  }
};

export const resetConversation = () => {
  return dispatch => {
    dispatch({
      type: RESET_CONVERSATION
    });
  };
};

export const getRandomInt = max => {
  let nr = Math.floor(Math.random() * Math.floor(max));
  if (nr === 0) nr = 1;
  return nr;
};

export const doLoadUserAction = () => {
  return (dispatch, getState) => {
    dispatch({
      type: LOAD_USER_ACTION
    });
  };
};

export const doUnloadUserAction = () => {
  return (dispatch, getState) => {
    dispatch({
      type: UNLOAD_USER_ACTION
    });
  };
};

export const doLoadFullConversation = () => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      dispatch({
        data: getState(),
        type: LOAD_DATA_CONVERSATION
      });

      resolve();
    });
  };
};

export const doSendBotMessageId = (msgId, selectedOpt) => {
  return (dispatch, getState) => {
    let msg = getState().formData.fullConversation.find(m => {
      return m.id === msgId;
    });

    dispatch({
      type: BOT_MSG_OBJECT,
      msgObj: msg
    });
  };
};

export const doSendBotMessage = msg => {
  return (dispatch, getState) => {
    dispatch({
      type: BOT_MSG_OBJECT,
      msgObj: msg
    });
  };
};

export const doRegisterAdditionalData = data => {
  return (dispatch, getState) => {
    dispatch({
      type: ADDITIONAL_DATA,
      data: data
    });
  };
};

export const doSendUserMessage = msg => {
  return (dispatch, getState) => {
    dispatch({
      type: REGISTER_REPLY,
      reply: msg
    });

    let userState = getState().user.profile;

    if (msg.next === "msg-end") {
      dispatch({
        type: UPDATE_PROFILE,
        data: userState
      });
  
      const token = getState().login.token;
  
      const userData = getState().formData.user;

      if (userData.dateOfBirth.substr(6).length === 2) {
        const date = moment(userData.dateOfBirth.substr(6), "YY")
        const year = date.format("YYYY");
        userState.Perfil.ANONASC = year;

        const splitDate = userData.dateOfBirth.split("/");
        const newDate = `${splitDate[0]}/${splitDate[1]}/${year}`;
        userState.dataNascimento = newDate;

      } else {
        userState.Perfil.ANONASC = userData.dateOfBirth.substr(6);
        userState.dataNascimento = userData.dateOfBirth;
      }

      userState.idPessoa = userData.id;
      userState.nome = userData.name;
      userState.Perfil.SEXO = userData.gender;
      userState.Perfil.LOCALIDADE.IDCIDADE = userData.city.id;
      userState.Perfil.LOCALIDADE.IDUF = userData.state.id;
      userState.Perfil.LOCALIDADE.COORDENADAS = [
        userData.city.latitude,
        userData.city.longitude
      ];
  
      let userInstruction = userState.Perfil.QUALIFICACAO._INSTRUCAO;
      let userFinalInstruction = [];
      userInstruction.forEach(q => {
        userFinalInstruction.push(q);
      });
      userState.Perfil.QUALIFICACAO._INSTRUCAO = userFinalInstruction;
  
      let userProfessions = userState.Perfil.PROFISSAO._PROFISSOES;
      let userFinalProfession = [];
      userProfessions.forEach(p => {
        userFinalProfession.push(p);
      });
      userState.Perfil.PROFISSAO._PROFISSOES = userFinalProfession;
  
      doSaveProfile(userState, token).then(result => {
        dispatch({
          type: UPDATE_PROFILE_FROM_SERVER,
          data: result
        });
        
        dispatch({
          type: INITIALIZE_USER_JSON
        });
      });
    }

    if (typeof msg.valueKey === "object" && msg.value !== null) {
      
      const last = msg.valueKey.slice(-1)[0];
      let obj = msg.valueKey.reduce((accumulator, currentValue) => {
        if (accumulator[currentValue] === undefined)
          accumulator[currentValue] = {};

        if (currentValue === last) return accumulator;

        return accumulator[currentValue];
      }, userState.Perfil);

      if (Array.isArray(msg.value)) obj[last] = msg.value;
      else obj[last].push(msg.value);
    }

    dispatch({
      type: USER_MSG_TEXT,
      msg: {
        id: "user" + getState().formData.fullConversation.length,
        user: true,
        msg: msg.text,
        next: msg.next
      }
    });
  };
};

export const doShowUserOptions = msg => {
  return (dispatch, getState) => {
    dispatch({
      type: USER_OPTIONS,
      options: msg.options,
      _next: msg._next,
      valueKey: msg.valueKey,
      evalValue: msg.evalValue
    });
  };
};

export const doShowUserMultipleOptions = msg => {
  return (dispatch, getState) => {
    dispatch({
      type: USER_MULTIPLE_OPTIONS,
      multipleOptions: msg.multipleOptions,
      maxSelected: msg.maxSelected,
      _next: msg._next,
      valueKey: msg.valueKey,
      evalValue: msg.evalValue
    });
  };
};

export const doShowUserFreeText = msg => {
  return (dispatch, getState) => {
    dispatch({
      type: USER_FREE_TEXT,
      freeText: msg.freeText,
      _next: msg._next,
      valueKey: msg.valueKey,
      evalValue: msg.evalValue
    });
  };
};

export const doShowUserAutocompleteOptions = msg => {
  return (dispatch, getState) => {
    dispatch({
      type: USER_AUTO_COMPLETE_OPTIONS,
      autocompleteOptions: msg.autocompleteOptions,
      _next: msg._next,
      valueKey: msg.valueKey,
      evalValue: msg.evalValue
    });
  };
};

export const doShowUserSubLevelOptions = msg => {
  return (dispatch, getState) => {
    dispatch({
      type: USER_SUB_LEVEL_OPTIONS,
      subLevelOptions: msg.subLevelOptions,
      _next: msg._next,
      valueKey: msg.valueKey,
      evalValue: msg.evalValue
    });
  };
};

export const doHandleSubLevelOption = msg => {
  return (dispatch, getState) => {};
};
