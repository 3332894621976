// @flow
const cache = [];

export const getCacheData = url => {
  return cache[url];
};

export const putCacheData = (url, data) => {
  cache[url] = data;
};
