import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    justifyContent: "space-evenly"
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  }
}));

export default function IconBreadcrumbs({ colorHomeIcon, colorMarkChatReadIcon, colorSentimentVerySatisfiedIcon, colorCheckCircleOutlineIcon }) {
  const classes = useStyles();

  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginLeft: "25px", justifyContent: "space-evenly", display: "flex" }}>
      <Link
        className={classes.link}
      >
        <HomeIcon className={classes.icon} style={{ color: colorHomeIcon }} />

      </Link>
      <Link
        className={classes.link}
      >
        <MarkChatReadIcon className={classes.icon} style={{ color: colorMarkChatReadIcon }} />
      </Link>
      <Link
        className={classes.link}
      >
        <SentimentVerySatisfiedIcon className={classes.icon} style={{ color: colorSentimentVerySatisfiedIcon }} />

      </Link>
    </Breadcrumbs>
  );
}
