// @flow
import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { doCreateAccount, doUpdateData } from "../../modules/create-account";
import { doLoadStates } from "../../modules/form-data";

import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";

import { ContainerJobSpot, Logo, ContainerBox, Buttons } from "./styles";

class CreateAccountType extends Component {
  componentDidMount() {
    if (this.props.states.length === 0) {
      this.props.doLoadStates();
    }
  }

  render() {
    const { history } = this.props;

    return (
      <Fragment>
        <ContainerJobSpot component="main" maxWidth="xs">
          <ContainerBox
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <IconButton
              onClick={() => history.replace("/create-account")}
              color="inherit"
              aria-label="Menu"
            >
              <Icon>keyboard_arrow_left</Icon>
            </IconButton>
            <Logo>
              <img src={require("../../images/logo.png")} alt="JobSpot Logo" />
            </Logo>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  style={{ marginTop: 25 }}
                >
                  Bem-vindo! O que você busca nesse momento?
                </Typography>
              </Grid>

              <Buttons item xs={12}>
                <Button
                  style={{ minWidth: 160, maxWidth: 165 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.replace("/find-bot");
                  }}
                >
                  Busco Trabalho
                </Button>
                <Button
                  style={{ minWidth: 160, maxWidth: 165 }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    history.replace("/jobs");
                  }}
                >
                  Busco Trabalhador
                </Button>
              </Buttons>
            </Grid>
          </ContainerBox>
        </ContainerJobSpot>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  phone: state.createAccount.phone,
  phoneConfirmation: state.createAccount.phoneConfirmation,
  password: state.createAccount.password,
  passwordConfirmation: state.createAccount.passwordConfirmation,
  isCreating: state.createAccount.isCreating,
  message: state.createAccount.message,
  showMessage: state.createAccount.showMessage,
  states: state.formData.states
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doCreateAccount,
      doUpdateData,
      doLoadStates
    },
    dispatch
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAccountType)
);
