// @flow
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  Switch,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
// Material icons
import {
  Add as AddIcon,
  Close as CloseIcon,
  Edit as EditIcon
} from "@material-ui/icons";
import React, { Component, Fragment } from "react";
import Persona from "../../../../components/Persona";
//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DialogEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: true,
      selectedOption: ""
    };
  }

  componentDidMount = () => {
    this.setState({ selectedOption: this.props.grade })
  }

  isRequired = () => {
    return ["Qualificação", "Profissão", "Localidade"].includes(
      this.props.cardTitle
    );
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleClose = () => {
    this.setState({ openDialog: false });
    this.props.actionClose();
  };

  handleActiveCard = () => {
    this.props.toggleCheckboxFn();

    if (this.props.jobId && this.props.persistJobFn) {
      this.props.persistJobFn();
    }
  };

  handleChangeOption = event => {
    const item = event.target.value;
    if (this.props.editQualificacao) {
      this.props.selectGradeFn(this.props.editQualificacaoItem, item);
    } else {
      if (this.props.active && this.props.selectGradeFn) {
        this.props.selectGradeFn(item);
      }

      //if (this.props.active && this.props.jobId && this.props.persistJobFn) {
        //this.props.persistJobFn();
      //}
    }
    this.setState({ selectedOption: item });
  };

  handleActiveSubItems = item => {};

  render() {
    const { classes, cardTitle, active, grade } = this.props;
    const { openDialog, selectedOption } = this.state;

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
        style={{ minWidth: "350px" }}
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={() => this.handleClose()}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {cardTitle}
            </Typography>
            <Button color="inherit" onClick={() => this.handleClose()}>
              Confirmar
            </Button>
          </Toolbar>
        </AppBar>

        {!this.isRequired() && (
          <DialogTitle id="responsive-dialog-title" className={classes.header}>
            <p>Ativar critério</p>
            <Switch
              color="secondary"
              checked={active}
              onChange={() => this.handleActiveCard()}
            />
          </DialogTitle>
        )}

        <DialogContent className={classes.dialogContent}>
          <h3>{cardTitle}</h3>
          {cardTitle !== "Profissão" && (
            <Fragment>
              <small>
                Selecione o nível de importância do critério{" "}
                {cardTitle.toLowerCase()}
              </small>
              <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
            </Fragment>
          )}

          {cardTitle === "Profissão" && (
            <Persona
              text={`Defina o grau de importância desse item em relação aos demais itens do perfil. Você está no controle: o que é mais importante, o que é desejável, o que é complementar.`}
            />
          )}

          <RadioGroup
            aria-label="position"
            name="position"
            value={selectedOption}
            onChange={this.handleChangeOption}
            row
            className={classes.radioGroup}
          >
            {this.props.importanceRange.map((v, k) => (
              <FormControlLabel
                key={k}
                value={Number(v.value)}
                control={<Radio color="primary" />}
                label={v.text}
                labelPlacement="top"
                className={classes.label}
                readOnly={!active}
                checked={Number(v.value) === Number(grade)}
              />
            ))}
          </RadioGroup>

          <div className={classes.buttonGroup}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.actionCard()}
              style={{ margin: "15px 5px", display: "flex" }}
            >
              <EditIcon />
              Editar
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => this.props.actionCard()}
              style={{ margin: "15px 5px", display: "flex" }}
            >
              <AddIcon />
              Nova
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

export default withStyles(styles)(DialogEdit);
