// @flow
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { withStyles, withMobileDialog, Button, Chip } from "@material-ui/core";

// Material icons
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";

//Component styles
import styles from "./styles";
class MultipleOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      title: "",
      selectedCheckboxes: new Set(),
      selectedValues: new Set(),
      selectedData: []
    };
  }

  componentDidMount() {
    this.handleDoTitle();

    setTimeout(() => {
      this.setState({ openDialog: true });
    }, 2000);
  }

  handleDoTitle = () => {
    const { conversation } = this.props;
    const current_step = conversation[conversation.length - 1].id;
    switch (current_step) {
      case "msg-experience-segment":
        this.setState({ title: "Em quais das áreas abaixo você já atuou" });
        break;
      case "msg-contracting-modality":
        this.setState({ title: conversation[conversation.length - 1].msg });
        break;
      case "msg-shift-preferences":
        this.setState({ title: conversation[conversation.length - 1].msg });
        break;
      case "msg-skills":
        this.setState({ title: conversation[conversation.length - 1].msg });
        break;
      default:
        this.setState({ title: "Selecione" });
        break;
    }
  };

  toggleCheckbox = opt => {
    let value = opt.value ? opt.value : opt.id;

    if (this.state.selectedCheckboxes.has(opt.text)) {
      this.state.selectedCheckboxes.delete(opt.text);
      this.state.selectedValues.delete(value);
    } else {
      this.state.selectedCheckboxes.add(opt.text);
      this.state.selectedValues.add(value);
    }
    this.setState({
      selectedCheckboxes: this.state.selectedCheckboxes,
      selectedValues: this.state.selectedValues,
      selectedData: [...this.state.selectedValues]
    });
  };

  async confirm() {
    const { replies } = this.props;
    let text = [...this.state.selectedCheckboxes].join(", ");
    let value = [...this.state.selectedValues];
    if (text.length === 0) {
      text = "Nenhuma das opções";
      value = "";
    }

    if (this.props.evalValue)
      value = await this.props.evalValue(value, replies);

    let valueKey = this.props.valueKey;
    if (typeof valueKey === "function") valueKey = valueKey(value, replies);

    this.props.doSendUserMessage({
      text: text,
      value: value,
      next: this.props._next,
      valueKey: valueKey
    });
  }

  render() {
    const { classes, multipleOptions } = this.props;
    const { selectedData } = this.state;

    return (
      <Fragment>
        {this.props.maxSelected && (
          <p>Selecione no máximo {this.props.maxSelected} opções</p>
        )}
        <div className={classes.optionsContent}>
          {multipleOptions &&
            multipleOptions.map((e, key) => {
              return (
                <Fragment key={key}>
                  {selectedData.includes(e.value) ? (
                    <Chip
                      size="small"
                      label={e.text}
                      color="secondary"
                      variant="outlined"
                      deleteIcon={
                        <CheckCircleIcon className={classes.checkedIcon} />
                      }
                      onClick={() => this.toggleCheckbox(e)}
                      onDelete={() => this.toggleCheckbox(e)}
                      className={classes.chip}
                    />
                  ) : (
                    <Chip
                      size="small"
                      key={key}
                      label={e.text}
                      color="primary"
                      variant="outlined"
                      onClick={() => this.toggleCheckbox(e)}
                      className={classes.chip}
                    />
                  )}
                </Fragment>
              );
            })}
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.confirmButton}
          disabled={
            (this.props.maxSelected &&
            this.props.maxSelected < [...this.state.selectedCheckboxes].length) ||
            (this.state.selectedData.length === 0)
          }
          onClick={() => this.confirm()}
        >
          Confirmar
        </Button>
      </Fragment>
    );
  }
}

MultipleOptions.propTypes = {
  fullScreen: PropTypes.bool.isRequired
};

export default withStyles(styles)(withMobileDialog()(MultipleOptions));
