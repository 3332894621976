// @flow
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Radio,
  RadioGroup,
  Slide,
  SnackbarContent,
  Switch,
  Toolbar,
  Typography,
  withStyles
} from "@material-ui/core";
// Material icons
import { Close as CloseIcon, Warning as WarningIcon } from "@material-ui/icons";
import React, { Component } from "react";
//Component styles
import styles from "./styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SelectSkills extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: true,
      selectedOption: ""
    };
  }

  componentDidMount() {
    this.doLoadCard();
  }

  doLoadCard = () => {
    const { jobId, active } = this.props;
    if (!jobId && !active) {
      this.handleActiveCard();
    }
  };

  capitalizeFirstLetter = string => {
    if (string)
      return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);

    return "";
  };

  handleClose = () => {
    this.setState({ openDialog: false });
    this.props.actionCard();
  };

  handleActiveCard = () => {
    const { toggleCheckboxFn, jobId, persistJobFn } = this.props;

    toggleCheckboxFn();

    if (jobId && persistJobFn) persistJobFn();
  };

  handleActiveSubItems = item => {
    const {
      active,
      jobId,
      persistJobFn,
      subOptionsSavedValues,
      subOptionsSelectGradeFn
    } = this.props;

    if (active && subOptionsSelectGradeFn) {
      const vl = subOptionsSavedValues[item.value] === 1 ? 0 : 1;
      subOptionsSelectGradeFn(item.value, vl);
    }

    if (active && jobId && persistJobFn) {
      persistJobFn();
    }
  };

  handleChangeOption = event => {
    const item = event.target.value;

    if (this.props.active && this.props.selectGradeFn) {
      this.props.selectGradeFn(item);
    }

    if (this.props.active && this.props.jobId && this.props.persistJobFn) {
      this.props.persistJobFn();
    }
    this.setState({ selectedOption: item });
  };

  render() {
    const {
      classes,
      cardTitle,
      active,
      grade,
      subOptions,
      subOptionsSavedValues
    } = this.props;
    const { openDialog, selectedOption } = this.state;

    return (
      <Dialog
        TransitionComponent={Transition}
        maxWidth={"md"}
        open={openDialog}
        onClose={this.handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={() => this.handleClose()}>
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" className={classes.title}>
              {cardTitle}
            </Typography>
            <Button color="inherit" onClick={() => this.handleClose()}>
              Confirmar
            </Button>
          </Toolbar>
        </AppBar>

        <DialogTitle id="responsive-dialog-title" className={classes.header}>
          <p>Ativar critério</p>
          <Switch
            color="secondary"
            checked={active}
            onChange={() => this.handleActiveCard()}
          />
        </DialogTitle>
        {active ? (
          <DialogContent className={classes.dialogContent}>
            {!active && (
              <div
                style={{
                  backgroundColor: "rgba(255, 255, 255, 0.65)",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 0,
                  zIndex: 8889
                }}
              />
            )}

            <h3>{cardTitle}</h3>
            <small>
              Selecione o nível de importância do critério{" "}
              {cardTitle.toLowerCase()}
            </small>
            <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
            <RadioGroup
              aria-label="position"
              name="position"
              value={selectedOption}
              onChange={this.handleChangeOption}
              row
              className={classes.radioGroup}
            >
              {this.props.importanceRange.map((v, k) => (
                <FormControlLabel
                  key={k}
                  value={v.value}
                  control={<Radio color="primary" />}
                  label={v.text}
                  labelPlacement="top"
                  className={classes.label}
                  readOnly={!active}
                  checked={Number(v.value) === Number(grade)}
                />
              ))}
            </RadioGroup>

            <div style={{ marginTop: 24 }}>
              <h4>Opções</h4>
              <small>Aqui você seleciona as habilidades desejadas</small>
              <Divider style={{ height: 2, marginBottom: 8, marginTop: 8 }} />
            </div>

            <FormGroup
              aria-label="Situacao"
              name="situacao1"
              className={classes.group}
            >
              {subOptions &&
                subOptions.map((item, k) => (
                  <FormControlLabel
                    key={k}
                    label={item.text}
                    control={
                      <Switch
                        color="secondary"
                        name={item.value}
                        value={"1"}
                        readOnly={!active}
                        checked={1 === subOptionsSavedValues[item.value]}
                        onChange={() => this.handleActiveSubItems(item)}
                      />
                    }
                  />
                ))}
            </FormGroup>
          </DialogContent>
        ) : (
          <SnackbarContent
            aria-describedby="client-snackbar"
            style={{
              backgroundColor: "#55738c",
              margin: "20px auto",
              display: "flex"
            }}
            message={
              <span
                id="client-snackbar"
                style={{ display: "flex", alignItems: "center" }}
              >
                <WarningIcon style={{ marginRight: "10px" }} />
                Ative este critério para editar as opções!
              </span>
            }
          />
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(SelectSkills);
