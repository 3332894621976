// Material helpers
import { Avatar, Grid, withStyles } from "@material-ui/core";
// Externals
import PropTypes from "prop-types";
import React, { Component } from "react";
import jobin from "../../images/jobin.jpg";
//Component styles
import styles from "./styles";

class Persona extends Component {
  render() {
    const { classes, text, hideJobin } = this.props;

    return (
      <div style={{ width: "100%", paddingBottom: 20 }}>
        <Grid item xs={12} lg={12} className={classes.personaContent}>
          { hideJobin === undefined ? <Avatar alt={"JobSpot"} className={classes.avatar} src={jobin} /> : <> </> }
          <div className={classes.personaTextContent}>
            <p>{text}</p>
          </div>
        </Grid>
      </div>
    );
  }
}

Persona.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Persona);
