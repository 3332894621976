// @flow
export default [
  {
    key: "searchEmployee",
    hash: "search-employee",
    path: "#search-employee"
  },
  {
    key: "chat",
    hash: "chat",
    path: "#chat"
  },
  {
    key: "settings",
    hash: "settings",
    path: "#settings"
  }
];
