// @flow
import { Fab, Grid, withWidth } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Add as AddIcon } from "@material-ui/icons";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";
import Persona from "../../../components/Persona";
import TopBar from "../../../components/TopBar";
import { doLoadImportanceRange } from "../../../modules/form-data";
import {
  doPersistJob,
  doSelectQualificationLevelGrade,
  doSelectQualificationSituationGrade
} from "../../../modules/job";
import JobCardLevel from "../components/JobCardLevel";
import SelectCourse from "../components/SelectCourse";
import SelectCourseSituation from "../components/SelectCourseSituation";
import SelectEducationSituation from "../components/SelectEducationSituation";
import styles from "./styles";

class JobQualificacaoLevelChoices extends Component {
  constructor(props) {
    super(props);

    this.importanceRange = [];
    this.levelName = props.match.params.level;
    this.coursesByLevel = [];
    this.level = null;

    this.header = React.createRef();

    this.state = {
      openDialogSelectCourse: false,
      openDialogSelectCourseSituation: false,
      openDialogSelectEducationSituation: false
    };
  }

  componentDidMount() {
    this.doLoadData();
  }

  doLoadData = () => {
    this.coursesByLevel = this.props.coursesConfirmed.filter(
      c => c.courseLevel.value === this.levelName
    );

    if (this.coursesByLevel.length > 0)
      this.level = this.coursesByLevel[0].courseLevel;

    doLoadImportanceRange(this.props.token).then(data => {
      this.importanceRange = data;
      this.forceUpdate();
    });
  };

  handleManagerDialogSelectCouse = () => {
    if (
      this.levelName === "ENSINO_FUNDAMENTAL" ||
      this.levelName === "ENSINO_MEDIO"
    ) {
      this.setState({
        openDialogSelectCourse: false,
        openDialogSelectCourseSituation: false,
        openDialogSelectEducationSituation: true
      });
    } else {
      this.setState({
        openDialogSelectCourse: !this.state.openDialogSelectCourse
      });
    }
  };

  handleSelectedCourse = () => {
    if (this.levelName === "GRADUACAO") {
      this.setState({
        openDialogSelectCourse: false,
        openDialogSelectCourseSituation: true,
        openDialogSelectEducationSituation: false
      });
    } else {
      this.setState({
        openDialogSelectCourse: false,
        openDialogSelectCourseSituation: false,
        openDialogSelectEducationSituation: true
      });
    }
  };

  handleCloseSelectSituation = () => {
    this.setState({
      openDialogSelectCourseSituation: false,
      openDialogSelectEducationSituation: false
    });
    setTimeout(() => {
      this.doLoadData();
    }, 200);
  };

  render() {
    const { classes, courseLevel } = this.props;
    const {
      openDialogSelectCourse,
      openDialogSelectCourseSituation,
      openDialogSelectEducationSituation
    } = this.state;
    const grid = {
      xs: 12,
      sm: 12,
      md: 6,
      lg: 4
    };
    const pageTitle = courseLevel ? courseLevel.text : "Qualificação";

    return (
      <div className={classes.root}>
        <TopBar
          pageTitle={pageTitle}
          buttonTitle="Confirmar"
          hasButton={true}
          buttonColor="primary"
          variant="contained"
          actionButton={() =>
            this.props.history.replace("/job-qualificacao-choices")
          }
          actionBack={() =>
            this.props.history.replace("/job-qualificacao-choices")
          }
        />

        <Grid container spacing={3}>
          <Persona
            text={`Clique no botão de "+" para adicionar novos itens em ${pageTitle}`}
          />

          {/* {this.level && (
            <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg}>
              <JobCardLevel
                master={true}
                checkboxHidden={true}
                cardTitle={this.level.text}
                selectGradeFn={value => {
                  this.props.doSelectQualificationLevelGrade(
                    this.level.value,
                    value
                  );
                }}
                grade={this.props.qualificationsLevelsGrades[this.levelName]}
                active={true}
                importanceRange={this.importanceRange}
                jobId={this.props.jobId}
                persistJobFn={this.props.doPersistJob}
                history={this.props.history}
              />
            </Grid>
          )} */}

          {this.coursesByLevel.length > 0 &&
            this.coursesByLevel.map((vl, k) => (
              <Grid
                key={k}
                item
                xs={grid.xs}
                sm={grid.sm}
                md={grid.md}
                lg={grid.lg}
              >
                <JobCardLevel
                  master={false}
                  checkboxHidden={true}
                  grade={vl.grade}
                  selectGradeFn={value => {
                    vl.grade = value;
                    this.props.doSelectQualificationSituationGrade(vl);
                  }}
                  cardTitle={
                    (vl.academicDegree ? vl.academicDegree.text + " " : "") +
                    (vl.course && vl.course.text
                      ? vl.course.text
                      : this.level.text) +
                    " " +
                    vl.situation.text
                  }
                  active={true}
                  choicesComponentUrl={null}
                  importanceRange={this.importanceRange}
                  jobId={this.props.jobId}
                  persistJobFn={this.props.doPersistJob}
                  history={this.props.history}
                />
              </Grid>
            ))}
        </Grid>

        <Fab
          color="primary"
          aria-label="Add"
          className={classes.fab}
          onClick={() => this.handleManagerDialogSelectCouse()}
        >
          <AddIcon />
        </Fab>

        {openDialogSelectCourse && (
          <SelectCourse
            handleManagerDialogSelectCouse={() =>
              this.handleManagerDialogSelectCouse()
            }
            handleSelectedCourse={() => this.handleSelectedCourse()}
          />
        )}

        {openDialogSelectCourseSituation && (
          <SelectCourseSituation
            handleCloseSelectSituation={() => this.handleCloseSelectSituation()}
          />
        )}

        {openDialogSelectEducationSituation && (
          <SelectEducationSituation
            courseSelected="updateList"
            handleCloseSelectSituation={() => this.handleCloseSelectSituation()}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  coursesConfirmed: state.job.coursesConfirmed,
  qualificationsLevelsGrades: state.job.qualificationsLevelsGrades,
  jobId: state.job.jobId,
  token: state.login.token,
  courseLevel: state.job.courseLevel
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doSelectQualificationSituationGrade,
      doSelectQualificationLevelGrade,
      doPersistJob
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(compose(withStyles(styles), withWidth())(JobQualificacaoLevelChoices))
);
