import i18next from "i18next";

i18next.init({
  interpolation: {
    escapeValue: false
  },
  lng: "pt",
  resources: {
    pt: {
      translation: {
        loading: "Carregando",
        invalidLogin: "Dados inválidos",
        forgotPassword: "Esqueceu a senha?",
        createAccount: "Criar nova conta",
        fullAddress: "Endereço",
        neighborhood: "Bairro",
        nextStep: "Avançar",
        enterCompany: "Entrar como empresa",
        enterPerson: "Sou candidato",
        phone: "DDD + Telefone (WhatsApp)",
        password: "Senha",
        confirm: "Confirmar",
        confirmPhone: "Confirmar DDD + Telefone (WhatsApp)",
        confirmPassword: "Confirmar senha",
        firstNameLastName: "Nome sobrenome",
        representativeName: "Nome do representante",
        companyName: "Nome da empresa",
        socialNumber: "CPF",
        companySocialNumber: "CNPJ (somente números)",
        dateOfBirth: "Data de nascimento",
        gender: "Sexo",
        state: "Estado",
        city: "Cidade",
        email: "E-mail",
        corporateEmail: "E-mail corporativo",
        confirmCorporateEmail: "Confirmar e-mail corporativo",
        genericError: "Algum erro ocorreu",
        allFieldsRequired: "Todos os campos são obrigatórios",
        invalidPhone: "Telefone inválido",
        invalidSocialNumber: "CPF inválido",
        invalidCompanySocialNumber: "CNPJ inválido",
        invalidDateOfBirth: "Data de nascimento inválida",
        invalidEmail: "E-mail inválido",
        invalidDate: "Data inválida",
        requiredField: "Este campo é obrigatório",
        gender_masc: "Masculino",
        gender_fem: "Feminino",
        matchFields: "Os campos não coincidem",
        emailDoNotMatch: "Os endereços de e-mail informados não coincidem. Verifique os campos, por favor.",
        minLengthPassword: "A senha deve ter pelo menos 6 caracteres",
        weakPassword: "A senha deve ter pelo menos 6 caracteres, com pelo menos um número",
        chatMsg3Employee:
          "Ok entendi! Para ajudar você a divulgar o seu perfil profissional, preciso de algumas informações.",
        send: "Enviar",
        forgotPasswordEndSuccess:
          "Caso a conta exista, você vai receber mais informações de como recuperar sua senha no seu e-mail",
        forgotPasswordEndFailure:
          "Não foi possível processar sua solicitação. O email informado não existe.",
        forgotPasswordEndRequestPerson:
          "Alteração de senha não autorizada.\nFaça seu login pelo Whatsapp da Jobspot.",
        newPassword: "Nova senha",
        newPasswordConfirmation: "Confirme a nova senha",
        nonMatchingPasswords: "As senhas não são iguais",
        resetPasswordSuccess: "Sua senha foi redefinida com sucesso!",
        resetPasswordUnsuccesful: "Não foi possível redefinir sua senha",
        acceptedInvite:
          'Convite aceito com sucesso! Agora você pode conversar com na aba de "Conversas"',
        sendMeToJobinBot: "Quero me cadastrar na Jobspot!",
        phoneEmailLogin: "DDD + Telefone ou E-mail",
        phoneLogin: "Telefone",
        emailLogin: "E-mail da empresa",
        profileRequestsMade: "Solicitações de perfil realizadas",
        availableCredits: "Créditos disponíveis",
        plan: "Plano",
        requests: "solicitações",
        totalUsage: "Uso total",
        nextRecharge: "Próxima recarga",
        accountNotVerifiedMessage: "A sua conta ainda não foi verificada. Por favor, verifique seu WhatsApp com as instruções que foram enviadas.",
        allRightsReserved: "Todos os direitos reservados."
      }
    }
  }
});

export default i18next;
