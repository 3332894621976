// Material helpers
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  withStyles,
  LinearProgress,
  CircularProgress,
} from "@material-ui/core";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import compose from "recompose/compose";
import { bindActionCreators } from "redux";

import SettingsIcon from '@material-ui/icons/Settings';

import { doUpdateAvailableCredits } from "../../../modules/job";

// Material icons
import {
  AssignmentInd as BuscoTrabalhadorIcon,
  Work as BuscoTrabalhoIcon
} from "@material-ui/icons";
// Externals
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { NavLink } from "react-router-dom";
// Component styles
import styles from "./styles";

// import {
//   SubmitButton
// } from "./styles";

function Sidebar({ classes, className, profile, userInitialized, current_plan, onChange, externalProps }) {
  const profileName =
    profile.userType === "COMPANY" ? profile.companyName : profile.name;

  const isCompany = profile.userType === "COMPANY";

  const today = new Date();
  const futureDate = new Date(today.setMonth(today.getMonth()+1));

  let totalUsedRequests;

  if (isCompany) {
    totalUsedRequests = current_plan.access_plan.monthly_credits - current_plan.available_credits;
  }

  if (!isCompany) {
    totalUsedRequests = 0;
    current_plan = {
      monthly_credits: 0
    };
  }

  const avatar = `https://api.dicebear.com/8.x/initials/svg?seed=${profileName}&backgroundColor=0062E4`;

  const rootClassName = classNames(classes.root, className);

  const ForwardNavLink = React.forwardRef((props, ref) => (
    <NavLink {...props} innerRef={ref} />
  ));

  const trabalhoLink = userInitialized ? "/profile" : "/find-bot";
  const preferencesLink = "/preferences";

  return (
    <nav className={rootClassName}>
      <div className={classes.profile}>
        <Avatar alt={profileName} className={classes.avatar} src={avatar} />
        <Typography className={classes.nameText} variant="h6">
          {profileName}
        </Typography>
        <Typography className={classes.bioText} variant="caption">
          {profile.publicId}
        </Typography>
        <Typography className={classes.bioText} variant="caption">
          {profile.email}
        </Typography>
      </div>

      <Divider className={classes.profileDivider} />

      <List component="div" disablePadding>

      {!isCompany && (
        <>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to={trabalhoLink}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <BuscoTrabalhoIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Meu Perfil"
            />
          </ListItem>

          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={ForwardNavLink}
            to={preferencesLink}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Preferências"
            />
          </ListItem>
        </>       
        )}
        {isCompany && (
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={ForwardNavLink}
          to="/jobs"
        >
          <ListItemIcon className={classes.listItemIcon}>
            <BuscoTrabalhadorIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Minhas Vagas"
          />
        </ListItem>
        )}
      </List>
      <Divider className={classes.profileDivider} />
      { 
      
        (
          current_plan.monthly_credits === 0 ?
            <div id="divCheckbox" style={{ display: "none" }}>
              <CircularProgress style={{ display: "inline-block", width: "30px", height: "30px", float: "left", marginLeft: "100px" }} />
            </div>
          :
            <div className={classes.planItem}>
              <Typography className={classes.listItemText} variant="body1">
                <span className={classes.textLeftHistory} onClick={onChange}><b>Plano {current_plan.access_plan.name}</b></span> <span className={classes.textRightHistory}>({current_plan.access_plan.monthly_credits} solicitações)</span> 
              </Typography>

              <br />

              <Typography className={classes.listItemText} variant="body1">
                <span className={classes.textLeft} ><b>Uso total</b></span> <span className={classes.textRight}>{totalUsedRequests}/{current_plan.access_plan.monthly_credits}</span>
              </Typography>

              <LinearProgress className={classes.linearBar} variant="determinate" value={(totalUsedRequests * 100) / current_plan.access_plan.monthly_credits} />

              <br />

              <Typography className={classes.listItemText} variant="caption">
                <span className={classes.textLeft}>Próxima recarga:</span> <span className={classes.textRight}>01/{futureDate.getMonth() + 1}/{futureDate.getFullYear()}</span>
              </Typography>

              <Divider className={classes.profileDivider} />
            </div>
        )
      }
    </nav>
  );
}

Sidebar.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doUpdateAvailableCredits
    },
    dispatch
  );

export default withRouter(
  connect(
    mapDispatchToProps
  )(compose(withStyles(styles))(Sidebar))
);
