import moment from "moment";

/**
 * Retorna a idade de um usuário (número inteiro) dada a sua data de nascimento no formato DD/MM/YYYY
 * @param {String} birthDate data de nascimento representada em String DD/MM/YYYY
 * @returns Number | undefined
 */
export function getAgeFromUserBirthDate(birthDate) {
  if (birthDate) {
    const parsedDate = moment(birthDate, "DD/MM/YYYY").toDate();
    return moment().diff(parsedDate, "years");
  }
  return undefined;
}
