export default theme => ({
  root: {},
  title: {
    flexGrow: 1,
    fontWeight: "bold",
    padding: "10px 0px",
    maxWidth: "75%"
  },
  dialogContent: {
    margin: 0,
    padding: "15px"
  },
  optionsContent: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    width: "100%"
  },
  chip: {
    margin: "3px 4px",
    height: "26px",
    position: "relative",
    fontSize: "11px"
  },
  checkedIcon: {
    position: "absolute",
    right: "-14px",
    top: "-6px",
    color: "rgba(94, 199, 73, 1)"
  },
  confirmButton: {
    borderRadius: "4px",
    backgroundColor: theme.palette.background.topbar,
    border: 0,
    boxShadow: "rgba(0, 0, 0, 0.15) 0px 2px 4px 0px",
    margin: "0px 5px 10px",
    padding: "10px 15px",
    color: "white",
    textAlign: "center",
    flexGrow: 1,
    width: "100%",
    marginTop: "15px"
  }
});
