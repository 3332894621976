// Material helpers
import { Divider, List, ListItem, withStyles } from "@material-ui/core";
// Externals
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import CandidatoProfile from "../CandidatoProfile";
import Typography from "@material-ui/core/Typography";
import Item from "./item";
//Component styles
import styles from "./styles";
import classNames from "classnames";

import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import IconButton from '@mui/material/IconButton';

import connect from "react-redux/es/connect/connect";
import { withRouter } from "react-router-dom";

class CandidatosList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      candidatesToShow: 7,
      startRange: 0,
      endRange: 7,
      openProfile: false,
      currentOpenedProfileId: null,
      profile: null,
      favorites: [],
      candidatesDenied: {},
      candidatesFavorite: {},
      candidatos: {},
      candidatosFavoritos: {},
      page: 1,
      slicedCandidates: []
    };
  }

  handleOpenProfile = (profile, key) => {
    this.setState({
      profile: {
        ...profile,
        keyId: key
      },
      openProfile: true,
      currentOpenedProfileId: profile.idCandidato
    });
  };

  handleManagerProfile = () => {
    this.setState({ openProfile: !this.state.openProfile });
  };

  updateFromChild = (candidatosFromChild) => {

    const favoriteCandidatesUpdated = [];

    candidatosFromChild.forEach((candidato) => {
      if (candidato.favorite) {
        favoriteCandidatesUpdated.push(candidato);
      }
    });

    this.setState({ candidatos: candidatosFromChild });
    this.setState({ candidatosFavoritos: favoriteCandidatesUpdated});
    this.forceUpdate();
  }

  updateCurrentCandidateFromChild = (candidate) => {
    this.setState({ currentOpenedProfileId: candidate.idCandidato });
    
    this.props.updateSelectedCandidate(candidate);
  }

  componentWillMount = () => {
      const favoritesArray = [];
      let candidatesDeniedObject = {};
      let candidatesFavoriteObject = {};
      let candidatosWithFavoriteProperty = this.props.candidatos;
      let favoriteCandidatesWithFavoriteProperty = [];

      if (this.props.candidatos !== "Não há nenhum candidato com os critérios desejados.") {
        candidatosWithFavoriteProperty.forEach((candidato) => {
        const idCandidato = candidato.idCandidato;

        candidato.user.jobs.forEach((userJob) => {

          candidato.favorite = userJob.favorite;

          if (userJob.job.id === this.props.jobId) {
            favoritesArray.push({ id: idCandidato, status: userJob.favorite });
            candidatesDeniedObject[idCandidato] = userJob.hiringStatus; 
            candidatesFavoriteObject[idCandidato] = userJob.favorite;
          }
        })
      });

      candidatosWithFavoriteProperty.forEach((candidato, k) => {
        if (candidato.favorite) {
          favoriteCandidatesWithFavoriteProperty.push(candidato);
        }
        candidato.iteration_id = k;
      })

      const maxValue = Number.isNaN(Math.ceil(candidatosWithFavoriteProperty.length / this.state.candidatesToShow)) ? 0 : Math.ceil(candidatosWithFavoriteProperty.length / this.state.candidatesToShow);
      let startRange = 0;
      let endRange = 7;
      for (let i = 1; i <= maxValue; i++) {
        this.setState({[i]: candidatosWithFavoriteProperty.slice(startRange, endRange * i)});
        startRange = endRange * i;
      }


      this.setState({ favorites: favoritesArray });
      this.setState({ candidatesDenied: candidatesDeniedObject });
      this.setState({ candidatesFavorite: candidatesFavoriteObject });
      this.setState({ candidatos: candidatosWithFavoriteProperty });
      this.setState({ candidatosFavoritos: favoriteCandidatesWithFavoriteProperty });
    }
  }

  manipulateFavorite = (idCandidato) => {
    let newCandidatosObject = this.state.candidatos;

    newCandidatosObject.forEach((candidato) => {
      if(candidato.idCandidato === idCandidato) {
        candidato.favorite = !candidato.favorite;
      }
    });

    this.setState({ candidatos: newCandidatosObject });
  }

  findJobUserObject = (jobs) => {
    const { jobId } = this.props;
    let trabalho = {};

    jobs.forEach((job) => {
      if (job.job.id === jobId) {
        trabalho = job;
      }
    });

    return trabalho;
  }

  nextPage = () => {
    this.setState({ page: this.state.page + 1 });
  }

  previousPage = () => {
    this.setState({ page: this.state.page - 1 });
  }

  render() {
    const { jobId, maxPoints, isMobile, classes } = this.props;
    const { candidatos, openProfile, profile, currentOpenedProfileId, candidatesToShow } = this.state;

    let selectedCandidatos = [];
    selectedCandidatos = this.state[this.state.page];

    const maxPage = Number.isNaN(Math.ceil(candidatos.length / candidatesToShow)) ? "0" : Math.ceil(candidatos.length / candidatesToShow);

    return (
      <Fragment>
          <List component="nav" style={{ padding: "8px" }} className={classNames(classes.card, {
            [classes.cardContentInactive]: !this.props.jobActive
          })}>
          { !Array.isArray(selectedCandidatos) ?
            <Typography align="center" component="p" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
              Nenhum candidato encontrado
            </Typography> :
            selectedCandidatos.map((c, k) => (
              <Fragment key={c.iteration_id}>
                <ListItem
                  button
                  onClick={() => {
                    this.updateCurrentCandidateFromChild(c);
                  }}
                  style={{ padding: "4px 0px", backgroundColor: c.idCandidato === this.state.currentOpenedProfileId ? "antiquewhite" : "" }}
                >
                  <Item
                    jobId={jobId}
                    k={k}
                    c={c}
                    jobUserObject={c.job_user}
                    maxPoints={maxPoints}
                    isMobile={isMobile}
                    idCandidato={c.idCandidato}
                    candidatesDenied={this.state.candidatesDenied}
                  />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
          <div id="paginationMenu" align="center" component="p" 
            style={{ 
              paddingTop: "10px",
              paddingBottom: "10px",
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-evenly",
              width: "25%",
              margin: "auto"
            }}
            className={classNames(classes.card, {
              [classes.cardContentInactive]: !this.props.jobActive
            })}
          >
            <IconButton
              style={{
                backgroundColor: this.state.page === 1 ? "#D3D3D3" : "#1976D2",
                color: "white",
                width: "25px", 
                height: "25px"
              }}
              onClick={this.previousPage}
              disabled={this.state.page === 1}
            >
              <ArrowLeftIcon />
            </IconButton>
              <p style={{ color: "grey" }}>{`${ maxPage === "0" ? "0" : this.state.page } / ${maxPage}`}</p> 
            <IconButton
              style={{
                backgroundColor: this.state.page === maxPage ? "#D3D3D3" : maxPage === "0" ? "#D3D3D3" : "#1976D2",
                color: "white",
                width: "25px", 
                height: "25px"
              }}
              onClick={this.nextPage}
              disabled={this.state.page === maxPage ? true : maxPage === "0" ? true : false }
            >
              <ArrowRightIcon />
            </IconButton>
                
          </div>

        {openProfile && (
          <CandidatoProfile
            profile={profile}
            jobId={jobId}
            candidatos={candidatos}
            idCandidatoOpened={currentOpenedProfileId}
            updateFromChild={this.updateFromChild}
            actionClose={() => this.handleManagerProfile()}
            isMobile={isMobile}
          />
        )}
      </Fragment>
    );
  }
}

CandidatosList.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  favoritos: state.favorites,
  jobActive: state.job.jobActive,
});

export default withRouter(
  connect(
    mapStateToProps
  )(withStyles(styles)(CandidatosList))
);
