// @flow
import React from "react";
import { Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import Switch from "@material-ui/core/Switch";
import Radio from "@material-ui/core/Radio";

class JobCard extends React.Component {
  capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  };

  getBackgroundColor = () => {
    let color = this.props.backgroundColor
      ? this.props.backgroundColor
      : "#FFFFFF";
    color += this.props.active && this.props.jobActive ? "FF" : "33";

    return color;
  };

  render() {
    const { classes } = this.props;

    return (
      <Card
        className={classes.card}
        style={{
          backgroundColor: this.getBackgroundColor(),
          marginLeft: this.props.marginLeft
            ? this.props.marginLeft
            : "inherited",
          textAlign: this.props.textAlign ? this.props.textAlign : "left"
        }}
      >
        <CardContent>
          <div>
            {this.props.cardIcon && (
              <Icon
                style={{ fontSize: 36, verticalAlign: "bottom", opacity: 0.7 }}
              >
                {this.props.cardIcon}
              </Icon>
            )}
            <span
              style={{
                fontSize: this.props.titleSize,
                paddingLeft: 10,
                paddingBottom: 5
              }}
            >
              {this.capitalizeFirstLetter(this.props.cardTitle)}
            </span>
            <br />
            <div
              style={{
                display: this.props.checkboxHidden ? "none" : "block"
              }}
            >
              <div
                style={{
                  width: "62px",
                  float: "left",
                  marginLeft: "-10px"
                }}
              >
                <Switch
                  color="primary"
                  checked={this.props.active}
                  style={{ float: "right" }}
                  onChange={() => {
                    this.props.toggleCheckboxFn();

                    if (this.props.jobId && this.props.persistJobFn)
                      this.props.persistJobFn();

                    if (!this.props.active)
                      this.props.history.push(this.props.choicesComponentUrl);
                  }}
                />
              </div>
              <div
                style={{
                  width: "calc(100% - 62px)",
                  lineHeight: "48px",
                  float: "left",
                  marginBottom: "20px",
                  fontSize: 14
                }}
              >
                Ativar critério
              </div>
            </div>
          </div>
          <table
            style={{
              marginTop: 10,
              opacity:
                (this.props.active && this.props.jobActive) ||
                this.props.checkboxHidden
                  ? 1
                  : 0.4
            }}
            width="100%"
          >
            <tbody>
              <tr>
                {this.props.importanceRange.map((v, k) => (
                  <td key={k} className={classes.cardImportance}>
                    {v.text}
                  </td>
                ))}
              </tr>
              <tr>
                {this.props.importanceRange.map((v, k) => (
                  <td key={k} className={classes.cardImportance}>
                    <Radio
                      color="primary"
                      name={this.props.cardTitle}
                      value={v.value}
                      readOnly={!this.props.active}
                      checked={Number(v.value) === Number(this.props.grade)}
                      onChange={() => {
                        if (this.props.active && this.props.selectGradeFn)
                          this.props.selectGradeFn(v.value);

                        if (
                          this.props.active &&
                          this.props.jobId &&
                          this.props.persistJobFn
                        )
                          this.props.persistJobFn();
                      }}
                    />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          {this.props.choicesComponentUrl && (
            <div
              style={{
                fontSize: 12,
                float: "right",
                marginTop: 20,
                opacity:
                  (this.props.active && this.props.jobActive) ||
                  this.props.checkboxHidden
                    ? 1
                    : 0.4
              }}
            >
              {((this.props.active && this.props.jobActive) ||
                this.props.checkboxHidden) && (
                <Link
                  to={this.props.choicesComponentUrl}
                  className={classes.cardDetail}
                >
                  Detalhar
                  <img
                    alt="Right Arrow Icon"
                    style={{ verticalAlign: "middle" }}
                    width="20"
                    height="20"
                    src={require("../../images/69a4b799a8b0e2fbaac92d9ebb1b90e3_757575.svg")}
                  />
                </Link>
              )}
              {(!this.props.active || !this.props.jobActive) &&
                !this.props.checkboxHidden && (
                    <div>
                      Detalhar
                      <Icon style={{ verticalAlign: "middle" }}>
                        keyboard_arrow_right
                      </Icon>
                    </div>
                )}
            </div>
          )}
        </CardContent>
      </Card>
    );
  }
}

const styles = {
  card: {
    margin: 20,
    paddingBottom: 15
  },
  cardImportance: {
    fontSize: 10,
    textAlign: "center",
    padding: 0
  },
  cardImportanceRadio: {
    transform: "scale(2)",
    marginTop: 15
  },
  cardDetail: {}
};

export default withStyles(styles)(JobCard);
